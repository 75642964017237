import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import Select from 'react-select'
import _ from 'lodash'
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect'

import { requestEventShare } from '../../../action/AlertActions'
import CustomButton from '../../CustomButton';

//import 'react-select/dist/react-select.css';

const EventShareModal = (props) => {

	const { event, onHide, toggleLoader } = props;

	const [emails, setEmails] = useState([]);
	const [expiration, setExpiration] = useState(0);
	const [message, setMessage] = useState('');
	const [errMessage, setErrMessage] = useState({
		erremail: '',
		errexpiration: '',
		errmessage: ''
	});
	const [inputValue, setInputValue] = useState("")

	let expirationSelect = null;

	const fillForm = (e) => {
		const { target } = e;
		const { name } = target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		if (name === "expiration") setExpiration(value);
		if (name === "message") setMessage(value);
	}

	const checkEmail = (argEmail) => {
		const re = /^([a-zA-Z0-9_\-\.\+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(argEmail)
	}

	const checkForm = (args) => {
		let error = false;

		Object.keys(args).forEach((key) => {
			if (args[key] === '' && key !== 'message') {
				error = true;
				errMessage[`err${key}`] = `Enter your ${key}!`;
			} else if (errMessage[key]) {
				errMessage[`err${key}`] = null;
			}
		})

		if (!errMessage.erremail) {
			args.email.split(',').forEach(item => {
				if (!checkEmail(item)) {
					error = true;
					errMessage.erremail = 'Email is not valid.';
					return;
				}
			})
		}

		if (args.message && args.message.length > 250) {
			error = true;
			errMessage.errmessage = 'The message can have maximum 250 chars.';
		}

		setErrMessage((prevState) => { return { ...prevState, ...errMessage } });
		return error;
	}

	const sendForm = () => {
		const params = {
			email: emails.join(','),
			expiration,
			message,
			company_id: event.company_id,
			event_id: event.id
		};
		const error = checkForm(params)

		if (!error) {
			toggleLoader(true)

			requestEventShare(params)
				.then((res, err) => {
					console.log('!requestEventShare res:', res, err);
					toggleLoader(false);

					onHide('success');
					toastr.success('', 'Video has been sent');
				})
				.catch((error) => {
					toggleLoader(false)
					onHide();

					console.log('!requestEventShare error:', error.response, error);

					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				});
		}
	};

	const { erremail, errexpiration, errmessage } = errMessage;

	const emailsList = []
	if (emails) {
		emails.forEach(item => emailsList.push({ value: item, label: item }));
	}

	// console.log('STATES EventShareModal emails: ', emails)
	// console.log('emailsList EventShareModal: ', emailsList)

	return (
		<Modal
			show
			onHide={onHide}
			className="modal-lg-size"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">Share Video</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<div className={`form-group rosco-select ${erremail && 'has-error'}`} style={{ padding: '0' }}>
						<label className="control-label">Recipient Emails:</label>
						{/* FWSD-7285 */}
						{isMobileOnly
						? (
							<>
								<div style={{ display: 'flex' }}>
									<input type="text" name="lemailast_name" className="vehicle-search mr-20" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
									<CustomButton
										variant="primary"
										size="sm"
										onClick={(e) => {
											if (!checkEmail(inputValue)) {
												setErrMessage((prevState) => { return { ...prevState,  erremail: 'Email is not valid.'} })
											}
											else {
												setErrMessage((prevState) => { return { ...prevState,  erremail: ''} })
												setInputValue('')
												setEmails((prevState) => { return [...prevState, inputValue] })
											}
										}}
									>
										Add
									</CustomButton>
								</div>
								<div className="mt-10">
									<label className="control-label mr-10">Entered Emails:</label>
									<span>{`${emails.length ? emails.join(', ') : 'None'}`}</span>
								</div>
							</>
						)
						: (
							<Select
								// multi
								isMulti
								// isSearchable={false}
								name="email"
								options={emailsList}
								inputValue={inputValue}
								onSelectResetsInput={true}
								placeholder="Select Subscribers..."
								// className="form-select3 subscribers"
								className="basic-multi-select"
								classNamePrefix="select"
								value={emailsList}
								onChange={(items,e) => {
									setEmails((prevState) => _.map(items, 'value'))
								}}
								onInputChange={(item) => {
									setInputValue(item)
								}}
								onKeyDown={(e) => {
									if (['Enter', ',', ' ', 'Tab'].indexOf(e.key) > -1) {
										e.preventDefault()
										const subscriber = e.target.value
										if (checkEmail(subscriber)) {
											setEmails((prevState) => { return [...prevState, subscriber] })
										} else if (e.key === 'Tab' && !subscriber) {
											expirationSelect.focus();
										} else {
											toastr.error('Not a valid email address');
										}
										setInputValue('')
									}
								}}
								// onKeyPress={(e) => {
								// onInputKeyDown={(e) => {
								// textInput={(e) => {
							// onFocus={ () => {
							// 	setTimeout( ()=> {
							// 	//   if ( searchValue.length === 0 ) {
							// 	  	// if ( document.querySelector("input.select__input") ) {
							// 			// document.querySelector(".Select-input input").value = null;
							// 			document.querySelector("input.select__input").value = null;
							// 	  	// }
							// 	}, 100 );
							// }}
							// closeMenuOnSelect={true}
							// onBlurResetsInput={true}
							// onBlur={(e) => {
							// 	if (e.target.value) {
							// 		debugger
							// 		if (checkEmail(e.target.value)) {
							// 			// setEmails([...emails, e.target.value])
							// 			setEmails((prevState) => { return [...prevState, e.target.value ] })
							// 		} else {
							// 			toastr.error('Not a valid email address');
							// 		}
							// 	}
							// }}
							/>
						)}
						{erremail && <span className="help-block">{erremail}</span>}
					</div>
					<div className={`form-group ${errexpiration && 'has-error'}`}>
						<label className="control-label">Link Expiration:</label>
						<select
							placeholder="Select..."
							className="rosco-select"
							name="expiration"
							value={expiration}
							onChange={fillForm}
							ref={elem => (expirationSelect = elem)}
							style={{ width: '100%' }}
						>
							<option value="0">No Expiration</option>
							<option value="24">A Day</option>
							<option value="168">A Week</option>
							<option value="720">A Month</option>
						</select>
						{errexpiration && <span className="help-block">{errexpiration}</span>}
					</div>
					<div className={`form-group ${errmessage && 'has-error'}`}>
						<label className="control-label">Message:</label>
						<textarea
							name="message"
							value={message}
							onChange={fillForm}
							style={{ width: '100%', height: '80px', resize: 'vertical' }}
						/>
						{errmessage && <span className="help-block">{errmessage}</span>}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton
					onClick={sendForm}
					variant="primary"
				>
					Send
				</CustomButton>
			</Modal.Footer>
		</Modal>
	);
};

EventShareModal.propTypes = {
	event: PropTypes.objectOf(PropTypes.any).isRequired,
	onHide: PropTypes.func.isRequired
}

export default connect(
	state => ({
		user: state.user
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(EventShareModal);
