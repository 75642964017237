import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { saveDashboardData, getDashboardData } from '../../../action/AccountActions';
import Toggle from 'react-toggle';
import { Button, Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';
import { BsPlusLg, BsXLg } from 'react-icons/bs';
import { isInternalUser } from '../../helpers/constants';

import EditReport from './EditReport';

import { getDevicesList } from '../../../action/DeviceActions';

import "bootstrap-daterangepicker/daterangepicker.css";
import "../../dump/menuComponents/menuComponents.sass";
import CustomButton from '../../CustomButton';

const ReportsList = (props) => {
	// console.log('PROPS ReportsList: ', props)
	const { user, company, updateCharts, toggleLoader } = props;

	const [deleteReports, setDeleteReports] = useState("");
	const [user_settings, setUser_settings] = useState({
		charts: [],
		layout: {
			lg: [],
			md: [],
			sm: [],
			xs: [],
			xxs: []
		}
	});
	const [events, setEvents] = useState(['Driver', 'High G-Force', 'Speed', 'Custom Video Request', 'Input Sensor', 'Pedestrian Warning', 'Forward Collision', 'Lane Departure', 'Headway Warning', 'Tailgating', 'Phoneuse', 'Drowsiness', 'Yawning', 'Distraction', 'Seatbelt', 'Smoking', 'CameraBlock']);
	const [alerts, setAlerts] = useState(['SD Card Failure', 'Improper Shutdown', 'Camera Failure']);
	const [modal, setModal] = useState({
		show: false,
		content: "",
		error: false
	});
	const [delete_chart, setDelete_chart] = useState({});
	const [devices, setDevices] = useState([]);
	const [edit_report_show, setEdit_report_show] = useState(false);
	const [editable_chart, setEditable_chart] = useState("");
	const [modal_type, setModal_type] = useState("");
	const [item, setItem] = useState({});

	// console.log('STATES ReportsList user_settings: ', user_settings)

	// // Only for Tenna and internal users (FWSD-7355)
	// useEffect(() => {
	// 	if (user?.partner_company_name === "TENNA LLC" || user?.company_name === "Tenna LLC" || isInternalUser(user.user.roles[0])) {
	// 		let evt = events;
	// 		evt.push('CameraBlock');
	// 		setEvents(evt);
	// 	}
	// }, [])

	useEffect(() => {
		setUser_settings(props.user_settings);
		changedDevicesList(company.company_id);
	}, [props.user_settings])

	const changedDevicesList = (company_id) => {
		const _company_id = company_id || company.company_id;
		getDevicesList(_company_id)
			.then((res, err) => {
				if (res.status == 200 || res.status == 201) {
					setDevices(res.data.response.devices);
				}
				if (err) {
					console.log("!changedDevicesList err", err);
				}
			})
			.catch((error) => {
				toggleLoader(false)
				console.log("!changedDevicesList error", error);
				setDevices([]);
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			});

	}

	const handleInputChange = (event, data) => {
		console.log("handleInputChange", data);
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		user_settings.charts.map(item => {
			if (item.i === data) {
				let data = item;
				data[name] = value;
				setItem(data);
			}
		});

		edit(null, null, data);
	};

	const closeModal = () => {
		setModal({
			...modal,
			show: false,
			error: false
		});
		setDeleteReports("all")
	};

	const deleteAllChartConfirm = () => {
		setModal({
			...modal,
			show: true,
			content: "Are you sure you want to delete all reports?"
		});
		setDeleteReports("all")
	};

	const deleteChartConfirm = async (data) => {
		if (await checkReports(data)) {
			setDelete_chart(data);
			setDeleteReports("one");
			setModal({
				...modal,
				show: true,
				content: "Are you sure you want to delete this report?"
			});
		}
	}

	const removeChart = async () => {
		const i = delete_chart;

		let newData = JSON.parse(JSON.stringify(user_settings));

		const layoutReject = () => {
			let newObj = {};
			for (let key in newData.layout) {
				newObj = { ...newObj, [key]: _.reject(newData.layout[key], { i: i }) };
			};
			return newObj;
		};

		const newArray = {
			charts: _.reject(newData.charts, { i: i }),
			layout: layoutReject()
		};

		setUser_settings(newArray);
		setDelete_chart({});
		setModal({ ...modal, show: false });

		const result = await edit("delete", newArray);
		result && toastr.success("Success", "A reports was deleted successfully");
	};

	const deleteAllCharts = () => {
		let data = [];
		toggleLoader(true);
		saveDashboardData(data)
			.then((res, err) => {
				console.log('!saveDashboardData res', res);
				toggleLoader(false);
				if (res.status == 200) {
					updateCharts();
					toastr.success("Success", "All reports successfully deleted");
					setUser_settings({
						charts: [],
						layout: { lg: [], md: [], sm: [], xs: [], xxs: [] },
					})
				}
			})
			.catch((error) => {
				toggleLoader(false);
				const err = _.get(error, "response.data.body");
				console.log("!deleteAllCharts error", _.get(error, "response.data.body"));
			}).then(() => {
				setModal({ ...modal, show: false });
				setDeleteReports('');
			});
	}

	const addOneChart = (new_chart) => {
		const new_data = new_chart;
		console.log('addOneChart', new_data);

		let data = user_settings;

		const counter = (user_settings.charts) ? user_settings.charts.length : 0;
		if (counter < 10) {
			let maxCount;
			let maxYLG, maxYMD, maxYSM, maxYXS, maxYXXS;

			if (data.charts && data.charts.length == 0) {
				maxCount = 0;
				maxYLG = 0;
				maxYMD = 0;
				maxYSM = 0;
				maxYXS = 0;
				maxYXXS = 0;
			}
			else if (data.charts && data.charts.length > -1) {
				const newMap = data.charts.map((item, i) => {
					return item.i.slice(1);
				});
				maxCount = Math.max.apply(null, newMap) + 1;

				maxYLG = Math.max.apply(Math, user_settings.layout.lg.map(function (o) { return o.y; })) + 3;
				maxYMD = Math.max.apply(Math, user_settings.layout.md.map(function (o) { return o.y; })) + 2;
				maxYSM = Math.max.apply(Math, user_settings.layout.sm.map(function (o) { return o.y; })) + 4;
				maxYXS = Math.max.apply(Math, user_settings.layout.xs.map(function (o) { return o.y; })) + 3;
				maxYXXS = Math.max.apply(Math, user_settings.layout.xxs.map(function (o) { return o.y; })) + 2;

			}

			data['layout']['lg'] = user_settings.layout.lg.concat({
				w: 6,
				h: 3,
				x: 0,
				y: maxYLG,
				i: 'n' + maxCount,
				moved: false,
				static: false,
				isResizable: false,
			});
			data['layout']['md'] = user_settings.layout.md.concat({
				w: 6,
				h: 3, //was 2
				x: 0,
				y: maxYMD,
				i: 'n' + maxCount,
				moved: false,
				static: false,
				isResizable: false,
			});
			data['layout']['sm'] = user_settings.layout.sm.concat({
				w: 6,
				h: 4,
				x: 0,
				y: maxYSM,
				i: 'n' + maxCount,
				moved: false,
				static: false,
				isResizable: false,
			});
			data['layout']['xs'] = user_settings.layout.xs.concat({
				w: 6,
				h: 3,
				x: 0,
				y: maxYXS,
				i: 'n' + maxCount,
				moved: false,
				static: false,
				isResizable: false,
			});
			data['layout']['xxs'] = user_settings.layout.xxs.concat({
				w: 6,
				h: 2,
				x: 0,
				y: maxYXXS,
				i: 'n' + maxCount,
				moved: false,
				static: false,
				isResizable: false,
			});
			data['charts'] = user_settings.charts.concat({
				i: 'n' + maxCount,
				type: new_data.type,
				name: new_data.name,
				active: new_data.active,
				stacked: new_data.stacked,
				start_date: new_data.start_date,
				end_date: new_data.end_date,
				alerts: new_data.alerts,
				events: new_data.events,
				devices: new_data.devices,
				devices_custom: new_data.devices_custom,
			})

			saveDashboardData(JSON.stringify(data))
				.then((res, err) => {
					console.log('!saveDashboardData res', res);
					toggleLoader(false);
					if (res.status == 200) {
						toggleLoader(false);
						toastr.success("Success", "A new reports was added successfully");
						updateCharts();
					}

				})
				.catch((error) => {
					console.log('!saveDashboardData error', error);
					toggleLoader(false);
					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription);
				});
		} else {
			toastr.error('Maximum allowed reports have been reached. Please remove an existing reports or graph')
		}
	}

	const beforeSave = async (chart) => {
		if (await checkReports(chart.i)) {
			let changed_state = user_settings.charts
			changed_state.map((item, i) => {
				if (item.i === chart.i) {
					changed_state[i] = chart;
				}
			});
			setUser_settings({ ...user_settings, charts: changed_state });

			setTimeout(() => edit(), 1000)
		}
	}

	const edit = async (action, user_data = null, i = null) => {
		if (i ? await checkReports(i) : true) {
			const data = JSON.stringify(user_data || user_settings);
			toggleLoader(true);

			return saveDashboardData(data)
				.then((res, err) => {
					console.log('!edit saveDashboardData res', res);
					toggleLoader(false);
					if (res.status == 200) {
						action !== "delete" && toastr.success("Success", "The changes were saved");
						updateCharts();
					}
					return true;

				})
				.catch((error) => {
					console.log('!edit saveDashboardData error', error);
					toggleLoader(false);

					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription);
					return false;
				});
		}
	}

	const editChart = (e, chart) => {
		let chart_clone = JSON.stringify(chart);
		chart_clone = JSON.parse(chart_clone);
		setEdit_report_show(true);
		setEditable_chart(chart_clone);
		setModal_type('edit');
	}

	const onHideEditReport = () => {
		setEdit_report_show(false);
		setEditable_chart('');
		setModal_type('');
	}

	const addChart = () => {
		if (user_settings.charts && user_settings.charts.length >= 10) {
			toastr.error('Maximum allowed reports have been reached. Please remove an existing reports or graph');
		}
		else {
			setEdit_report_show(true);
			setModal_type('add');
		}
	}

	const checkReports = async (i) => {
		let mapObj = [];
		await getDashboardData()
			.then((res, err) => {
				const dashboardMap = JSON.parse(JSON.stringify(res.data.response.settings.dashboard_data));
				dashboardMap && JSON.parse(dashboardMap).charts.map((chart) => { mapObj.push(chart.i) })
			})

		if (!mapObj.includes(i)) {
			toastr.warning(`Warning`, 'This chart does not exist');
			updateCharts();
			return false;
		} else {
			return true;
		}
	}

	const user_role = user.user.roles[0];
	const user_permissions = user.user.permissions;

	return (
		<div className='reports-list-container'>
			<div className="chart-buttons">
				{(!['custom_user', 'custom_partner'].includes(user_role) || (['custom_user', 'custom_partner'].includes(user_role) && _.find(user_permissions, { 'perm_category_id': 3, 'a_add': true }))) &&
					<CustomButton
						variant="primary"
						prefix="flex-btn"
						onClick={(e) => addChart(e)}
					>
						<BsPlusLg /> Add New Report
					</CustomButton>
				}
				{(!['custom_user', 'custom_partner'].includes(user_role) || (['custom_user', 'custom_partner'].includes(user_role) && _.find(user_permissions, { 'perm_category_id': 3, 'a_delete': true }))) &&
					<CustomButton
						variant='delete'
						prefix="flex-btn"
						onClick={(e) => deleteAllChartConfirm(e)}
					>
						<BsXLg /> Delete All Reports
					</CustomButton>
				}
			</div>

			{user_settings && user_settings.charts &&
				user_settings.charts.map((charts, i) => {
					return (
						<div className={charts.active ? 'on form-group report-item' : 'off form-group report-item'} key={charts.i}>
							<div className="report-control">
								<label>
									<Toggle
										className="custom-toggle"
										name="active"
										checked={charts.active}
										onChange={(e) => { handleInputChange(e, charts.i) }}
									/>
								</label>
								<i className="fa fa-edit" onClick={(e) => { editChart(e, charts) }}></i>

								<i className="fa fa-remove" onClick={() => { deleteChartConfirm(charts.i) }}><BsXLg /></i>
							</div>
							<div className="report-name">{charts.name}</div>
						</div>
					)

				})
			}

			{edit_report_show &&
				<EditReport
					show={edit_report_show}
					onHide={onHideEditReport}
					chart={editable_chart}
					events={events}
					alerts={alerts}
					devices={devices}
					user_role={user.user.roles[0]}
					company={company}
					beforeSave={beforeSave}
					edit={edit}
					addOneChart={addOneChart}
					modalType={modal_type}
					user_charts={user_settings.charts}
				/>
			}

			<Modal
				show={modal.show}
				onHide={closeModal}
				dialogClassName="has-error"
				className="modal-lg-size"
			>
				<Modal.Header closeButton>
					<Modal.Title>Delete Report</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='modal-text text-center'>{modal.content}</div>
				</Modal.Body>
				<Modal.Footer bsPrefix="default-modal-footer modal-footer">
					{deleteReports === 'one' ?
						<CustomButton
							variant="delete"
							onClick={removeChart}
						>
							Delete
						</CustomButton> :
						<CustomButton
							variant="delete"
							onClick={deleteAllCharts}
						>
							Delete All Reports
						</CustomButton>
					}
				</Modal.Footer>
			</Modal>

		</div>
	)
}

export default connect(
	state => ({
		user: state.user,
		company: state.company.company
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(ReportsList);
