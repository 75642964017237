import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import classnames from 'classnames'

import { getDevicesList } from '../../../action/DeviceActions'
import VehicleSelectBox from '../../dump/menuComponents/VehicleSelectBox'
import CategoryItem from '../../dump/menuComponents/CategoryItem'
import CustomButton from '../../CustomButton'

const FleetConfigurationModal = (props) => {
	const { profile, profiles, company, isNewProfile, saveProfile, onHide, toggleLoaderModal, configurationdv6, webfleetDeviceId, isDV6ProfileUpdated, isDV4ProfileUpdated } = props

	const [profileName, setProfileName] = useState('')
	const [errMessage, setErrMessage] = useState({ errProfileName: '' })
	const [totalVehicles, setTotalVehicles] = useState([])
	const [profileId, setProfileId] = useState(props.profile.id)
	const [vehicleSearch, setVehicleSearch] = useState('') 
	const [currentVehicles, setCurrentVehicles] = useState([]) // list of devices where uploaded_company_profile = profile.name, i.e. devices which used the current updated profile
	const [availableVehicles, setAvailableVehicles] = useState([]) // list of devices available for selection
	const [vehicle_ids, setVehicle_ids] = useState([]) // list of device ids where we send new or updated profile
	const [action, setAction] = useState(props.action)
	const [step, setStep] = useState(1)

	useEffect(() => {
		toggleLoaderModal(true);
		getDevicesList(company.company_id)
			.then(res => {
				let totalVehicles = res.data.response.devices
				if (webfleetDeviceId) {
					const arr = webfleetDeviceId.split(',')
					totalVehicles = totalVehicles.filter(item => arr.includes(item.device_id))
				}

				const vehicle_ids = []
				const currentVehicles = []
				let availableVehicles = []
				if (!isNewProfile && action !== 'Push') {
					totalVehicles.forEach(vehicle => {
						if (vehicle.uploaded_company_profile === profile.name) {
							// currentVehicles.push(vehicle)
							// vehicle_ids.push(vehicle.device_id)

							// FWSD-6734
							if (
								(isDV6ProfileUpdated && vehicle.device_type === 'dv6') 
								|| (isDV4ProfileUpdated && vehicle.device_type === 'dv4')
							) {
								currentVehicles.push(vehicle)
								vehicle_ids.push(vehicle.device_id)
							}
						}
						else {
							availableVehicles.push(vehicle)
						}
					})
				}
				else {
					availableVehicles = res.data.response.devices;
				}

				// FWSD-6734
				if (isDV6ProfileUpdated && !isDV4ProfileUpdated) {
					availableVehicles = availableVehicles.filter(({device_type}) => device_type === 'dv6')
				}
				else if (isDV4ProfileUpdated && !isDV6ProfileUpdated) {
					availableVehicles = availableVehicles.filter(({device_type}) => device_type === 'dv4')
				}

				setTotalVehicles(totalVehicles)
				setCurrentVehicles(currentVehicles)
				setAvailableVehicles(availableVehicles)
				setVehicle_ids(vehicle_ids)
				currentVehicles.length === 0 && setStep(2)

				toggleLoaderModal(false);
			})
			.catch(err => {
				toggleLoaderModal(false);
			})
	}, [])

	const setVehicles = (vehicle_ids) => {
		setVehicle_ids(vehicle_ids)
	}

	const addProfile = () => {
		if (!profileName) {
			setErrMessage({
				errProfileName: 'Please enter a profile name',
			})
		} else if (profileName.toLowerCase() === 'manual') {
			setErrMessage({
				errProfileName: '"manual" is restricted name for fleet profiles',
			})
		} else if (profileName.toLowerCase() === 'default') {
			setErrMessage({
				errProfileName: "You cannot edit the 'Default' profile. Please enter a different name.",
			})
		} else if (profileName.length > 128) {
			setErrMessage({
				errProfileName: "Profile name must be less than 128 characters",
			})
		} else if (profiles.find(profile => profile.name === profileName)) {
			setErrMessage({
				errProfileName: "A profile with this name already exists.",
			})
		} else {
			saveProfile(vehicle_ids, profileName);
		}
	}

	const pushProfile = () => {
		onHide()
		props.pushProfile(profileId, vehicle_ids)
	}

	const { errProfileName } = errMessage;

	const vehicleIdsLength = vehicle_ids.length;
	const vehiclesTerm = currentVehicles.length > 1 ? 'vehicles' : 'vehicle';
	const vehiclesActionTerm = `${vehicleIdsLength} ${vehicleIdsLength > 1 ? 'Vehicles' : 'Vehicle'}`

	// console.log('PROPS FleetConfigurationModal: ', props)

	return (
		<Modal
			show
			onHide={onHide}
			className='config-modal modal-lg-size'
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">{`${action} Fleet Profile`}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='config-modal-body'>
					{action === 'Update' && (
						step === 1 ? (
							<div>
								{currentVehicles.length > 0 &&
									<div>
										<label>The profile <strong>{profile.name}</strong> is applied to the following {vehiclesTerm}:</label>
										<div className='profile-vehicle-list'>
											{currentVehicles.map((vehicle) => {
												return (
													<CategoryItem
														key={vehicle.device_id}
														text={vehicle.vehicle_name || vehicle.device_id}
														small
													/>
												);
											})}
										</div>
										<label>
											You can save this profile to automatically update the {vehiclesTerm} listed above
										</label>
										<div className="profile-action">
											<CustomButton
												type="button"
												variant="primary" onClick={() => setStep(2)}>
												Continue (Update Vehicles)
											</CustomButton>
										</div>
										<div className='text-center message'><strong>OR</strong></div>
										<label>
											You can create a new profile. You will still be able to immediately apply this profile to vehicles in your fleet.
										</label>
										<div className="profile-action">
											<CustomButton variant="primary-outline" onClick={() => {
												setAction('Add')
												setVehicle_ids([])
											}}>
												Create a New Profile
											</CustomButton>
										</div>
									</div>
								}
							</div> 
						) : (
							<div>
								{currentVehicles.length === 0 ?
									<label>
										The profile <strong>{profile.name}</strong> is not currently applied to any vehicles. To apply this profile,
										select any number of vehicles below before clicking <strong>Update</strong>. Applying a profile will immediately reboot the device.
									</label> :
									<label>
										Select any additional vehicles you would like to push this profile to (not required).
										Applying a profile will immediately reboot the device.
									</label>}
								{availableVehicles.length > 0 &&
									<VehicleSelectBox
										configurationdv6={configurationdv6}
										vehicles={availableVehicles}
										selectedVehicleIds={vehicle_ids}
										onToggle={setVehicles}
									/>
								}
							</div>
						)
					)}
					{(action === 'Add' || action === 'Push') &&
						<div>
							{action === 'Add'
								? (
									<div>
										<div className={classnames('form-group', { 'has-error': errProfileName })}>
											<label>Choose a name for your new profile:</label>
											<input
												name="profileName"
												className="vehicle-search"
												placeholder='Profile Name'
												onFocus={() => setErrMessage({ errProfileName: '' })}
												onChange={(e) => setProfileName(e.target.value)}
												value={profileName || ''} />
											{errProfileName && <span className="error-message">{errProfileName}</span>}
										</div>
										<label>If you would like to apply this profile immediately to certain vehicles, please select them from the list below.</label>
									</div> 
								) : (
									<label>Select any number of vehicles below to apply this profile to:</label>
								)
							}
							{(totalVehicles && totalVehicles.length > 0) &&
								<VehicleSelectBox
									configurationdv6={configurationdv6}
									vehicles={totalVehicles}
									selectedVehicleIds={vehicle_ids}
									onToggle={setVehicles}
								/>
							}
							{!isNewProfile && action !== 'Push' &&
								<div>
									<hr />
									<span className='message'>If you would like to go back to updating the existing profile instead, click <CustomButton variant="link" onClick={() => setAction('Update')}>here</CustomButton></span>
								</div>
							}
						</div>
					}
				</div>
			</Modal.Body>
			<Modal.Footer>
				{action === 'Update' && step === 2 &&
					<div>
						{currentVehicles.length > 0 && // current profile is already applied to some vehicles
							<CustomButton type="button" variant="primary-outline" onClick={() => setStep(1)}>
								Back
							</CustomButton>
						}
						<CustomButton type="button" variant="primary" onClick={() => props.saveProfile(vehicle_ids)}>
							{vehicleIdsLength
								? `Save and Update ${vehiclesActionTerm}`
								: 'Save'
							}
						</CustomButton>
					</div>
				}
				{action === 'Add' &&
					<CustomButton type="button" variant="primary" onClick={addProfile}>
						{vehicleIdsLength ?
							`Create Profile and Update ${vehiclesActionTerm}` :
							'Create Profile'
						}
					</CustomButton>
				}
				{action === 'Push' && vehicleIdsLength > 0 &&
					<CustomButton type="button" variant="primary" onClick={pushProfile}>
						{`Push Profile to ${vehiclesActionTerm}`}
					</CustomButton>
				}
			</Modal.Footer>
			{props.loaderModal}
		</Modal>
	);
}


FleetConfigurationModal.propTypes = {
	webfleetDeviceId: PropTypes.string,
}

FleetConfigurationModal.defaultProps = {
	webfleetDeviceId: '',
}

const mapStateToProps = ({ company, webfleetData }) => ({
	company: company.company,
	webfleetDeviceId: webfleetData.deviceId,
})

const mapDispatchToProps = (dispatch) => {
	return {
		toggleLoaderModal: (show) => {
			dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FleetConfigurationModal)
