// FWSD-2842
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import _ from 'lodash'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'

import SnapshotChannelNode from '../../../component/smart/modals/SnapshotChannelNode'
import momenttz from '../../../component/helpers/momenttz'
import { apiCall } from '../../../action/RouterActions'

import './MarkerAlertPopup.sass'

momentDurationFormatSetup(moment);

const MarkerAlertPopup = (props) => {

	const { timestamp, eventType, event, snapshots, mapUI, position, onCloseAlertWindow, setTripAdvancedPlayerEvent, setTripShowShareEvent, toggleLoader, setEventArchiveRestoring } = props;

	const [activeChannel, setActiveChannel] = useState(null)
	const [channelsState, setChannelsState] = useState([])

	useEffect(() => {
		// console.log('componentDidMount MarkerAlertPopup')
		setChannels(snapshots)

		// setTimeout(() => {
		// 	$('.gm-style-iw-c').css({
		// 		'border-radius': '8px'
		// 	})
		// 	$('.gm-ui-hover-effect').css({
		// 		'bottom': '225px', 'right': '0px', 'top': '-8px', 'border-bottom-left-radius': '8px', 'background-color': '#fff', 'width': '25px', 'height': '38px'
		// 	})
		// 	$('.gm-ui-hover-effect images').css({
		// 		'width': '25px !important', 'height': '25px !important'
		// 	})
		// }, 10)
	}, [])

	useEffect(() => {
		console.log('componentWillReceiveProps MarkerAlertPopup')
		setChannels(snapshots)

		return () => {
			console.log('componentWillUnmount MarkerAlertPopup');
			const { setTripAdvancedPlayerEvent, setTripShowShareEvent, onCloseAlertWindow } = props
			setTripAdvancedPlayerEvent(null)
			setTripShowShareEvent(null)
		}
	}, [snapshots])

	useEffect(() => {
		const bubbleElm = new H.ui.InfoBubble(position, { content: content });
		bubbleElm.addClass("alertPopupBubble");
		mapUI.addBubble(bubbleElm);

		return () => {
			mapUI.removeBubble(bubbleElm);
		}
	}, [event, activeChannel])

	const setChannels = (snapshots) => {
		// console.log('setChannels: ', snapshots)
		const channels = []
		let activeChannel = null

		const re = /\/(\d).jpeg?/i
		snapshots.forEach((item) => {
			const found = item.match(re)
			if (found) {
				channels.push({ number: found[1], src: item })
			}
		})
		activeChannel = channels[0] || null

		setChannelsState(channels);
		setActiveChannel(activeChannel);
	}

	const handleCloseInfoWindow = () => {
		const { device_id, onCloseInfo } = props
		onCloseInfo(device_id)
	}

	globalThis.g_changeChannel = (channelNumber) => {
		channelNumber = channelNumber.toString()
		console.log('changeChannel: ', channelNumber)

		if (activeChannel.number === channelNumber) {
			return
		}

		const obj = channelsState.find(({ number }) => number === channelNumber)
		if (obj) {
			setActiveChannel(obj)
		}
	}

	const channelsList = () => (
		channelsState.map(({ number }) => (
			(`<li class="list-inline-item ${(activeChannel.number === number) && 'active'}">
				<a onclick="g_changeChannel(${number})">${number}</a>
			</li>`)
		))
	)

	globalThis.g_onFlagEvent = () => {
		const { event, setProps } = props
		const { id, flagged } = event

		toggleLoader(true)
		if (!flagged) {
			apiCall('PUT', '/events/{id}/flagged', { id })
				.then((res, err) => {
					toggleLoader(false)
					setProps({ event: { ...event, flagged: true } })
				})
				.catch((error) => {
					toggleLoader(false)
					console.log('!onFlagEvent put error:', error.response, error)

					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				})
		} else {
			apiCall('DELETE', '/events/{id}/flagged', { id })
				.then((res, err) => {
					toggleLoader(false)
					setProps({ event: { ...event, flagged: false } })
				})
				.catch((error) => {
					toggleLoader(false)
					console.log('!onFlagEvent delete error:', error.response, error)

					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				})
		}
	}

	globalThis.g_setTripAdvancedPlayerEvent = () => {
		setTripAdvancedPlayerEvent(event)
	}

	globalThis.g_setTripShowShareEvent = () => {
		setTripShowShareEvent(event);
	}

	globalThis.g_setEventArchiveRestoring = (typeArg) => {
		setEventArchiveRestoring({ showModal: true, message_type: typeArg, event_id: event.event_id })
	}

	// console.log('PROPS MarkerAlertPopup: ', props)

	const content = `<div class="markerAlertPopup" style="z-index: 1000002">
		<div class="imgWrapper ${!activeChannel && 'no-snapshot'}" onclick="g_setTripAdvancedPlayerEvent()">
		${activeChannel ? `<img src="${activeChannel.src}" alt="" />` : '<span class="snapshot-error-message">No Snapshot Available</span>'}
		</div>
		<div class="controlsWrapper">
			<div style="margin-bottom: 15px">
				<span class="eventType">
					${eventType}
				</span>
				<span class="eventTime">
					${momenttz(`${timestamp}+00`).format('h:mm a')}
				</span>
			</div>
			<div class="controls">
				<ul class="channelsList">
					${channelsList().join("")}
				</ul>
				<div class="controlButtons">
					${event.archive === 'archived' ? '<button class="button archivedButton" title="Restore Event" onclick="g_setEventArchiveRestoring(\'event_restore\')" />' : ''}
					${event.archive === 'restoring' ? '<button class="button archivedButton restoring" title="Event Restoring" onclick="g_setEventArchiveRestoring(\'event_restoring\')" />' : ''}

					${event.uploading_status === 'VIDEO_UPLOADED' ? '<button class="button playButton" title="Play Video" style="font-size: 26px" onclick="g_setTripAdvancedPlayerEvent()" />' : ""}
					${((event.uploading_status !== 'VIDEO_UPLOADED') && (event.snapshot_status === 'SNAPSHOT_UPLOADED')) ? '<button class="button snapshotButton" title="View Snapshot" style="font-size: 26px; margin-right: 2px" onclick="g_setTripAdvancedPlayerEvent()" />' : ""}
					${props.user.user.roles[0] !== "sales_demo" ? '<button class="button shareButton" title="Share Event" onclick="g_setTripShowShareEvent()" />' : ""}
					<button class="button flaggedButton ${event.flagged && 'on'}" title="${event.flagged ? 'Unflag' : 'Flag'} Event" onclick="g_onFlagEvent()" />
				</div>
			</div>
		</div>
		</div>`;

	return (<></>)
}

const mapStateToProps = ({ user }) => ({
	user
});

const mapDispatchToProps = dispatch => ({
	setTripAdvancedPlayerEvent: (state) => {
		dispatch({ type: 'SET_TRIP_ADVANCED_PLAYER_EVENT', payload: state })
	},
	setTripShowShareEvent: (state) => {
		dispatch({ type: 'SET_TRIP_SHOW_SHARE_EVENT', payload: state })
	},
	setEventArchiveRestoring: (state) => {
		dispatch({ type: 'SET_EVENT_ARCHIVE_RESTORING', payload: state });
	},
	toggleLoader: (show) => {
		dispatch({ type: 'TOGGLE_LOADER', payload: show });
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(MarkerAlertPopup);
