import React from 'react';
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Polyline, Marker, InfoWindow } from "react-google-maps"
import { googleMapStylesList } from '../helpers/constants'

const SimpleMapExampleGoogleMap = compose (
	withProps({
		googleMapURL:
			"https://maps.googleapis.com/maps/api/js?v=3.59&libraries=geometry,drawing,places&key=AIzaSyABDYlC67NC9TFAUxh-wZQKqt1qNiovg54",
		loadingElement: <div style={{ height: `100%` }} />,
	}),
	withScriptjs,
	withGoogleMap
  )
  ((props) => {
	const { direction, coordinates, polyline, accidentTime } = props;

	const lineSymbol = {
		path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW, // 'M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z',
		fillOpacity: 1,
		scale: 4,
		rotation: direction,
		// fillColor: '#000',
		// strokeWeight: 1,
		// anchor: new google.maps.Point(12, 0),
	};

	const addLocation = () => {
		new google.maps.Geocoder().geocode({
			'latLng': new google.maps.LatLng(coordinates.lat, coordinates.lng)
		}, (results, status) => (status === google.maps.GeocoderStatus.OK) ? localStorage.setItem('accidentLocal', results[0].formatted_address) : localStorage.setItem('accidentLocal', 'Unknown'));
		return localStorage.getItem('accidentLocal');
	};

	return (
		<GoogleMap
			defaultZoom={18}
			defaultCenter={{ lat: -34.397, lng: 150.644 }}
			center={coordinates}
			options={{
				styles: googleMapStylesList.default_map, // FWSD-3757, FWSD-4457
			}}
		>
			{!!accidentTime && <InfoWindow position={coordinates}>
				<div style={{ backgroundColor: `white`, padding: `5px` }}>
					<div style={{ fontSize: `10px`, fontColor: `#08233B` }}>
						<div style={{ margin: "10px" }}>
							<p style={{ fontWeight: 'bold', margin: 0 }}>Time of Collision:</p>
							<p style={{ margin: 0 }}>{accidentTime}</p>
						</div>
						<div style={{ margin: "10px" }}>
							<p style={{ fontWeight: 'bold', margin: 0 }}>Location:</p>
							<p style={{ margin: 0 }}>{addLocation()}</p>
						</div>
					</div>
				</div>
			</InfoWindow>}
			<Polyline
				path={polyline}
				options={{
					geodesic: true,
					strokeColor: '#FF0000',
					strokeOpacity: 1.0,
					strokeWeight: 2,
				}}
			// key={index}
			// show={props.showroute}
			// onRightClick={() => console.log("you clicked")}
			// key={Date.now() + Math.random()}
			// options={{strokeColor: 'red',strokeWeight: 6}}
			/>

			<Marker
				position={coordinates}
				icon={lineSymbol}
			/>
		</GoogleMap>
	)
})

export default SimpleMapExampleGoogleMap;
