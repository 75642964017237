import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'

import { apiCall } from '../../../action/RouterActions'
import CustomButton from '../../CustomButton'

const ModalEventLabel = (props) => {
	const { event, onSaveEventLabel, onHide, toggleLoader } = props;
	const [label, setLabel] = useState(event.event_label || "");

	const saveLabel = () => {
		toggleLoader(true);

		const params = { event_label: label };
		apiCall('POST', `/event/${event.id}/label`, params)
			.then(() => {
				onSaveEventLabel(label);
				toggleLoader(false);
				toastr.success('', 'Display Label Saved');
				onHide();
			})
			.catch((error) => {
				toggleLoader(false);
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	return (
		<Modal
			show
			onHide={onHide}
			className="modalEventLabel modal-lg-size"
		>
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title id="contained-modal-title-lg">
					{`${event.event_label ? "Edit" : "Add"} Display Label`}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<p>To change the display label of this event, enter a new display label value below. This value is then displayed with the event anywhere this event is viewed. This does not change the event type and will not effect driver scoring.</p>
					<div className="col-xs-6 col-sm-6 form-group" style={{ paddingTop: "5px" }}>
						<label className="control-label">New Display Label</label>
						<input type="text" className="vehicle-search" value={label} onChange={e => setLabel(e.target.value)} style={{ width: "350px" }} />
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div>
					<CustomButton variant="primary-outline" onClick={onHide}>
						Back
					</CustomButton>
					<CustomButton variant="primary" onClick={saveLabel}>
						Save
					</CustomButton>
				</div>
			</Modal.Footer>
		</Modal>
	);
}

ModalEventLabel.propTypes = {
	event: PropTypes.objectOf(PropTypes.any).isRequired,
	onHide: PropTypes.func.isRequired
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(ModalEventLabel);
