// FWSD-3520
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// import { Polyline } from 'react-google-maps'
import { connect } from 'react-redux'

import TripSpeedEventPopup from '../TrackTrace/TripSpeedEventPopupHM'
import { getSpeedEventsFiltersColors } from '../../../component/helpers/helperTripsGrid'

const TripSpeedEvents = (props) => {

	const { tripsSpeedEvents, tripsSpeedEventsFilters, device_id, map } = props;

	const [showSpeedEventPopup, setShowSpeedEventPopup] = useState(false);
	const [hoveredEvent, setHoveredEvent] = useState(null);
	const [hoveredLatLng, setHoveredLatLng] = useState(null);
	const [polyline, setPolyline] = useState(null);

	useEffect(() => {
		if (tripsSpeedEvents && tripsSpeedEvents.length > 0) {
			tripsSpeedEvents.map((item, index) => {
				if (tripsSpeedEventsFilters[item.event_type]) {
					const polylineOptions = {
						strokeColor: 'rgba(66, 104, 168, 1.0)',
						lineWidth: 5,
					};

					const dashedPolylineOptions = {
						strokeColor: 'rgba(255, 255, 255, 0.5)',
						lineWidth: 3,
						lineDash: [1, 7],
						lineHeadCap: "arrow-head",
						lineTailCap: "arrow-tail",
					};

					const polyline = new H.map.Polyline(item.positions, { style: { ...polylineOptions, strokeColor: getSpeedEventsFiltersColors[item.event_type] } });
					const dashedPolyline = new H.map.Polyline(item.positions, { style: dashedPolylineOptions });

					polyline.addEventListener("pointerenter", (e) => handleMouseOver(e, item), true);
					polyline.addEventListener("pointermove", (e) => handleMouseOver(e, item), true);
					polyline.addEventListener("pointerleave", (e) => setShowSpeedEventPopup(false), true);
					map.addObject(polyline);
					map.addObject(dashedPolyline);
					setPolyline(polyline);
				}
			})
		}

		return () => {
			map && map.getObjects().length && map.removeObjects(map.getObjects());
			if (polyline) {
				polyline.removeEventListener("pointerenter", (e) => handleMouseOver(e), true);
				polyline.removeEventListener("pointermove", (e) => handleMouseOver(e), true);
				polyline.removeEventListener("pointerleave", (e) => setShowSpeedEventPopup(false), true);
			}
		}
	}, [])

	const handleMouseOver = (e, speedEvent) => {
		// console.log('handleMouseOver e.latLng: ', e.latLng)
		const position = map.screenToGeo(e.currentPointer.viewportX, e.currentPointer.viewportY);
		setHoveredEvent(speedEvent);
		setHoveredLatLng(position);
		setShowSpeedEventPopup(true);
	}

	return (
		<div>
			{showSpeedEventPopup && (
				<TripSpeedEventPopup
					event={hoveredEvent}
					device_id={device_id}
					position={hoveredLatLng}
				/>
			)}
		</div>
	)
}

TripSpeedEvents.propTypes = {
	device_id: PropTypes.string,
	tripsSpeedEvents: PropTypes.arrayOf(PropTypes.any).isRequired,
	tripsSpeedEventsFilters: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = ({ dashboardData }) => ({
	tripsSpeedEvents: dashboardData.tripsSpeedEvents,
	tripsSpeedEventsFilters: dashboardData.tripsSpeedEventsFilters
});

export default connect(mapStateToProps)(TripSpeedEvents);
