import React, { useState, useEffect } from 'react'
import { toastr } from 'react-redux-toastr'
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { connect } from 'react-redux'
import _, { map } from 'lodash'
import PropTypes from 'prop-types'
import moment from 'moment'
import { RiAlertFill } from 'react-icons/ri'

import CustomButton from '../../CustomButton'
import { CustomButtonToggle } from '../../DropdownComponents'
import CustomOverlayTrigger from '../../CustomOverlayTrigger'
import SearchBar from './SearchBar'
import ManageAssetsContainer from '../../smart/menuComponentsContainer/ManageAssetsContainer'
import AddAssetContainer from '../../smart/menuComponentsContainer/AddAssetContainer'
import ModalDeviceOfflineDisabled from '../../../pages/vehicles/ModalDeviceOfflineDisabled'
import ModalChangeConfBatch from '../../smart/modals/ModalChangeConfBatch'
import ModalFormatSDBatch from '../../smart/modals/ModalFormatSDBatch'
import LabelTag from '../../../component/dump/LabelTag'
import InfoPop from '../../../component/common/InfoPop'

import { getCompanyById } from '../../../action/CompaniesActions'
import { /* getAllDevices, */ getDevicesList } from '../../../action/DeviceActions'
// import { apiCallGet } from '../../../action/RouterActions'
// import ModalUpgradeFirmwareBatch from '../../smart/modals/ModalUpgradeFirmwareBatch'
import { getDeviceDataLimit, detectDeviceStatus, getDeviceStatusName, deviceStatusPopups } from '../../helpers/helperVehicle'
import { isInternalUser, isInternalUserWithPartner } from '../../helpers/constants'
import { cameraHealthIcons, columnSelectIcon } from '../../helpers/iconHelpers'
import { getAvailableFleetStatuses } from '../../helpers/helperSleepMode'
import { sendGA } from '../../helpers/helperUsers'

import './ManageVehicles.sass'

const ManageAssets = (props) => {
	const { user, company, toggleLoader, goToCameraHealth, updateHasUnacknowledgedAlerts } = props;
	const userRole = user.roles[0];

	const [searchText, setSearchText] = useState('');
	const [devices, setDevices] = useState([]);
	const [initialData, setInitialData] = useState([]);
	const [companyData, setCompanyData] = useState({});
	const [highlightData, setHighlightData] = useState(false);
	const [lastFirmwarePackages, setLastFirmwarePackages] = useState([]);
	const [showUpdateProfile, setShowUpdateProfile] = useState(false)
	const [showUpdateFirmware, setShowUpdateFirmware] = useState(false)
	const [showFormatStorage, setShowFormatStorage] = useState(false)
	const [columns, setColumns] = useState(JSON.parse(localStorage.getItem('vehicleColumns')) || {
		channelStatus: true,
		group: true,
		configProfile: true,
		onlineStatus: true,
		totalDataUsage: true,
		streamDataUsage: false,
		liveStream: true,
		recordingStatus: !isInternalUser(userRole),
		// firmwareStatus: true,
		firstConnected: false,
		id: false,
		vinNumber: false,
		plateNumber: false,
		imei: false,
		auditLog: false,
		no_sd_card: false,
	});
	const [selectedDevices, setSelectedDevices] = useState([]);

	const [sortDevicesName, setSortDevicesName] = useState('vehicle_name')
	const [sortDevicesOrder, setSortDevicesOrder] = useState('asc')
	const [devicesOfflineDisabled, setDevicesOfflineDisabled] = useState('')
	// const [fleetStatuses, setFleetStatuses] = useState({
	// 	driving: 0,  sleep: 0, offline: 0, parked: 0, awake: 0, disabled: 0
	// })

	useEffect(() => {
		setSearchText('');
		getCompanyDevices();
		setLastViewedAssetsList();

		return () => {
			setLastViewedAssetsList();
			setSelectedDevices([]);
		}
	}, [company.company_id]);

	const getCompanyDevices = () => {
		toggleLoader(true);

		if (!company || (!company.name || !company.company_id)) {
			toggleLoader(false)
			return
		}

		const requestArray = []
		requestArray.push(getCompanyById(userRole, company.company_id, 'data_usage'))
		// requestArray.push(getAllDevices(userRole, company.name, company.partner, null, true, 'sd_status,channel_status,data_usage,ignition,latest_hdderror'));
		requestArray.push(getDevicesList(company.company_id, 'inactive_devices,latest_hdderror,data_usage,ignition,sd_status,channel_status,driver,live_stream,no_sd_card,camera_health'))
		// userRole !== 'custom_user' && requestArray.push(apiCallGet('/firmware/last/packages'));
		// const cameraHealthWarn = devices.reduce((errors, vehicle) => {
		// 	return errors + vehicle.camera_health.warn
		// }, 0)
		// const cameraHealthCrit = devices.reduce((errors, vehicle) => {
		// 	return errors + vehicle.camera_health.crit
		// }, 0)
		// The Camera Health tab will have a red dot (bubble) indicator when there are unacknowledged errors on any camera, FWSD-6457
		let hasUnacknowledgedAlerts = false
		Promise.all(requestArray)
			.then(res => {
				const companyData = res[0].data.response.company;
				const initDevices = res[1].data.response.devices.map((item) => {
					item.cameraError = (item.channel_status && item.channel_status.length && item.channel_status.find(channel => channel.fail_ts > (channel.success_ts || '1970-01-01 00:00:00') && item.channel_available.includes(channel.channel))) ? 1 : 0;

					item.recordingError = (!_.isEmpty(item.latest_hdderror) && item.latest_hdderror.count) > 0 ? 1 : 0
					// FWSD-5221
					item.liveStreamError = item.live_stream.duration_month > 6000 ? 1 : 0

					// FWSD-4314 - If device does not receive GPS from device and ignition is Off more 24 hours (1440 mins) then display Online status.
					// Device stopped receive GPS, broken antena or something, and FileServer does not know when ignition is On.
					// if ((row.ignition && row.ignition.state) || (!row.gps_online && durationInMins > 1440)) {
					// let onlineStatus = 'parking'
					// if (!item.online) {
					// 	onlineStatus = 'offline'
					// }
					// else {
					// 	const ignitionOffTS = item.ignition && item.ignition.ts ? item.ignition.ts : ''; // 2023-01-04 18:50:37
					// 	const duration = moment.duration(moment.utc().diff(moment.utc(ignitionOffTS), 'seconds'), 'seconds')
					// 	const durationInMins = duration.asMinutes()

					// 	if ((item.ignition && item.ignition.state) || (!item.gps_online && durationInMins > 1440)) {
					// 		onlineStatus = 'online'
					// 	}
					// }
					// item.onlineStatus = onlineStatus

					if (
						!hasUnacknowledgedAlerts && item.camera_health && 
						((isInternalUser(userRole) && item.camera_health.unacknowledged > 0) || item.camera_health.unacknowledged_sd20 > 0)
					) {
						hasUnacknowledgedAlerts = true
					}

					item.status = detectDeviceStatus(item)

					return item;
				})
				// const last_firmware_packages = res[2] && res[2].data.response.last_firmware_packages

				setCompanyData(companyData)
				// setLastFirmwarePackages(last_firmware_packages)
				setInitialData(initDevices)
				updateHasUnacknowledgedAlerts(hasUnacknowledgedAlerts)

				searchText ? dataSearch() : setDevices(initDevices);

				toggleLoader(false)
			})
			.catch(error => {
				toggleLoader(false)
				toastr.error('There was an issue getting your available vehicles. Please try again later.')
			})
	}

	const dataSearch = (e) => {
		const value = e ? e.target.value.toLowerCase() : searchText;
		const searchedDevices = _.cloneDeep(initialData).filter(device => {
			return (
				(device.device_id && device.device_id.toString().toLowerCase().includes(value))
				|| (device.vehicle_name && device.vehicle_name.toLowerCase().includes(value))
				// || (device.driver_name && device.driver_name.toLowerCase().includes(value))
				|| (device.group_name && device.group_name.toLowerCase().includes(value))
				|| (device.vin_number && device.vin_number.toLowerCase().includes(value))
				|| (device.serial_number && device.serial_number.toLowerCase().includes(value))
				|| (device.tag_plate && device.tag_plate.toLowerCase().includes(value))
			)
		})

		setDevices(searchedDevices)
		setSearchText(value)
	}

	const toggleCheckbox = ({ target }) => {
		const { name, checked } = target;
		sendGA({
			category: userRole,
			action: 'Select Column',
			label: (checked ? 'Add ' : 'Remove ') + name
		})
		const updatedColumns = { ...columns, ...{ [name]: checked } }
		setColumns(updatedColumns);
		localStorage.setItem('vehicleColumns', JSON.stringify(updatedColumns))
	}

	const selectDevice = (row) => {
		sendGA({
			category: userRole,
			action: 'Toggle Devices',
			label: 'Select One Device'
		})

		selectedDevices.includes(row.device_id) ?
			setSelectedDevices(selectedDevices.filter((id) => id !== row.device_id)) :
			setSelectedDevices([...selectedDevices, row.device_id])
	}

	const selectDevicesByError = (errType) => {
		let newSelectedDevices = [];
		let sortedDevices = devices;
		switch (errType) {
			case 'channel_status':
				// devices.forEach(e => {
				// 	if (e.channel_status.length) {
				// 		(e.channel_status.filter(channel => channel.fail_ts > (channel.success_ts|| '1970-01-01 00:00:00')).length ? newSelectedDevices.push(e) : null)
				// 	}
				// })
				// sortedDevices = sortedDevices.sort((a,b) => newSelectedDevices.map(e => e.device_id).indexOf(a.device_id) > -1 ? -1 : 1)
				newSelectedDevices = devices.filter(vehicle => vehicle.cameraError)
				setSortDevicesName('cameraError');
				setSortDevicesOrder('desc');
				sendGA({
					category: userRole,
					action: 'Toggle Devices',
					label: 'Channel Status Issue',
				})
				break;
			case 'sd_status':
				// newSelectedDevices = devices.filter(vehicle => vehicle.sd_status.recording_status === 'Needs Reformat')
				// sortedDevices = sortedDevices.sort((a,b) => a.sd_status.recording_status === 'Needs Reformat' ? -1 : 1)

				// newSelectedDevices = devices.filter(vehicle => !_.isEmpty(vehicle.latest_hdderror) && vehicle.latest_hdderror.count > 0)
				newSelectedDevices = devices.filter(vehicle => vehicle.recordingError)
				setSortDevicesName('recordingError');
				setSortDevicesOrder('desc');
				sendGA({
					category: userRole,
					action: 'Toggle Devices',
					label: 'SD Reformat',
				})
				break;
			case 'data_limit':
				newSelectedDevices = devices.filter(vehicle => (vehicle.data_usage / 1024 / 1024 / 1024 + vehicle.data_usage_live_stream / 1024 / 1024 / 1024) > (getDeviceDataLimit(vehicle.data_limit, companyData)));
				setHighlightData(true);
				setSortDevicesName('data_usage');
				setSortDevicesOrder('desc');
				sendGA({
					category: userRole,
					action: 'Toggle Devices',
					label: 'Data Over Limit',
				})
				break;
			case 'live_stream':
				newSelectedDevices = devices.filter(vehicle => vehicle.liveStreamError)
				setSortDevicesName('liveStreamError');
				setSortDevicesOrder('desc');
				sendGA({
					category: userRole,
					action: 'Toggle Devices',
					label: 'Live Stream Over Limit',
				})
				break;
			case 'no_sd_card':
				newSelectedDevices = devices.filter(vehicle => !!vehicle.no_sd_card)
				setSortDevicesName('no_sd_card');
				setSortDevicesOrder('desc');
				sendGA({
					category: userRole,
					action: 'Toggle Devices',
					label: 'SD Card Removal',
				})
				break;
			default:
				break;
		}

		newSelectedDevices = newSelectedDevices.map(vehicle => vehicle.device_id);
		if (_.isEqual(newSelectedDevices, selectedDevices)) {
			setSelectedDevices([])
		}
		else {
			newSelectedDevices.length && setSelectedDevices(newSelectedDevices)
		}
	}

	const selectAllDevices = (event) => {
		const { target } = event;
		const checked = target.type === 'checkbox' ? target.checked : target.value;
		let value = 'Deselect';

		let selectedDevices = []
		if (checked) {
			devices.map(device => { if (!!device.activated_timestamp) selectedDevices.push(device.device_id) });
			value = 'Select';
		}

		sendGA({
			category: userRole,
			action: 'Toggle Devices',
			label: value + ' All Devices'
		})

		setSelectedDevices(selectedDevices)
	}

	const handleActionButton = (modalType) => {
		let value = '';

		if (selectedDevices.length) {
			switch (modalType) {
				case 'showUpdateProfile':
					setShowUpdateProfile(true)
					value = 'Open Update Profile'
					break;
				case 'showFormatStorage':
					setShowFormatStorage(true)
					value = 'Open Format SD'
					break;
				default:
					break;
			}
			sendGA({
				category: userRole,
				action: 'Manage Vehicles Modal',
				label: value,
			})
		}
	}

	// const handleUpdateFirmwareButton = (outOfDateDevicesId) => {
	// 	if (outOfDateDevicesId.length) {
	// 		setShowUpdateFirmware(true)

	// 		sendGA({
	// 			category: userRole,
	// 			action: 'Manage Vehicles Modal',
	// 			label: 'Open Update Firmware',
	// 		})
	// 	}
	// }

	const clearSelection = () => {
		// setShowUpdateFirmware(false)
		setShowUpdateProfile(false)
		setSelectedDevices([])
		checkingDevicesOfflineDisabled()
	}

	const filterColumn = (e, column, value) => {
		let newDevices = [];
		if (column === 'clear_all') {
			// FWSD-5030
			if (!devices.length) {
				setDevices(initialData);
			}
			else {
				setDevices([]);
			}
			sendGA({
				category: userRole,
				action: 'Filter Column',
				label: 'Clear All'
			})
			return
		}

		if (!e.target.checked) {
			/* if (column === 'firmware_status' && value === 'firmware update') {
				newDevices = devices.filter(e => !e[column].includes('pending'))
			}
			else */
			// if (column === 'ignition') {
			// 	newDevices = devices.filter(e => !(e.online && (value ? (e.ignition && e.ignition.state === value) : ((e.ignition && e.ignition.state === value) || e.ignition === null))));
			// }
			// else if (column === 'sd_status') {
			// 	value === 'Needs Reformat' ?
			// 		newDevices = devices.filter(e => e.sd_status.recording_status !== 'Needs Reformat') :
			// 		newDevices = devices.filter(e => e.sd_status.recording_status === 'Needs Reformat')
			// }
			if (column === 'latest_hdderror') {
				value === 'Needs Reformat'
					? newDevices = devices.filter(e => _.isEmpty(e.latest_hdderror) || e.latest_hdderror.count === 0)
					: newDevices = devices.filter(e => !_.isEmpty(e.latest_hdderror) && e.latest_hdderror.count > 0)
			}
			else if (column === 'group_name') {
				newDevices = devices.filter(e => value === 'No group' ? (e.group_name && e.group_name.length) : e.group_name !== value);
			}
			else {
				newDevices = devices.filter(e => e[column] !== value);
			}
			e.target.checked = true;
		}
		else {
			/* if (column === 'firmware_status' && value === 'firmware update') {
				newDevices = [...initialData.filter(e => e[column].includes('pending')), ...devices];
			}
			else */
			// if (column === 'ignition') {
			// 	newDevices = [...initialData.filter(e => e.online && (value ? (e.ignition && e.ignition.state === value) : ((e.ignition && e.ignition.state === value) || e.ignition === null))), ...devices];
			// }
			// else if (column === 'sd_status') {
			// 	newDevices = [...devices];
			// 	value === 'Needs Reformat' ?
			// 		newDevices.unshift(...initialData.filter(e => e.sd_status.recording_status === 'Needs Reformat')) :
			// 		newDevices.unshift(...initialData.filter(e => e.sd_status.recording_status !== 'Needs Reformat'))
			// }
			if (column === 'latest_hdderror') {
				newDevices = [...devices];
				value === 'Needs Reformat' ?
					newDevices.unshift(...initialData.filter(e => !_.isEmpty(e.latest_hdderror) && e.latest_hdderror.count > 0)) :
					newDevices.unshift(...initialData.filter(e => _.isEmpty(e.latest_hdderror) || e.latest_hdderror.count === 0))
			}
			else if (column === 'group_name') {
				newDevices = [...initialData.filter(e => value === 'No group' ? (!e.group_name || !e.group_name.length) : e.group_name === value), ...devices];
			}
			else {
				newDevices = [...initialData.filter(e => e[column] === value), ...devices];
			}
			e.target.checked = false;
		}

		sendGA({
			category: userRole,
			action: 'Filter Column',
			label: column + ': ' + value
		})
		setDevices(newDevices)
	}

	const filterConfigColumn = (e, value) => {
		let newDevices = [];
		if (!e.target.checked) {
			if (value !== 'Manual' && value !== 'Pending') {
				newDevices = devices.filter(e => e['uploaded_company_profile'] !== value);
			}
			else if (value === 'Pending') {
				newDevices = devices.filter(e => e['config_checksum'] !== 1)
			}
			else {
				newDevices = devices.filter(e => (e['config_checksum'] === 1 || (e['uploaded_company_profile'] !== 'Manual' && e['uploaded_company_profile'])))
			}
		}
		else {
			if (value !== 'Manual' && value !== 'Pending') {
				newDevices = [...initialData.filter(e => e['uploaded_company_profile'] === value), ...devices];
			}
			else if (value === 'Pending') {
				newDevices = [...initialData.filter(e => e['config_checksum'] === 1), ...devices];
			}
			else {
				newDevices = [...initialData.filter(e => (e['config_checksum'] !== 1 && (e['uploaded_company_profile'] === 'Manual' || !e['uploaded_company_profile']))), ...devices];
			}
		}
		setDevices(newDevices)
	}

	// const getLastFirmwarePackage = () => {
	// 	let lastPackageVersion = ''
	// 	if (lastFirmwarePackages && lastFirmwarePackages.length) {
	// 		lastFirmwarePackages.forEach((item) => {
	// 			if (item.version.substring(0, 3) === 'dv4') {
	// 				lastPackageVersion = item.version;
	// 			}
	// 		})
	// 	}

	// 	return lastPackageVersion
	// }

	// const getDeviceDataLimit = (deviceDataLimit) => {
	// 	let dataLimit = 1;
	// 	switch (deviceDataLimit) {
	// 		case 3:
	// 			dataLimit = 1;
	// 			break;
	// 		case 2:
	// 			dataLimit = 0.5;
	// 			break;
	// 		default:
	// 			dataLimit = companyData.data_limit === 2 ? 0.5 : 1;
	// 			break;
	// 	}
	// 	return dataLimit
	// }

	const setLastViewedAssetsList = () => {
		const { company, assets, updateLastViewedAssetsList } = props;
		if (company.company_id) {
			const { lastViewedVehiclesPage } = assets;
			const obj = lastViewedVehiclesPage.find(({ companyId }) => companyId === company.company_id)
			if (obj) {
				obj.ts = moment.utc().format('YYYYMMDDHHmmss')
			} else {
				lastViewedVehiclesPage.push({ companyId: company.company_id || '', ts: moment.utc().format('YYYYMMDDHHmmss') })
			}
			updateLastViewedAssetsList([...lastViewedVehiclesPage])
		}
	}

	const setDevicesSort = (sortName, sortOrder) => {
		setSortDevicesName(sortName);
		setSortDevicesOrder(sortOrder);
	}

	const canAddVehicles = ['system_admin', 'customer_service', 'provisioning'].includes(userRole);
	const { channelStatus, group, configProfile, onlineStatus, totalDataUsage, streamDataUsage, liveStream, recordingStatus, /* firmwareStatus, */ firstConnected, id, auditLog, vinNumber, plateNumber, imei, no_sd_card } = columns;
	const formatSDUser = ['fleet_manager', 'fleet_maintainer', 'group_manager', 'customer_service', 'partner', 'system_admin'].indexOf(userRole) > -1;

	const isSingleUsers = () => {
		const singleUsersList = ['fleet_maintainer', 'fleet_manager', 'group_manager', 'user', 'installer', 'partner_view', 'sales_demo', 'storage_manager', 'storage_user', 'custom_user', 'custom_partner']

		return singleUsersList.indexOf(userRole) !== -1
	}

	// const cameraErrors = devices.reduce((errors, vehicle) => {
	// 	return vehicle.channel_status.find(channel => channel.fail_ts > (channel.success_ts || '1970-01-01 00:00:00') && vehicle.channel_available.includes(channel.channel)) ? errors + 1 : errors;
	// }, 0)
	const cameraHealthWarn = initialData.reduce((errors, vehicle) => {
		return vehicle.camera_health.warn ? errors + 1 : errors
	}, 0)
	const cameraHealthCrit = initialData.reduce((errors, vehicle) => {
		return vehicle.camera_health.crit ? errors + 1 : errors
	}, 0)
	const cameraHealthWarnSD20 = initialData.reduce((errors, vehicle) => {
		return vehicle.camera_health.warn_sd20 ? errors + 1 : errors
	}, 0)
	// FWSD-4382
	// const recordingErrors = !isInternalUser(userRole) ? 0 : devices.reduce((errors, vehicle) => {
	// 	// return vehicle.sd_status.recording_status === 'Needs Reformat' ? errors + 1 : errors
	// 	// return (!_.isEmpty(vehicle.latest_hdderror) && vehicle.latest_hdderror.count > 0) ? errors + 1 : errors
	// 	return errors + vehicle.recordingError
	// }, 0)
	const cameraErrors = initialData.reduce((errors, vehicle) => {
		return errors + vehicle.cameraError
	}, 0)
	// const dataUsageErrors = initialData.reduce((errors, vehicle) => {
	// 	return ((vehicle.data_usage / 1024 / 1024 / 1024) > (getDeviceDataLimit(vehicle.data_limit, companyData))) ? errors + 1 : errors
	// }, 0)
	// const liveStreamErrors = devices.reduce((errors, vehicle) => {
	// 	return errors + vehicle.liveStreamError
	// }, 0)
	// const sdCardRemoval = devices.reduce((errors, vehicle) => {
	// 	return errors + vehicle.no_sd_card
	// }, 0)
	// const outOfDateDevices = devices.reduce((deviceIdArray, {serial_number, device_type, firmware_status, device_id}) => {
	// 	if (firmware_status === 'firmware out of date') {
	// 		deviceIdArray.push({ 'serial_number': serial_number, 'device_id': device_id, 'device_type': device_type })
	// 	}
	// 	return deviceIdArray
	// }, [])

	// const fleetStatusesCalculation = (initDevicesArg) => {
	// 	const initDevices = initDevicesArg || state.initDevices
	// 	const initStatuses = { driving: 0,  sleep: 0, 'pending sleep': 0, offline: 0, parked: 0, awake: 0, 'pending awake': 0, disabled: 0 }

	// 	const newFleetStatuses = initDevices.reduce((result, device) => {
	// 		result[device.status] =  (result[device.status] || 0 ) + 1
	// 		return result
	// 	}, {})

	// 	setFleetStatuses({ ...initStatuses, ...newFleetStatuses})
	// }

	const renderFleetActivityStatus = () => {
		const res = []
		const initStatuses = getAvailableFleetStatuses({ driving: 0,  sleep: 0, offline: 0, parked: 0, awake: 0, disabled: 0, /*'pending awake': 0, 'pending sleep': 0,*/ }, 'glance')
		
		const fleetStatuses = initialData.reduce((result, device) => {
			result[device.status] =  (result[device.status] || 0 ) + 1
			return result
		}, { ...initStatuses })
		
		const fleetStatusesKeys = Object.keys(fleetStatuses)
		fleetStatusesKeys.forEach((status_name) => {
			res.push(
				<div key={status_name} className="status-row">
					<div className={`icon ${status_name.replace(' ', '_')}`} />
					<InfoPop
						title={`Activity Status - ${status_name.charAt(0).toUpperCase() + status_name.slice(1)}`} 
						body={deviceStatusPopups[status_name]}
						// placement="top"
					>
						<span className="title">{getDeviceStatusName(status_name)}</span>
					</InfoPop>
					{fleetStatuses[status_name]}
				</div>
			)
		})

		return res
	}

	// FWSD-6482, If a device cannot be reached to complete the request (Offline/Disabled), display a modal informing the user the request cannot be completed that this time. 
	const checkingDevicesOfflineDisabled = () => {
		const devices = initialData.filter(({device_id, status}) => selectedDevices.includes(device_id) && ['disabled','offline'].includes(status))

		if (devices.length) {
			setDevicesOfflineDisabled(devices.map(({vehicle_name, device_id}) => vehicle_name || device_id ))
		}
	}

	// const monthlyDataAvailable = initialData.reduce((a, b) => a + ((getDeviceDataLimit(b.data_limit, companyData)) || 1), 0)
	const monthlyDataAvailable = (companyData?.data_usage?.available_data_pool || 0) / 1024
	// const monthlyDataUsed = initialData.reduce((a, b) => a + (b['data_usage'] / 1024 / 1024 / 1024 || 0) + (b['data_usage_live_stream'] / 1024 / 1024 / 1024 || 0), 0)
	const monthlyDataUsed = (companyData?.data_usage?.total_data_usage || 0) / 1024
	const monthlyDataUsedPercentage = monthlyDataUsed * 100 / monthlyDataAvailable
	const monthlyDataUsedDegrees = monthlyDataUsedPercentage > 100 ? 269 : monthlyDataUsedPercentage * 360 / 100 - 90
	//* Count devices use equal to or more than 30% of the total data pool 
	const countDataUsage30Perc = initialData.reduce((errors, vehicle) => {
		return ((vehicle.data_usage / 1024 / 1024 / 1024 + vehicle.data_usage_live_stream / 1024 / 1024 / 1024) >= monthlyDataAvailable * 0.3) ? errors + 1 : errors
	}, 0)
	const isDataPoolExceeded = monthlyDataUsed > monthlyDataAvailable

	return (
		<div className="manage-vehicles">
			<div className="page-subheader manage-vehicles-subheader">
				<div className="subheader-section search">
					<SearchBar term={searchText} onSearch={dataSearch} placeholder="Search Vehicles..." />
				</div>
				<div className="subheader-section button-group">
					<CustomButton
						onClick={() => handleActionButton('showUpdateProfile')}
						disabled={!selectedDevices.length}
						variant='toggle-group'
					>
						Update Profile
					</CustomButton>
					{/* {userRole !== 'custom_user' &&
						<CustomButton
							onClick={() => handleUpdateFirmwareButton(outOfDateDevicesId)}
							disabled={!outOfDateDevicesId.length && getLastFirmwarePackage()}
							variant='toggle-group'
						>
							Update Firmware
						</CustomButton>
					} */}
					{formatSDUser &&
						<CustomButton
							onClick={() => handleActionButton('showFormatStorage')}
							disabled={!selectedDevices.length}
							variant='toggle-group'
							style={{ marginLeft: '2px' }}
						>
							Format SD
						</CustomButton>
					}
				</div>

				<Dropdown
					id="vehicle-select-dropdown"
					className="column-select-dropdown"
					role="menuitem"
					drop
					align="start"
				>
					{/* <Dropdown.Toggle as={CustomButtonToggle} prefix="vehicle-select-dropdown" wrapperClassName="text-right" noCaret>
						{columnSelectIcon}
					</Dropdown.Toggle> */}
					{/* <Dropdown.Toggle prefix="vehicle-select-dropdown" wrapperClassName="text-right" noCaret> */}
					
					{/* <Dropdown.Toggle  prefix="vehicle-select-dropdown"  noCaret > */}
					<Dropdown.Toggle  bsPrefix="dropdown-toggle custom-button rosco-primary inverted small">
						Columns
					</Dropdown.Toggle>
					<Dropdown.Menu className="vehicle-select-menu">
						<div className="vehicle-checkbox-group">
							<h5 className="category-title">Columns</h5>
							<div style={{ display: "flex" }}>
								<div className="col-sm-6">
									<div>
										<input
											type="checkbox"
											className="vehicle-checkbox"
											checked={channelStatus}
											name="channelStatus"
											onChange={toggleCheckbox}
										/>
										Channel Status
									</div>
									<div>
										<input
											type="checkbox"
											className="vehicle-checkbox"
											checked={group}
											name="group"
											onChange={toggleCheckbox}
										/>
										Group
									</div>
									<div>
										<input
											type="checkbox"
											className="vehicle-checkbox"
											checked={configProfile}
											name="configProfile"
											onChange={toggleCheckbox}
										/>
										Settings Profile
									</div>
									<div>
										<input
											type="checkbox"
											className="vehicle-checkbox"
											checked={onlineStatus}
											name="onlineStatus"
											onChange={toggleCheckbox}
										/>
										Activity Status
									</div>
									<div>
										<input
											type="checkbox"
											className="vehicle-checkbox"
											checked={id}
											name="id"
											onChange={toggleCheckbox}
										/>
										ID
									</div>
									<div>
										<input
											type="checkbox"
											className="vehicle-checkbox"
											checked={totalDataUsage}
											name="totalDataUsage"
											onChange={toggleCheckbox}
										/>
										Data Usage
									</div>
									<div>
										<input
											type="checkbox"
											className="vehicle-checkbox"
											checked={streamDataUsage}
											name="streamDataUsage"
											onChange={toggleCheckbox}
										/>
										Stream Data Usage
									</div>
									{/* <div>
										<input
											type="checkbox"
											className="vehicle-checkbox"
											checked={liveStream}
											name="liveStream"
											onChange={toggleCheckbox}
										/>
										Live Stream
									</div> */}
								</div>
								<div className="col-sm-6">
									{/* {!isInternalUser(userRole)
										? (
											<OverlayTrigger
												placement="bottom"
												delayHide={100}
												overlay={<Tooltip id="column-improvement-tooltip" className="tooltip custom-tooltips">Under Improvement</Tooltip>}
											>
												<div>
													<i className="fas fa-ban" />
													Recording Status
												</div>
											</OverlayTrigger>
										) : (
											<div>
												<input
													type="checkbox"
													className="vehicle-checkbox"
													checked={recordingStatus}
													name="recordingStatus"
													onChange={toggleCheckbox}
												/>
												Recording Status
											</div>
										)
									} */}
									<div>
										<input
											type="checkbox"
											className="vehicle-checkbox"
											checked={firstConnected}
											name="firstConnected"
											onChange={toggleCheckbox}
										/>
										First Connected
									</div>
									<div>
										<input
											type="checkbox"
											className="vehicle-checkbox"
											checked={vinNumber}
											name="vinNumber"
											onChange={toggleCheckbox}
										/>
										VIN
									</div>
									<div>
										<input
											type="checkbox"
											className="vehicle-checkbox"
											checked={plateNumber}
											name="plateNumber"
											onChange={toggleCheckbox}
										/>
										Plate Number
									</div>
									<div>
										<input
											type="checkbox"
											className="vehicle-checkbox"
											checked={imei}
											name="imei"
											onChange={toggleCheckbox}
										/>
										IMEI
									</div>
									{(userRole === 'system_admin' || userRole === 'customer_service') && (
										<div>
											<input
												type="checkbox"
												className="vehicle-checkbox"
												checked={auditLog}
												name="auditLog"
												onChange={toggleCheckbox}
											/>
											Audit Log
										</div>
									)}
									<div>
										<input
											type="checkbox"
											className="vehicle-checkbox"
											checked={no_sd_card}
											name="no_sd_card"
											onChange={toggleCheckbox}
										/>
										SD Card Removal
									</div>
								</div>
							</div>
						</div>
					</Dropdown.Menu>
				</Dropdown>
			</div>

			<main className="main-content-block">
				{/* FWSD-6749 */}
				<div className="at-a-glance">
					<h3 className="category-title">At A Glance</h3>

					<div className="cards-wrapper">
						{/* Fleet Activity Status */}
						<div className="card">
							<div className="header">Fleet Activity Status {/*<LabelTag text="Beta" tag_type="beta" />*/}</div>
							<div className="body">
								<div className='fleet-activity-status'>
									{ renderFleetActivityStatus() }			
								</div>
								<div className="total-cameras">{initialData.length}</div>
								<div className="text-center">Total Cameras</div>
							</div>
						</div>
						{/* Fleet Data Usage */}
						{isInternalUser(userRole) &&
						<div className={`card${isDataPoolExceeded ? ' card-error' : ''}`}>
							<div className="header">Fleet Data Usage</div>
							<div className="body">
								<div className="ellipse-graph">
									<div className="radius" style={{ transform: `rotate(${monthlyDataUsedDegrees}deg)` }} />
									<div className="data-usage">
										<div>
										<div>{`${monthlyDataUsedPercentage.toFixed(1)} %`}</div>
										<div>{`${monthlyDataUsed.toFixed(1)} GB`}</div>
										<div style={{ fontSize: '11px'}}>USED</div>
										</div>
									</div>
								</div>
								<div className="data-pool-wrapper">
									<div className="total-data-pool">
										<div className="value">{`${monthlyDataAvailable} GB`}</div>
										<div className="text-center">Total Data Pool</div>
									</div>
									{isDataPoolExceeded  
										? (
										<CustomOverlayTrigger
											{...{type: 'crit', title: 'Data Pool Exceeded', body: `The fleet data pool of <b>${monthlyDataAvailable} GB</b> has been exceeded for this billing cycle. Live streaming has been paused and overage charges will now be applied for any additional data used until end of current	billing cycle.`}}
											placement="top"
											trigger="click"
											closeBtn={true}
											// prefix={type}
											prefix='crit'
											bodyPrefix='text-center'
										>
											<div>
												<CustomButton variant="link">
													<RiAlertFill className="alert-error" />
												</CustomButton>
											</div>
										</CustomOverlayTrigger>
										)
										: (countDataUsage30Perc > 0 && 
											<CustomOverlayTrigger
												{...{type: 'warn', title: 'Data Usage Warning', body: `High data usge has been detected for <b>${countDataUsage30Perc}</b> vehicle(s) in your fleet. Higher than normal data usage from a vehicle can deplete the data pool and lead to overage charges for this billing cycle.`}}
												placement="top"
												trigger="click"
												closeBtn={true}
												prefix='warn'
												bodyPrefix='text-center'
											>
												<div>
													<CustomButton variant="link">
														<RiAlertFill />
													</CustomButton>
												</div>
											</CustomOverlayTrigger>
										)
									}
								</div>
							</div>
						</div>
						}
						{/* Camera Health */}
						{isInternalUserWithPartner(userRole) &&
							<div className="card">
								<div className="header">Camera Health</div>
								<div className="body camera-health">
									{((isInternalUser(userRole) && cameraHealthWarn > 0) || (cameraHealthWarnSD20 > 0 )) &&
										<OverlayTrigger
											placement="bottom"
											delayHide={100}
											overlay={
												<Tooltip id="tooltip" className="tooltip custom-tooltips">
													{`${isInternalUser(userRole) ? cameraHealthWarn : cameraHealthWarnSD20} camera${(isInternalUser(userRole) && cameraHealthWarn >1 ||  cameraHealthWarnSD20 > 1) ? 's' : ''} with warning health`}
												</Tooltip>
											}
										>
											<div className="camera-health-row">
												<CustomButton
													variant="primary"
													size="hs"
													prefix="warn"
													onClick={goToCameraHealth}
												>
													WARN
												</CustomButton>
												<span className="value">{isInternalUser(userRole) ? cameraHealthWarn : cameraHealthWarnSD20}</span>
											</div>
										</OverlayTrigger>
									}
									{isInternalUser(userRole) && cameraHealthCrit > 0 &&
										<OverlayTrigger
											placement="bottom"
											delayHide={100}
											overlay={
												<Tooltip id="tooltip" className="tooltip custom-tooltips">
													{`${cameraHealthCrit} camera${cameraHealthCrit > 1 ? 's' : ''} with critical health`}
												</Tooltip>
											}
										>
											<div className="camera-health-row">
												<CustomButton
													variant="primary"
													size="hs"
													prefix="crit"
													onClick={goToCameraHealth}
												>
													CRITICAL
												</CustomButton>
												<span className="value">{cameraHealthCrit}</span>
											</div>
										</OverlayTrigger>
									}
									{cameraErrors > 0 &&
										<OverlayTrigger
											// show={true}
											placement="bottom"
											delayHide={100}
											overlay={
												<Tooltip id="tooltip" className="tooltip custom-tooltips">
													{`${cameraErrors} ${cameraErrors > 1 ? 'cameras have' : 'camera has'} status issues`}
												</Tooltip>}
										>
											<div className="camera-health-row">
												<CustomButton
													variant="primary"
													size="hs"
													prefix="crit"
													onClick={() => selectDevicesByError('channel_status')}
												>
													CHANNEL
												</CustomButton>
												<span className="value">{cameraErrors}</span>
											</div>
										</OverlayTrigger>
									}
									{(
										(isInternalUser(userRole) && cameraHealthWarn === 0 && cameraHealthCrit === 0 && cameraErrors === 0) 
										|| (!isInternalUser(userRole) && cameraHealthWarnSD20 === 0 && cameraErrors === 0)
									) && <div><img src="/images/health-no-issue.png" /></div>
									}
								</div>
							</div>
						}
					</div>
				</div>

				<h3 className="category-title">Vehicles</h3>

				{!isSingleUsers()
					&& (
						<AddAssetContainer changedDevicesList={getCompanyDevices} devices={devices} />
					)}

				<div className="vehicle-table-header">
					<CustomButton variant="link" onClick={() => setSelectedDevices([])}>Clear All Selected {selectedDevices ? ('(' + selectedDevices.length + ')') : ''}</CustomButton>
				</div>
				<ManageAssetsContainer
					devices={devices}
					initialData={initialData}
					companyData={companyData}
					columns={columns}
					selectedDevices={selectedDevices}
					selectDevice={selectDevice}
					selectAllDevices={selectAllDevices}
					filterColumn={filterColumn}
					filterConfigColumn={filterConfigColumn}
					toggleCheckbox={toggleCheckbox}
					highlightData={highlightData}
					selectProfile={props.selectProfile}
					sortDevicesName={sortDevicesName}
					sortDevicesOrder={sortDevicesOrder}
					setDevicesSort={setDevicesSort}
					getCompanyDevices={getCompanyDevices}
					monthlyDataAvailable={monthlyDataAvailable}
				/>

				{showUpdateProfile && (
					<ModalChangeConfBatch
						userRole={userRole}
						companyData={companyData}
						selectedDevices={selectedDevices}
						clearSelection={clearSelection}
						onHide={() => setShowUpdateProfile(false)}
						getCompanyDevices={getCompanyDevices}
					/>
				)}

				{/* {showUpdateFirmware && (
					<ModalUpgradeFirmwareBatch
						userRole={userRole}
						outOfDateDevicesId={outOfDateDevicesId}
						lastFirmwarePackages={lastFirmwarePackages}
						clearSelection={clearSelection}
						onHide={() => setShowUpdateFirmware(false)}
						getCompanyDevices={getCompanyDevices}
					/>
				)} */}

				{showFormatStorage && (
					<ModalFormatSDBatch
						userRole={userRole}
						selectedDevices={selectedDevices}
						clearSelection={clearSelection}
						onHide={() => setShowFormatStorage(false)}
						getCompanyDevices={getCompanyDevices}
					/>
				)}

				{devicesOfflineDisabled.length > 0 &&
					<ModalDeviceOfflineDisabled
						devicesOfflineDisabled={devicesOfflineDisabled}
						onHide={() => setDevicesOfflineDisabled('')}
					/>
				}
			</main>
		</div>
	);
}

ManageAssets.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired,
	assets: PropTypes.objectOf(PropTypes.any).isRequired,
	updateLastViewedAssetsList: PropTypes.func.isRequired,
	goToCameraHealth: PropTypes.func.isRequired,
	updateHasUnacknowledgedAlerts: PropTypes.func.isRequired,
}

export default connect(
	({ user, company, assets }) => ({
		user: user.user,
		company: company.company,
		assets
	}),
	dispatch => ({
		updateLastViewedAssetsList: (state) => {
			dispatch({ type: 'SET_LAST_VIEWED_ASSETS', payload: state })
		},
		updateHasUnacknowledgedAlerts: (state) => {
			dispatch({ type: 'SET_HAS_UNACKNOWLEDGED_ALERTS', payload: state })
		},
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show })
		}
	})
)(ManageAssets);
