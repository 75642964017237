import React from 'react'
import PropTypes from 'prop-types'
import ToggleCellNode from './ManageAssetsUserToggleCellNode'

const ToggleRowNode = (props) => {
	const { perm_category_name, perm_category_id, a_view, a_edit, a_add, a_delete, onChange, disabled } = props

	//FWSD-4552
	const isEmpty = [14, 17].includes(perm_category_id) // We do not display edit/add/delete toggles for the Live Stream and Firmware Portal permissions

	const opts = {}
	if (a_edit || a_add || a_delete) {
		opts.disabled = true
		opts.readOnly = true
	}

	return (
		<tr>
			<td className="col-md-4">
				{perm_category_name}
			</td>
			<ToggleCellNode
				name="a_view"
				checked={a_view}
				perm_category_id={perm_category_id}
				onChange={onChange}
				isEmpty={perm_category_id === 18}
				disabled={(a_edit || a_add || a_delete || disabled) ? true : false}
				readOnly={(a_edit || a_add || a_delete) ? true : false}
			/>
			<ToggleCellNode
				name="a_add"
				checked={a_add}
				perm_category_id={perm_category_id}
				onChange={onChange}
				isEmpty={isEmpty}
				disabled={disabled}
			/>
			<ToggleCellNode
				name="a_edit"
				checked={a_edit}
				perm_category_id={perm_category_id}
				onChange={onChange}
				isEmpty={isEmpty}
				disabled={disabled}
			/>
			<ToggleCellNode
				name="a_delete"
				checked={a_delete}
				perm_category_id={perm_category_id}
				onChange={onChange}
				isEmpty={isEmpty || perm_category_id === 18}
				disabled={disabled}
			/>
		</tr>
	)
}

ToggleRowNode.propTypes = {
	perm_category_name: PropTypes.string.isRequired,
	perm_category_id: PropTypes.number.isRequired,
	a_view: PropTypes.bool.isRequired,
	a_edit: PropTypes.bool.isRequired,
	a_add: PropTypes.bool.isRequired,
	a_delete: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
}

export default ToggleRowNode;
