import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import moment from 'moment'
import { Button } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { Dropdown } from 'react-bootstrap'
import classnames from 'classnames'
import Autocomplete from 'react-autocomplete'
import _ from 'lodash'
import momentDurationFormatSetup from 'moment-duration-format'

import EventViewSnapshotModal from '../modals/EventViewSnapshotModal'
import EventNoteModal from '../modals/EventNoteModal'
import ModalDelete from '../modals/ModalDelete'
import AdvancedPlayer from '../../AdvancedPlayer'
import EventShareModal from '../modals/EventShareModal'
import { renderCustomHeader, tableAdjustableColExportOptions } from '../../helpers/table'
import momenttz from '../../helpers/momenttz'
import { eventStatusFormatter, eventTypeDetection } from '../../helpers/helperEvents'
import { generalIcons, eventIcons } from '../../helpers/iconHelpers'
import { sendGA } from '../../helpers/helperUsers'

import { getDevicesList } from '../../../action/DeviceActions'
import { getEventsOfCompanyAndRange, getEventVideo, deleteEvent, restoreFromArchiveWCSEvent, requestFloodDownload } from '../../../action/AlertActions'
import { apiCallGet, apiCall } from '../../../action/RouterActions'

import '../../dump/menuComponents/cloudStorage.sass'
import EventClipsGrid from './EventClipsGrid'
import { isMobileOnly } from 'react-device-detect'
import CustomButton from '../../CustomButton'
import { formatTimestamp } from '../../helpers/tablesFuncHelpers'

momentDurationFormatSetup(moment);

const COLUMNS_WIDTH = {
	0: {
		'Date / Time': '12%', Type: '12%', Vehicle: '15%', Groups: '14%', Note: '7%', Reviewed: '12%', ID: '8%', Duration: '8%', RequestedAt: '12%',
	},
	1: {
		'Date / Time': '12%', Type: '10%', Vehicle: '12%', Groups: '10%', Note: '6%', Reviewed: '12%', ID: '7%', Duration: '7%', RequestedAt: '12%', Driver: '10%',
	},
}

const EventFlagged = (props) => {
	const { company, user, toggleLoader, setEventArchiveRestoring } = props
	const userRole = user.roles[0]
	const userPermissions = user.permissions

	let interval = null

	const [initialData, setInitialData] = useState([]);
	const [events, setEvents] = useState([]);
	const [drivers, setDrivers] = useState([]);
	const [devices, setDevices] = useState([]);
	const [eventsView, setEventsView] = useState(localStorage.getItem('eventsView') || 'Table');
	const [columns, setColumns] = useState(JSON.parse(localStorage.getItem('eventFlaggedColumns')) || {
		vehicle: true,
		groups: true,
		reviewed: false,
		id: false,
		duration: true,
		requested_at: false,
		driver: false,
	});
	const [isFlagged, setIsFlagged] = useState(true);
	const [searchCategory, setSearchCategory] = useState('vehicle');
	const [autoValue, setAutoValue] = useState('');
	const [deleteEventId, setDeleteEventId] = useState(null);
	const [totalSize, setTotalSize] = useState();
	const [page, setPage] = useState();
	const [sizePerPage, setSizePerPage] = useState();
	const [showEventSnapshot, setShowEventSnapshot] = useState(false);
	const [selectedEvent, setSelectedEvent] = useState();
	const [showEventNote, setShowEventNote] = useState(false);
	const [showShareEvent, setShowShareEvent] = useState(false);
	const [showEventVideoAll, setShowEventVideoAll] = useState(false);
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [availableDates, setAvailableDates] = useState([]);
	const [filters, setFilters] = useState([]);
	const [sortName, setSortName] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [event_date, setEvent_date] = useState('');
	const [location, setLocation] = useState('');
	const [requestedEventIDs, setRequestedEventIDs] = useState('');
	const [showEventAlertSearch, setShowEventAlertSearch] = useState([]);

	// useEffect(() => {
	// 	if (company.name) {
	// 		getEventsFlagged();
	// 	}
	// }, []);

	useEffect(() => {
		if (company.company_id && company.partner) {
			getEventsFlagged(company.company_id);
		}
	}, [company.company_id, company.partner]);

	const getEventsFlagged = (companyId = null) => {
		toggleLoader(true);

		const requestArray = []
		requestArray.push(getDevicesList(companyId || company.company_id))
		requestArray.push(apiCallGet('/events/flagged', { company_id: companyId || company.company_id }))
		if (['system_admin', 'customer_service', 'partner', 'fleet_manager', 'fleet_maintainer', 'partner_view', 'sales_demo'].indexOf(userRole) > -1) {
			requestArray.push(apiCallGet('/company/{company_id}/driver', { company_id: companyId || company.company_id }))
		}

		Promise.all(requestArray)
			.then(res => {
				console.log('!Promise response:', res)

				const devices = _.get(res[0], 'data.response.devices', [])
				let events = _.get(res[1], 'data.response', [])
				events = _.orderBy(events, item => moment(item.timestamp).valueOf(), ['desc']);
				let drivers = []
				if (res[2]) {
					drivers = _.get(res[2], 'data.response.drivers', [])
				}

				setDevices(devices);
				setDrivers(drivers);

				events.map(event => {
					if (event.driver_id) {
						drivers.map(driver => {
							if (event.driver_id === driver.id) return event.driver_name = driver.first_name + ' ' + driver.last_name;
						})
					}
				});

				setEvents(events);
				setInitialData(JSON.parse(JSON.stringify(events)));

				toggleLoader(false);
			})
			.catch((error) => {
				console.log('!PromiseAll ERROR:', error.response, error)
				toggleLoader(false);

				setDevices([]);
				setDrivers([]);
				setEvents([]);
				setInitialData([])

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const onFlagEvent = (row) => {
		const { id } = row;

		toggleLoader(true);
		apiCall('DELETE', '/events/{id}/flagged', { id })
			.then((res, err) => {
				toggleLoader(false);

				setEvents(events.filter(item => item.id !== id));
				if (showEventVideoAll) {
					setShowEventVideoAll(false)
				}
				sendGA({
					category: userRole,
					action: 'Unflag Event',
					label: row.type
				})
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!onFlagEvent delete error:', error.response, error)
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const getFleetEventsByRole = (location) => {
		console.log('getFleetEventsByRole', location)

		switch (userRole) {
			case 'system_admin':
			case 'customer_service':
			case 'sales_demo':
				if (company.name) {
					getFleetDrivers()
					getFleetDevices(
						() => {
							if (devices.length) {
								if (availableDates.length) {
									if (location && location.date_ts) {
										setEvent_date(moment(location.date_ts + '+00').format('YYYY-MM-DD'));
									} else {
										setEvent_date(availableDates[availableDates.length - 1]);
									}

									getFleetEvents();
								} else {
									toggleLoader(false);
									toastr.info('', 'Device does not have events');
								}
							}
						},
						company.name,
						company.partner
					);
				}
				break;
			case 'partner':
			case 'partner_view':
				if (company.name) {
					getFleetDrivers()
					getFleetDevices(
						() => {
							if (devices.length) {
								if (availableDates.length) {
									if (location && location.date_ts) {
										setEvent_date(moment(location.date_ts + '+00').format('YYYY-MM-DD'));
									} else {
										setEvent_date(availableDates[availableDates.length - 1]);
									}

									getFleetEvents();
								} else {
									toggleLoader(false);
									toastr.info('', 'Device does not have events');
								}
							}
						},
						company.name
					)
				}
				break;
			default:
				getFleetDrivers()
				getFleetDevices(
					() => {
						if (devices.length) {
							if (availableDates.length) {
								if (location && location.date_ts) {
									setEvent_date(moment(location.date_ts + '+00').format('YYYY-MM-DD'));
								} else {
									setEvent_date(availableDates[availableDates.length - 1]);
								}

								getFleetEvents()
							} else {
								toggleLoader(false);
								toastr.info('', 'Device does not have events');
							}
						}
					},
					company.name
				);
				break;
		}
	}

	const getFleetDevices = (callback) => {
		toggleLoader(true);

		getDevicesList(company.company_id)
			.then(res => {
				setDevices(res.data.response.devices);
				setEvents([]);
				setEvent_date(moment());

				if (!res.data.response.devices.length) {
					toggleLoader(false);
				}

				if (callback && typeof callback === "function") {
					callback();
				}
			})
			.catch((error) => {
				toggleLoader(false);
				console.log(error);

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const getFleetDrivers = (companyId) => {
		if (['system_admin', 'customer_service', 'partner', 'fleet_manager', 'fleet_maintainer', 'partner_view', 'sales_demo', 'group_manager', 'custom_user', 'user'].indexOf(userRole) === -1) {
			return
		}

		const company_id = companyId || company.company_id
		apiCallGet('/company/{company_id}/driver', { company_id })
			.then((res, err) => {
				console.log('!getFleetDrivers res:', res, err)

				const { drivers } = res.data.response
				setDrivers(drivers)

			})
			.catch((error) => {
				console.log('!getFleetDrivers error:', error.response, error)

				setDrivers([])

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const getFleetEvents = (pageFnc = page, sizePerPageFnc = sizePerPage, showLoader = true, event_dateFnc = null, company_name = null, callback = null, eventIds = null) => {
		const eventDate = event_dateFnc || event_date

		if (showLoader) {
			toggleLoader(true);
		}

		const data = {
			user_role: userRole,
			company_name: company_name || company.name || user.company_name,
			partner_name: company.partner,
			start_ts: moment(moment(eventDate).format('YYYY-MM-DD 00:00:00')).utc().format('YYYYMMDDHHmmss'),
			end_ts: moment(moment(eventDate).format('YYYY-MM-DD 23:59:59')).utc().format('YYYYMMDDHHmmss'),
			page_number: pageFnc,
			page_size: sizePerPageFnc,
			filters,
			search_type: searchCategory,
			search: autoValue,
			order: `&order=${sortName}&direction=${sortOrder}`,
			search_by_id: (location && location.search_by_id) ? location.search_by_id : '',
			event_ids: eventIds
		}

		if (eventIds) {
			delete data.page_number;
			delete data.page_size;
		}

		getEventsOfCompanyAndRange(data)
			.then((res, err) => {
				// console.log('!getFleetEvents getEventsOfCompanyAndRange res', res)
				const { response } = res.data

				if (data.search_type === 'event' && data.search && response.events.length) {
					const newEventDate = moment(response.events[0].timestamp).format('YYYY-MM-DD')
					setEvent_date(newEventDate)
					setEvents(response.events)
					setTotalSize(response.events.length)
					setPage(1)
				} else if (eventIds && eventIds.length) {
					if (response.events.length) {
						const _events = events.map((event) => {
							let index = eventIds.indexOf(event.id);
							if (index > -1) {
								event = _.find(response.events, ['id', event.id]);
								if (event && ((event.uploading_status == 'VIDEO_UPLOADED' && event.snapshot_status == 'SNAPSHOT_UPLOADED') || event.uploading_status == 'VIDEO_NON_EXIST')) {
									let requestedEventIDsUpdate = requestedEventIDs;
									requestedEventIDsUpdate.splice(index, 1);
									setRequestedEventIDs(requestedEventIDsUpdate);
								}
							}

							return event
						})

						setEvents(_events)
					}
				} else {
					const location1 = location;
					setEvents(response.events);
					setTotalSize(response.total_number);
					setPage((response.page_number) ? response.page_number * 1 : pageFnc);
					setLocation((location1 && location1.search_by_id) ? { ...location1, search_by_id: null } : location1);
					setSizePerPage(sizePerPageFnc);
				}

				if (!response.events.length) {
					toastr.info('', 'Device does not have events');
				}

				if (callback && typeof callback === 'function') {
					callback();
				}

				if (showLoader) {
					toggleLoader(false);
				}
			})
			.catch((error) => {
				toggleLoader(false);

				console.log('!EventFlagged getFleetEvents getEventsOfCompanyAndRange error: ', error.response, error);

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const handleSearchCategoryChange = (e) => {
		const category = e.target.value;
		setSearchCategory(category)
		// debugger
		if (autoValue) {
			searchVehicle(autoValue, category)
		}
		if (category === "driver") {
			getFleetDrivers(company.company_id)
		}
	}

	const searchVehicle = (argValue = null, argCategory = null) => {
		// debugger
		const value = argValue !== null ? argValue.toLowerCase() : autoValue.toLowerCase()
		const category = argCategory || searchCategory
		let filter = initialData

		if (value) {
			switch (category.toLowerCase()) {
				case 'vehicle':
					filter = initialData.filter(event => (
						(event.device_id && event.device_id.toString().toLowerCase().includes(value)) ||
						(event.vehicle_name && event.vehicle_name.toLowerCase().includes(value))
					))
					break;
				case 'driver':
					filter = initialData.filter(event => (
						(event.driver_name && event.driver_name.toLowerCase().includes(value)) ||
						(event.geotab_driver && event.geotab_driver.toLowerCase().includes(value))
					))
					break;
				case 'event':
					filter = initialData.filter(event => (
						event.id.toString().toLowerCase().includes(value)
					));
					break;
				default:
					break;
			}
		}

		setEvents(filter);
	}

	const downloadNVR = (eventId) => {
		getEventVideo({
			user_role: userRole,
			event_id: eventId,
			type: 'nvr',
			company_name: company.name,
			partner_name: company.partner
		})
			.then((res, err) => {
				console.log('!downloadNVR getEventVideo res:', res, err);
				sendGA({
					category: userRole,
					action: 'Download Event',
					label: 'Download Event NVR'
				})
				window.location.href = res.data.response.url;
			})
			.catch((error) => {
				console.log('!downloadNVR getEventVideo error:', error.response, error);

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const downloadFlood = (event) => {
		toggleLoader(true);

		requestFloodDownload({ event_id: event.id })
			.then((res, err) => {
				toggleLoader(false);

				const { response } = res.data
				if (response.error && response.error === 'device is offline, snapshot is pending') {
					toastr.warning('', 'Flood Video Requested but Device is offline now')
				} else {
					toastr.success('', 'Flood Video Requested')
					sendGA({
						category: userRole,
						action: 'Download Event',
						label: 'Download Event Flood'
					})
				}

				onSaveVideoRequest({
					event_ts: event.timestamp,
					event_device_id: event.device_id,
					event_id: event.id,
				}, true)
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!requestFloodDownload error:', error.response, error)

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const onCloseEventVideoAll = () => {
		setShowEventVideoAll(false);
	}

	const openEventVideoAll = (row) => {
		if (!(window.innerWidth < 768 || isMobileOnly)) {
			setSelectedEvent(row);
			setShowEventVideoAll(true);
			sendGA({
				category: userRole,
				action: 'Open Event Video',
				label: row.type
			})
			window.scrollTo(0, 0)
		}
		else {
			// Stores required params for the separate browser window to the local storage.
			const winOpen = {
				eventId: row.id,
				type: row.type,
				custom_event_type: row.custom_event_type,
				userRole: user.roles[0],
				companyName: company.name,
				partnerName: company.partner,
				driverRecognition: company.driver_recognition,
				user: user,
			}
			localStorage.setItem('winOpen', JSON.stringify(winOpen));

			const width = 1200
			const height = 800
			const w = window
			const d = document
			const e = d.documentElement
			const g = d.getElementsByTagName('body')[0]
			const winWidth = w.innerWidth || e.clientWidth || g.clientWidth
			const winHeight = w.innerHeight || e.clientHeight || g.clientHeight
			const winX = (document.all) ? window.screenLeft : window.screenX
			const winY = (document.all) ? window.screenTop : window.screenY
			const left = ((winWidth - width) / 2) + winX
			const top = ((winHeight - height) / 2) + winY
			const windowSpecs = 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=1,width=' + width + ',height=' + height + ',left=' + left + ',top=' + top
			const target = row.id
			const win = window.open('/event/video/' + target, target + '1', windowSpecs);

			return win
		}
	}

	const onCloseEventSnapshot = () => {
		setShowEventSnapshot(false)
	}

	const openEventSnapshot = (row) => {
		setSelectedEvent(row);
		setShowEventSnapshot(true);
		sendGA({
			category: userRole,
			action: 'Open Event Snapshot',
			label: row.type
		})
	}

	const onCloseEventNote = (note) => {
		setShowEventNote(false)
		let label = 'Close Event Note';
		if ((note && note.length) || note === '') {
			label = 'Submit Event Note';
			let selectedEventUpdate = selectedEvent;
			if (selectedEventUpdate) {
				selectedEventUpdate.custom_event_type = note
				setSelectedEvent(selectedEventUpdate)
			}
		}
		sendGA({
			category: userRole,
			action: 'Event Note',
			label: label
		})
	}

	const openEventNote = (row) => {
		setSelectedEvent(row);
		setShowEventNote(true);
		sendGA({
			category: userRole,
			action: 'Event Note',
			label: row.type
		})
	}

	const openModalDelete = (eventId) => {
		setShowModalDelete(true);
		setDeleteEventId(eventId)
	}

	const closeModalDelete = () => {
		setShowModalDelete(false);
	}

	const openShareEvent = (row) => {
		setSelectedEvent(row);
		setShowShareEvent(true);
		sendGA({
			category: userRole,
			action: 'Share Event',
			label: row.type
		})
	}

	const onCloseShareEvent = (success) => {
		setShowShareEvent(false)
		let label = (success && success.length) ? 'Submit Share Event' : 'Close Share Event';
		sendGA({
			category: userRole,
			action: 'Share Event',
			label: label
		})
	}

	const updateDriver = (event_id, driver) => {
		const updatedEvents = [...events];
		for (let event of updatedEvents) {
			if (event.id === event_id) {
				event.driver_name = driver.first_name + ' ' + driver.last_name;
				event.driver_id = driver.id
				setSelectedEvent(event)
			}
		}
		setEvents(updatedEvents)
	}

	const deleteCustomRequest = () => {
		toggleLoader(true);

		setShowModalDelete(false)

		deleteEvent({
			user_role: user.roles[0],
			partner_name: company.partner,
			company_name: company.name || user.company_name,
			event_id: deleteEventId,
		})
			.then((res, err) => {
				toggleLoader(false);
				toastr.success('', `Event "${deleteEventId}" removed`);

				console.log('!deleteCustomRequest deleteEvent res', res);

				getFleetEvents()

				//* Removes the events update (interval) if removed the monitored event
				const requestedEventIDsUpdate = requestedEventIDs
				console.log('requestedEventIDs', requestedEventIDsUpdate)
				if (interval && requestedEventIDsUpdate.length) {
					console.log('interval', requestedEventIDsUpdate.length)
					const index = requestedEventIDsUpdate.indexOf(deleteEventId)
					console.log('index', index)
					if (index > -1) {
						requestedEventIDsUpdate.splice(index, 1)
						requestedEventIDs(requestedEventIDsUpdate)
					}

					console.log('requestedEventIDs1', requestedEventIDsUpdate, requestedEventIDs)
					if (!requestedEventIDs.length) {
						clearInterval(interval)
						interval = null
					}
				}
				// ************************
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!deleteCustomRequest deleteEvent error: ', error.response, error);

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const openEventAlertSearch = () => {
		setShowEventAlertSearch(!showEventAlertSearch);
	}

	const onCloseEventAlertSearch = () => {
		setShowEventAlertSearch(false);
	}

	const actionsFormatter = (cell, row) => {
		let snapshotButton, downloadButton, playButton, deleteButton, shareButton, flaggedButton, archivedButton

		let noteButton = (
			<CustomButton size="icon" variant="tertiary" prefix={classnames("note-button", { 'with-note': row.custom_event_type })} active={row.custom_event_type} onClick={() => openEventNote(row)}>
				Note
			</CustomButton>
		)

		flaggedButton = <CustomButton prefix="imageButton flaggedButton on" title="Unflag Event" onClick={() => onFlagEvent(row, 'Table')} />

		if (row.uploading_status === 'VIDEO_UPLOADED') {
			playButton = <CustomButton prefix="imageButton playButton" title="Play Videos" onClick={() => openEventVideoAll(row, 'Table')} />
			downloadButton = <CustomButton prefix="imageButton downloadButton" title="Download Video" onClick={() => downloadNVR(row.id)} />
			shareButton = <CustomButton prefix="imageButton shareButton" title="Share Event" onClick={() => openShareEvent(row)} />
		} else if (row.snapshot_status !== 'SNAPSHOT_UPLOADED') {
			playButton = <CustomButton prefix="imageButton playButton" title="Play Videos" disabled="disabled" />
			downloadButton = <CustomButton prefix="imageButton downloadButton" title="Download Video" disabled="disabled" />
		}

		if (row.snapshot_status === 'SNAPSHOT_UPLOADED') {
			snapshotButton = <CustomButton prefix="imageButton snapshotButton" title="View Snapshot" onClick={() => openEventSnapshot(row)} />;
		} else {
			snapshotButton = <CustomButton prefix="imageButton snapshotButton" title="View Snapshot" disabled="disabled" />;
		}

		if (row.type.toLowerCase() === 'custom clip'
			&& (row.uploading_status !== 'VIDEO_UPLOADED'
				&& row.snapshot_status !== 'SNAPSHOT_UPLOADED')
			&& userRole !== 'sales_demo'
			&& (
				userRole !== 'user'
				|| (userRole === 'user'
					&& row.created_by === user.id
				)
			)
		) {
			deleteButton = <CustomButton id="deleteButton" prefix="imageButton deleteButton" title="Delete Custom Request" onClick={() => openModalDelete(row.id)}>
				{generalIcons.crossDelete}
			</CustomButton>
		} else if (row.type.toLowerCase() === 'flood') {
			downloadButton = <CustomButton prefix="imageButton downloadButton" title="Download Video" onClick={() => downloadFlood(row)} />
		}

		if (['custom_user', 'custom_partner', 'group_manager'].includes(userRole)) {
			if (!_.find(userPermissions, { 'perm_category_id': 2, 'a_delete': true })) {
				deleteButton = null
			}
			if (!_.find(userPermissions, { 'perm_category_id': 9, 'a_view': true })) {
				downloadButton = null
			}
		} else if (userRole === 'sales_demo') {
			downloadButton = (downloadButton) ? <CustomButton prefix="imageButton downloadButton disabled" title="Download Video" disabled="disabled" /> : ''
			shareButton = (shareButton) ? <CustomButton prefix="imageButton shareButton disabled" title="Share Event" disabled="disabled" /> : ''
			deleteButton = (deleteButton) ? <CustomButton id="deleteButton" prefix="imageButton deleteButton disabled" title="Delete Custom Request" disabled="disabled" /> : ''
		}

		if (row.archive === 'archived') {
			archivedButton = <CustomButton prefix={`imageButton archivedButton`} title="Restore Event" onClick={() => setEventArchiveRestoring({ showModal: true, event_id: row.id, message_type: 'event_restore', callback: getEventsFlagged })} />
		}
		else if (row.archive === 'restoring') {
			archivedButton = <CustomButton prefix={`imageButton archivedButton rosco-tertiary active`} title="Event Restoring" onClick={() => setEventArchiveRestoring({ showModal: true, event_id: row.id, message_type: 'event_restoring' })} />
		}

		return (
			<div className="event-actions">
				{archivedButton}
				{!archivedButton && playButton}
				{!archivedButton && snapshotButton}
				{shareButton}
				{!archivedButton && downloadButton}
				{flaggedButton}
				{deleteButton}
				{noteButton}
			</div>
		)
	}

	const restoreWCSEvent = (row) => {
		toggleLoader(true);
		console.log("restoreWCSEvent", row);
		const data = {
			user_role: user.roles[0],
			company_name: company.name || user.company_name,
			partner_name: company.partner,
			event_id: row.id,
		}

		restoreFromArchiveWCSEvent(data)
			.then((res, err) => {
				console.log('!restoreWCSEvent res', res);
				getFleetEvents();
				toggleLoader(false);

			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!restoreWCSEvent error', error);
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	};

	const actionsFormatterWCS = (cell, row) => {
		let buttons, playButton, downloadButton, deleteButton, shareButton;

		let noteButton = (
			<CustomButton size="icon" variant="tertiary" prefix={classnames("note-button", { 'with-note': row.custom_event_type })} active={row.custom_event_type} onClick={() => openEventNote(row)}>
				Note
			</CustomButton>
		)

		if (row.type.toLowerCase() === 'custom clip' && row.uploading_status === 'EVENT_REPORTED') {
			buttons = (
				<div>
					<CustomButton id="deleteButton" prefix="imageButton deleteButton" title="Delete Custom Request" onClick={() => openModalDelete(row.id)} />
				</div>
			);

			if (['custom_user', 'custom_partner'].includes(userRole) && !_.find(userPermissions, { 'perm_category_id': 2, 'a_delete': true })) {
				buttons = null
			}
		} else {
			let archived_status = row.status;
			switch (archived_status) {
				case 'archived':
					buttons = (
						<CustomButton prefix="imageButton requestButton" title="Request" onClick={() => restoreWCSEvent(row)} />
					);
					break;
				case 'restoring':
					buttons = (
						<div>
							<p>Restoring in progress</p>
						</div>
					);
					break;
				default:
					if (row.uploading_status == 'VIDEO_UPLOADED') {
						playButton = <CustomButton prefix="imageButton playButton" title="Play Video" onClick={() => openEventVideoAll(row)} />
						downloadButton = <CustomButton prefix="imageButton downloadButton" title="Download Video" onClick={() => downloadNVR(row.id)} />
						shareButton = <CustomButton prefix="imageButton shareButton" title="Share Event" onClick={() => openShareEvent(row)} />
					} else {
						playButton = <CustomButton prefix="imageButton playButton" title="Play Video" disabled="disabled" />
						downloadButton = <CustomButton prefix="imageButton downloadButton" title="Download Video" disabled="disabled" />
					}

					if (row.type.toLowerCase() === 'custom clip'
						&& row.uploading_status !== 'VIDEO_UPLOADED'
						&& userRole !== 'sales_demo'
						&& (
							userRole !== 'storage_user'
							|| (userRole === 'storage_user'
								&& row.created_by == props.user.id)
						)
					) {
						deleteButton = <CustomButton id="deleteButton" prefix="imageButton deleteButton" title="Delete Custom Request" onClick={() => openModalDelete(row.id)} />;
					}

					if (['custom_user', 'custom_partner'].includes(userRole)) {
						if (!_.find(userPermissions, { 'perm_category_id': 2, 'a_delete': true })) {
							deleteButton = null
						}
						if (!_.find(userPermissions, { 'perm_category_id': 9, 'a_view': true })) {
							downloadButton = null
						}
					}

					buttons = (
						<div className="event-actions">
							{playButton}
							{downloadButton}
							{shareButton}
							{deleteButton}
							{noteButton}
						</div>
					);
					break;
			}
		}

		return buttons;
	}

	const changeView = (view) => {
		setEventsView(view);
		localStorage.setItem('eventsView', view);
		sendGA({
			category: userRole,
			action: 'Change View Format',
			label: view
		})
	}

	const cardActionsFormatter = (row) => {
		let downloadButton, snapshotButton, deleteButton, shareButton;

		let noteButton = (
			<CustomButton size="icon" variant="tertiary" prefix={classnames("note-button", { 'with-note': row.custom_event_type })} active={row.custom_event_type} onClick={() => openEventNote(row)}>
				Note
			</CustomButton>
		)

		const flaggedButton = <CustomButton prefix={`imageButton flaggedButton on`} id={row.id} title="Unflag Event" onClick={() => onFlagEvent(row, 'Grid')} />

		if (row.uploading_status === 'VIDEO_UPLOADED') {
			downloadButton = <CustomButton prefix="imageButton downloadButton" title="Download Video" onClick={() => downloadNVR(row.id)} />
			shareButton = <CustomButton prefix="imageButton shareButton" title="Share Event" onClick={() => openShareEvent(row)} />
		}
		else if (row.snapshot_status === 'SNAPSHOT_UPLOADED') {
			snapshotButton = <CustomButton prefix="imageButton snapshotButton" title="View Snapshot" onClick={() => openEventSnapshot(row)} />;
		}

		if (row.type.toLowerCase() === 'custom clip'
			&& (row.uploading_status !== 'VIDEO_UPLOADED'
				&& row.snapshot_status !== 'SNAPSHOT_UPLOADED')
			&& userRole !== 'sales_demo'
			&& (
				userRole !== 'user'
				|| (userRole === 'user'
					&& row.created_by === user.id
				)
			)
		) {
			deleteButton = <CustomButton id="deleteButton" prefix="imageButton deleteButton" title="Delete Custom Request" onClick={() => openModalDelete(row.id)}>
				{generalIcons.crossDelete}
			</CustomButton>
		} else if (row.type.toLowerCase() === 'flood') {
			downloadButton = <CustomButton prefix="imageButton downloadButton" title="Download Video" onClick={() => downloadFlood(row)} />
		}

		if (['custom_user', 'custom_partner', 'group_manager'].includes(userRole)) {
			if (!_.find(userPermissions, { 'perm_category_id': 2, 'a_delete': true })) {
				deleteButton = null
			}
			if (!_.find(userPermissions, { 'perm_category_id': 9, 'a_view': true })) {
				downloadButton = null
			}
		} else if (userRole === 'sales_demo') {
			downloadButton = (downloadButton) ? <CustomButton prefix="imageButton downloadButton disabled" title="Download Video" disabled="disabled" /> : ''
			shareButton = (shareButton) ? <CustomButton prefix="imageButton shareButton disabled" title="Share Event" disabled="disabled" /> : ''
			deleteButton = (deleteButton) ? <CustomButton id="deleteButton" prefix="imageButton deleteButton disabled" title="Delete Custom Request" disabled="disabled">
				{generalIcons.crossDelete}
			</CustomButton> : ''
		}

		return (
			<div className="event-actions">
				{snapshotButton}
				{shareButton}
				{downloadButton}
				{flaggedButton}
				{deleteButton}
				{noteButton}
			</div>
		)
	}

	const handlePageChange = (page, sizePerPage) => {
		getFleetEvents(page, sizePerPage);
	}

	const editVehicle = (device_id) => {
		browserHistory.push({ pathname: '/managevehicles/edit', state: { id: device_id, company: company, type: '/event' } });
	}

	const wcsCompany = company.partner === 'Rosco Vision WCS' || company.type === 'WCS'
	const selectedRow = {
		mode: 'radio',
		bgColor: '#f3f6c1', // you should give a bgcolor, otherwise, you can't regonize which row has been selected
		hideSelectColumn: true, // enable hide selection column.
		clickToSelect: true
	}
	const driverFeatureAccess = (company.driver_recognition !== 'manual' || ['system_admin'].indexOf(userRole) > -1)
	const tableColumnsWidth = COLUMNS_WIDTH[driverFeatureAccess * 1]

	const timestampFormatter = (cell, row) => {
		if (cell) {
			// console.log('cell', cell)
			// return moment(cell).format('M/DD/YYYY hh:mm:ss A');
			return momenttz(cell).format('M/DD/YYYY hh:mm:ss A');
		}
		return 'Not reviewed';
	}

	const durationFormatter = (cell, row) => {
		// FWSD-4819
		if (eventStatusFormatter(row.uploading_status) !== 'Uploaded') {
			return '0'
		}

		let clipDuration = row.duration
		// FWSD-4558
		if (row.accurate_duration) {
			clipDuration = row.accurate_duration
		}
		return moment.duration(clipDuration, 'seconds').format('h [hrs] m [min] s [sec]', { trim: 'both' })
		// return moment.duration(moment(row.end_ts).diff(moment(row.start_ts), 'seconds'), 'seconds').format('h [hrs] m [min] s [sec]', { trim: 'both' })
	}
	const driverFormatter = (cell, row) => {
		return row.driver_name || row.geotab_driver
	}
	const timeFormatter = (cell, row) => {
		return moment(cell).format('hh:mm:ss A')
	}
	const vehicleFormatter = (cell, row) => {
		const editVehicleUser = userRole === 'system_admin' || userRole === 'customer_service';
		return editVehicleUser ?
			<CustomButton variant="link" onClick={() => editVehicle(row.device_id)}>{cell || row.device_id}</CustomButton> :
			(cell || row.device_id)
	}
	const vehicleCSVFormatter = (cell, row) => {
		return row.vehicle_name || row.device_id
	}
	const videostatusFormatter = (cell, row) => {
		if (row.size) {
			return `${(row.size).toFixed(2)} MB `
		}
		if (row.uploading_status && row.uploading_status.toLowerCase() === 'event_reported') {
			return 'pending'
		}
		if (row.uploading_status && row.uploading_status.toLowerCase() === 'video_non_exist') {
			return 'Unavailable'
		}

		const re = /_/gi;
		return row.uploading_status.replace(re, ' ')
	}

	const typeFormatter = (cell, row) => {
		return eventTypeDetection(row)
	}

	const displayItemName = (item) => {
		return item.vehicle_name || item.device_id
	}

	const getItemValue = (item) => {
		return item.vehicle_name || item.device_id;
	}

	const matchStateToTerm = (item, value) => {
		if (item.vehicle_name) {
			return item.vehicle_name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
		}

		return item.device_id.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1;
	}

	const getItemValueDriver = (item) => {
		return item.name || item.id
	}
	const displayItemNameDriver = (item) => {
		return item.name || item.id
	}
	const matchStateToTermDriver = (item, value) => {
		if (item.name) {
			return item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
		}

		return item.id.toLowerCase().indexOf(value.toLowerCase()) !== -1
	}
	const driverNameSort = name => (a, b, order) => {
		const first = a.driver_name || a.geotab_driver || '';
		const second = b.driver_name || b.geotab_driver || '';

		return order === 'asc' ? second.localeCompare(first) : first.localeCompare(second)

	}
	const durationSort = name => (a, b, order) => {
		const first = moment(a.end_ts).diff(moment(a.start_ts), 'seconds')
		const second = moment(b.end_ts).diff(moment(b.start_ts), 'seconds')

		if (order === 'desc') {
			return second - first
		}

		return first - second
	}

	const toggleCheckbox = ({ target }) => {
		const { name, checked } = target;
		sendGA({
			category: userRole,
			action: 'Select Column',
			label: (checked ? 'Add ' : 'Remove ') + name
		})
		const updatedColumns = { ...columns, ...{ [name]: checked } }
		setColumns(updatedColumns);
		localStorage.setItem('eventFlaggedColumns', JSON.stringify(updatedColumns))
	}

	const { vehicle, groups, reviewed, id, duration, requested_at, driver } = columns;

	return (
		<div className="cloud-storage">
			<div className="page-subheader flagged-events-subheader">
				<div className="event-search flagged-search">
					<select
						placeholder="select"
						className="event-search-select"
						name="search-category"
						id="search-category"
						value={searchCategory}
						onChange={handleSearchCategoryChange}
					>
						<option value="vehicle">Vehicle</option>
						<option value="driver">Driver</option>
						<option value="event">Event ID</option>
					</select>
					{searchCategory === 'vehicle'
						&& (
							<Autocomplete
								autoHighlight
								value={autoValue}
								inputProps={{ id: 'states-autocomplete' }}
								wrapperProps={{ className: 'search-autocomplete event-search-input' }}
								getItemValue={getItemValue}
								shouldItemRender={matchStateToTerm}
								menuStyle={{
									borderRadius: '3px',
									boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
									background: 'rgba(255, 255, 255, 0.9)',
									padding: '2px 0',
									fontSize: '90%',
									position: 'absolute',
									overflow: 'auto',
									maxHeight: '200px',
									left: '0',
									top: 'auto'
								}}
								onChange={(e, value) => {
									setAutoValue(value);
									searchVehicle(value);
								}}
								onSelect={(value, item) => {
									setAutoValue(value);
									searchVehicle(value);
								}}
								renderMenu={function (items, value, style) {
									return <div className="items-list" style={{ ...style, ...this.menuStyle }} children={items} />
								}
								}
								renderItem={(item, isHighlighted) => (
									<div
										className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
										key={item.device_id}
									>
										{displayItemName(item)}
									</div>
								)}
								renderInput={(props) => (
									<input {...props} placeholder="Search Vehicle" className="form-control" />
								)}
								items={devices}
							/>
						)}

					{searchCategory === 'driver'
						&& (
							<Autocomplete
								autoHighlight
								value={autoValue}
								inputProps={{ id: 'states-autocomplete' }}
								wrapperProps={{ className: 'search-autocomplete event-search-input' }}
								getItemValue={getItemValueDriver}
								shouldItemRender={matchStateToTermDriver}
								menuStyle={{
									borderRadius: '3px',
									boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
									background: 'rgba(255, 255, 255, 0.9)',
									padding: '2px 0',
									fontSize: '90%',
									position: 'absolute',
									overflow: 'auto',
									maxHeight: '200px',
									left: '0',
									top: 'auto'
								}}
								onChange={(e, value) => {
									setAutoValue(value);
									searchVehicle(value);
								}}
								onSelect={(value, item) => {
									setAutoValue(value);
									searchVehicle(value);
								}}
								renderMenu={function (items, value, style) {
									return <div className="items-list" style={{ ...style, ...this.menuStyle }} children={items} />
								}
								}
								renderItem={(item, isHighlighted) => (
									<div
										className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
										key={item.id}
									>
										{displayItemNameDriver(item)}
									</div>
								)}
								renderInput={(props) => (
									<input {...props} placeholder="Search Driver" className="form-control" />
								)}
								items={drivers}
							/>
						)}

					{searchCategory === 'event'
						&& (
							<input
								type="text"
								placeholder="Search Event"
								className="form-control event-search-input"
								id="event-search-input"
								value={autoValue}
								onChange={(e) => {
									setAutoValue(e.target.value);
									searchVehicle(e.target.value);
								}}
							/>
						)}
				</div>
				<div className='view-format-select'>
					<CustomButton variant="primary-outline" size="icon" active={eventsView === 'Table'} onClick={() => changeView('Table')}>
						{eventIcons.tableViewIcon}
					</CustomButton>

					<CustomButton variant="primary-outline" size="icon" active={eventsView === 'Grid'} onClick={() => changeView('Grid')}>
						{eventIcons.gridViewIcon}
					</CustomButton>
				</div>
			</div>

			<main className="event-page-table main-content-block">
				{!wcsCompany
					? (
						<div>
							{eventsView === 'Grid' ? <div>
								{events.length ?
									<EventClipsGrid
										events={events}
										cardActionsFormatter={cardActionsFormatter}
										onFlagEvent={onFlagEvent}
										openEventVideoAll={openEventVideoAll}
										openEventSnapshot={openEventSnapshot}
										totalSize={totalSize}
										sizePerPage={sizePerPage}
										handlePageChange={handlePageChange}
										editVehicle={editVehicle}
										editVehicleUser={userRole === 'system_admin' || userRole === 'customer_service'}
										showDate={true}
									/> :
									<div className="no-events-message">No Flagged Events</div>
								}
							</div> :
								<div className="blockWithDropdown">
									<Dropdown
										id="events-select-dropdown"
										className="column-select-dropdown"
										role="menuitem"
										drop
										align="start"
									>
										<Dropdown.Toggle bsPrefix="dropdown-toggle custom-button rosco-primary inverted small">
											Columns
										</Dropdown.Toggle>
										<Dropdown.Menu className="vehicle-select-menu">
											<div className="vehicle-checkbox-group">
												<h5 className="category-title">Columns</h5>
												<div style={{ display: "flex" }}>
													<div className="col-sm-6">
														<div>
															<input
																type="checkbox"
																className="vehicle-checkbox"
																checked={vehicle}
																name="vehicle"
																onChange={toggleCheckbox}
															/>
															Vehicle
														</div>
														<div>
															<input
																type="checkbox"
																className="vehicle-checkbox"
																checked={groups}
																name="groups"
																onChange={toggleCheckbox}
															/>
															Groups
														</div>
														<div>
															<input
																type="checkbox"
																className="vehicle-checkbox"
																checked={reviewed}
																name="reviewed"
																onChange={toggleCheckbox}
															/>
															Reviewed
														</div>
														<div>
															<input
																type="checkbox"
																className="vehicle-checkbox"
																checked={id}
																name="id"
																onChange={toggleCheckbox}
															/>
															ID
														</div>
													</div>
													<div className="col-sm-6">
														<div>
															<input
																type="checkbox"
																className="vehicle-checkbox"
																checked={duration}
																name="duration"
																onChange={toggleCheckbox}
															/>
															Duration
														</div>
														<div>
															<input
																type="checkbox"
																className="vehicle-checkbox"
																checked={requested_at}
																name="requested_at"
																onChange={toggleCheckbox}
															/>
															Requested At
														</div>
														<div>
															<input
																type="checkbox"
																className="vehicle-checkbox"
																checked={driver}
																name="driver"
																onChange={toggleCheckbox}
															/>
															Driver
														</div>
													</div>
												</div>
											</div>
										</Dropdown.Menu>
									</Dropdown>

									<BootstrapTable
										data={events}
										{...tableAdjustableColExportOptions}
										csvFileName="flagged_events.csv"
									>
										<TableHeaderColumn
											width={tableColumnsWidth['Date / Time']}
											dataField="timestamp"
											dataSort
											dataFormat={timestampFormatter}
											csvHeader={'Date / Time'}
											tdStyle={{ whiteSpace: 'normal' }}
										>
											{renderCustomHeader(0, 'Date / Time')}
										</TableHeaderColumn>
										<TableHeaderColumn
											width="10%"
											dataField="type"
											dataSort
											tdStyle={{ whiteSpace: 'normal' }}
											dataFormat={typeFormatter}
											csvFormat={typeFormatter}
											csvHeader={'Event Type'}
										>
											{renderCustomHeader(1, 'Type')}
										</TableHeaderColumn>
										<TableHeaderColumn
											width={tableColumnsWidth.Vehicle}
											dataField="vehicle_name"
											dataSort
											dataFormat={vehicleFormatter}
											csvFormat={vehicleCSVFormatter}
											csvHeader="Vehicle Name"
											tdStyle={{ whiteSpace: 'normal' }}
											hidden={!vehicle}
										>
											{renderCustomHeader(2, 'Vehicle')}
										</TableHeaderColumn>
										<TableHeaderColumn
											width={tableColumnsWidth.Groups}
											dataField="groups"
											dataSort
											csvHeader="Groups"
											tdStyle={{ whiteSpace: 'normal' }}
											hidden={!groups}
										>
											{renderCustomHeader(3, 'Groups')}
										</TableHeaderColumn>
										<TableHeaderColumn
											width={tableColumnsWidth.Reviewed}
											dataField="last_viewed"
											dataSort
											dataFormat={timestampFormatter || 'Not Reviewed'}
											csvHeader="Reviewed"
											tdStyle={{ whiteSpace: 'normal' }}
											hidden={!reviewed}
										>
											{renderCustomHeader(4, 'Reviewed')}
										</TableHeaderColumn>
										<TableHeaderColumn
											width={tableColumnsWidth.ID}
											dataField="id"
											csvHeader="ID"
											dataSort
											isKey
											hidden={!id}
										>
											{renderCustomHeader(5, 'ID')}
										</TableHeaderColumn>
										<TableHeaderColumn
											width={tableColumnsWidth.Duration}
											dataField="start_ts"
											dataSort
											csvHeader="Start Time"
											sortFunc={durationSort()}
											dataFormat={durationFormatter}
											hidden={!duration}
										>
											{renderCustomHeader(6, 'Duration')}
										</TableHeaderColumn>
										<TableHeaderColumn
											width={tableColumnsWidth.RequestedAt}
											dataField="createtime"
											dataSort
											dataFormat={timestampFormatter}
											csvHeader={'Requested At'}
											hidden={!requested_at}
										>
											{renderCustomHeader(7, 'Requested At')}
										</TableHeaderColumn>

										{driverFeatureAccess
											&& (
												<TableHeaderColumn
													width={tableColumnsWidth.Driver}
													dataField="driver_name"
													dataSort
													dataFormat={driverFormatter}
													csvHeader="Driver Name"
													tdStyle={{ whiteSpace: 'normal' }}
													sortFunc={driverNameSort()}
													hidden={!driver}
												>
													{renderCustomHeader(8, 'Driver')}
												</TableHeaderColumn>
											)}

										<TableHeaderColumn
											width="15%"
											dataField={'custom_event_type'}
											dataFormat={actionsFormatter}
											csvHeader="Note"
											csvFormat={(cell) => cell || ''}
											tdStyle={{ whiteSpace: 'normal' }}
										>
											<div className="custom-table-header-row">
												<div className="custom-table-header-title">Actions</div>
											</div>
										</TableHeaderColumn>
									</BootstrapTable>
								</div>}
						</div>
					) : (
						<BootstrapTable
							data={events}
							remote
							fetchInfo={{ dataTotalSize: totalSize }}
							selectRow={selectedRow}
							{...tableAdjustableColExportOptions}
							csvFileName="flagged_events.csv"
						>
							<TableHeaderColumn
								width="11%"
								dataField="timestamp"
								dataSort
								dataFormat={timestampFormatter}
								csvHeader="Date / Time"
								tdStyle={{ whiteSpace: 'normal' }}
							>
								{renderCustomHeader(0, 'Date / Time')}
							</TableHeaderColumn>

							<TableHeaderColumn
								width="10%"
								dataField="type"
								dataSort
								tdStyle={{ whiteSpace: 'normal' }}
								dataFormat={typeFormatter}
								csvFormat={typeFormatter}
							>
								{renderCustomHeader(1, 'Type')}
							</TableHeaderColumn>
							<TableHeaderColumn
								width="9%"
								dataField="vehicle_name"
								dataSort
								dataFormat={vehicleFormatter}
								tdStyle={{ whiteSpace: 'normal' }}
							>
								{renderCustomHeader(2, 'Vehicle')}
							</TableHeaderColumn>
							<TableHeaderColumn
								width="6%"
								dataField="groups"
								dataSort
								tdStyle={{ whiteSpace: 'normal' }}
							>
								{renderCustomHeader(3, 'Groups')}
							</TableHeaderColumn>
							<TableHeaderColumn
								width="8%"
								dataField="size"
								dataSort
								dataFormat={videostatusFormatter}
								tdStyle={{ whiteSpace: 'normal' }}
							>
								{renderCustomHeader(4, 'File Size')}
							</TableHeaderColumn>
							<TableHeaderColumn
								width="11%"
								dataField="last_viewed"
								dataSort
								dataFormat={timestampFormatter}
								tdStyle={{ whiteSpace: 'normal' }}
							>
								{renderCustomHeader(7, 'Reviewed')}
							</TableHeaderColumn>
							<TableHeaderColumn
								width="5%"
								dataField="id"
								dataSort
								isKey
							>
								{renderCustomHeader(8, 'ID')}
							</TableHeaderColumn>
							<TableHeaderColumn
								width="7%"
								dataField="start_ts"
								dataSort
								dataFormat={timeFormatter}
								tdStyle={{ whiteSpace: 'normal' }}
							>
								{renderCustomHeader(9, 'Start')}
							</TableHeaderColumn>
							<TableHeaderColumn
								width="7%"
								dataField="end_ts"
								dataSort
								dataFormat={timeFormatter}
								tdStyle={{ whiteSpace: 'normal' }}
							>
								{renderCustomHeader(10, 'End')}
							</TableHeaderColumn>
							<TableHeaderColumn
								width="11%"
								dataFormat={actionsFormatterWCS}
								tdStyle={{ whiteSpace: 'normal' }}
							>
								<div className="custom-table-header-row">
									<div className="custom-table-header-title">Actions</div>
								</div>
							</TableHeaderColumn>
						</BootstrapTable>
					)
				}
			</main>

			{showEventSnapshot
				&& (
					<EventViewSnapshotModal
						event={selectedEvent}
						onHide={onCloseEventSnapshot}
					/>
				)}

			<EventNoteModal
				show={showEventNote}
				event={selectedEvent}
				company_name={company.name || user.company_name}
				partner_name={company.partner || user.partner_company_name}
				onHide={onCloseEventNote}
				onSave={(note) => {
					getEventsFlagged();
					onCloseEventNote(note);
				}}
			/>

			{showEventVideoAll
				&& (
					<AdvancedPlayer
						onHide={onCloseEventVideoAll}
						openShareEvent={openShareEvent}
						openEventNote={openEventNote}
						onFlagEvent={onFlagEvent}
						isFlagged={isFlagged}
						event={selectedEvent}
						note={selectedEvent.custom_event_type}
						updateDriver={updateDriver}
					/>
				)}

			{showShareEvent
				&& (
					<EventShareModal
						event={selectedEvent}
						onHide={onCloseShareEvent}
					/>
				)}

			{showModalDelete
				&& (
					<ModalDelete
						title={'Delete Custom Video Request'}
						content={`Are you sure you want to delete this custom video request?`}
						closeModal={closeModalDelete}
						deleteModal={deleteCustomRequest}
					/>
				)}
		</div>
	)
}

EventFlagged.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired
}

const mapStateToProps = ({ user, company }) => ({
	user: user.user,
	company: company.company,
})

const mapDispatchToProps = dispatch => ({
	toggleLoader: (show) => {
		dispatch({ type: 'TOGGLE_LOADER', payload: show });
	},
	setEventArchiveRestoring: (state) => {
		dispatch({ type: 'SET_EVENT_ARCHIVE_RESTORING', payload: state });
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(EventFlagged)
