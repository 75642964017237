import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import { formatAddress, formatSpeedMPH, formatTimestamp } from '../../../component/helpers/tablesFuncHelpers';
import { getDeviceAddress } from '../../../action/DeviceActions';
import { vehicleIcons } from '../../../component/helpers/iconHelpers';
import momenttz from '../../../component/helpers/momenttz';
// import { FaArrowRight } from "react-icons/fa6";
import { IoMdArrowForward } from "react-icons/io"

const TreeVehicleNode = (props) => {
	// console.log('PROPS TreeVehicleNode: ', props)
	// const { device_id, vehicle_name, online, gps_online, driver_name, activeTreeItem, last_disconnected_ts, ignition, markerInfo, onTreeDeviceClick, isInternalUser, showTripsHistory, entry, status } = props
	const { device, activeTreeItem, onTreeDeviceClick } = props
	const { device_id, vehicle_name, online, ignition, gps_online, last_disconnected_ts, status } = device

	const [address, setAddress] = useState('')

	const prevProps = useRef(props).current;

	// if (props.device.vehicle_name === '28') {
	// 	console.log('PROPS TreeVehicleNode 28 general_status: ', props.device.general_status)
	// 	console.log('PROPS TreeVehicleNode 28: ', props)
	// }

	// useEffect(() => {
	// 	if (activeTreeItem && (device_id === activeTreeItem.device_id) && props !== prevProps) {
	// 		// || address === '' - We have thousend of requests and receive the 429 http error when /address always returns null/empty address
	// 		if (!showTripsHistory && (online && ignition && ignition.state) /* || address === '' */) {
	// 			getAddress();
	// 		}
	// 	}
	// }, [props])

	const getAddress = (e = null) => {
		e && e.stopPropagation()

		if (!markerInfo || !markerInfo.position) {
			return 'Not Available'
		}
		const data = { lat: markerInfo.position.lat, lng: markerInfo.position.lng };

		getDeviceAddress(data)
			.then((res) => {
				setAddress(formatAddress(res.data.response.address))
			})
			.catch(err => {
				console.log(err)
			})
	}

	const getStatusTime = () => {
		if (status === 'offline' && last_disconnected_ts) {
			return <div className="status-time">{`Since: ${momenttz(last_disconnected_ts + (last_disconnected_ts.toString().includes('GMT') ? '' : '+00')).format('MM/DD/YY')}`}</div>
		}

		if (status === 'parked' && ignition && ignition.ts) {
			const parkingDurationSecs = moment.utc().diff(moment.utc(ignition.ts), 'seconds')
			const subTitle = parkingDurationSecs < 60 ? 'Just Now' : moment.duration(parkingDurationSecs, 'seconds').format('d[D] h[H] m[M]', { trim: 'both' })
			return (
				<div className="status-time">
					{`Parked: ${subTitle}`}
				</div>
			)
		}
	}

	const isActive = activeTreeItem && (device_id === activeTreeItem.device_id)

	// FWSD-4314 - If device does not receive GPS from device and ignition is Off more 24 hours (1440 mins) then display Online status.
	// Device stopped receive GPS, broken antena or something, and FileServer does not know when ignition is On.
	// if ((row.ignition && row.ignition.state) || (!row.gps_online && durationInMins > 1440)) {
	let onlineStatus = 'parking'
	if (!online) {
		onlineStatus = 'offline'
	}
	else {
		// const ignitionOffTS = ignition && ignition.ts ? ignition.ts : ''; // 2023-01-04 18:50:37
		// const duration = moment.duration(moment.utc().diff(moment.utc(ignitionOffTS), 'seconds'), 'seconds')
		// const durationInMins = duration.asMinutes()

		if ((ignition && ignition.state) /*|| (!gps_online && durationInMins > 1440)*/) {
			onlineStatus = 'online'
		}
	}
	const isOnline = onlineStatus === 'online'
	const isParked = onlineStatus === 'parking' //online && (!ignition || !ignition.state)

	return (
		<div
			// className={classNames("tree-node-container", { active: isActive, online: isOnline, parking: isParked })}
			className={`tree-node-container ${isActive && 'active'}`}
			// onClick={() => onTreeDeviceClick(entry)}
			onClick={() => onTreeDeviceClick(device)}
			tabIndex={0}
			role="button"
		>
			<div className="tree-node-header category-item flex-item thin">
				<div className="status-row">
					<div className={`icon ${status.replace(' ', '_')}`}></div>
					<div className="vehicle-name">{vehicle_name || device_id}</div>

				</div>
				{getStatusTime()}
				<IoMdArrowForward/>
			</div>
			{/* <h5 className="tree-node-header">
				<label className={classNames("vehicle-name", { 'pulse': (isParked && isActive) })}>
					{vehicle_name || device_id}
				</label>
				<FaArrowRight/>
			</h5> */}
			{/* {isActive && (
				<div className="tree-node-body">
					{driver_name && (
						<div className='node-icon-wrapper'>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-badge node-icon" viewBox="0 0 16 16">
								<path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
								<path d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z" />
							</svg>
							{driver_name}
						</div>
					)}
					{online && ignition && ignition.state && markerInfo && markerInfo.infoData && markerInfo.infoData.spd && (
						<div className='node-icon-wrapper'>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-speedometer node-icon" viewBox="0 0 16 16">
								<path d="M8 2a.5.5 0 0 1 .5.5V4a.5.5 0 0 1-1 0V2.5A.5.5 0 0 1 8 2zM3.732 3.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 8a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 7.31A.91.91 0 1 0 8.85 8.569l3.434-4.297a.389.389 0 0 0-.029-.518z" />
								<path fillRule="evenodd" d="M6.664 15.889A8 8 0 1 1 9.336.11a8 8 0 0 1-2.672 15.78zm-4.665-4.283A11.945 11.945 0 0 1 8 10c2.186 0 4.236.585 6.001 1.606a7 7 0 1 0-12.002 0z" />
							</svg>
							{formatSpeedMPH(markerInfo.infoData.spd)}
						</div>
					)}
					{(markerInfo && markerInfo.ignitionСhange && markerInfo.ignitionСhange.ts) && (!ignition || !ignition.state) && online && gps_online && (
						<div>
							Parked for {moment.duration(moment.utc().diff(moment.utc(markerInfo.ignitionСhange.ts || ''), 'seconds'), 'seconds').format('hh:mm:ss', { trim: false })}
						</div>
					)}
					{(markerInfo && markerInfo.position && markerInfo.position.lat !== 0) && (
						<button className='address-button node-icon-wrapper' onClick={getAddress} role="menuitem">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" onClick={getAddress} fill="currentColor" className="bi bi-geo-alt-fill node-icon" viewBox="0 0 16 16">
								<path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path>
							</svg>
							<span>{address || 'Get Address'}</span>
						</button>
					)}
					{(isInternalUser && !showTripsHistory && markerInfo && markerInfo.infoData && markerInfo.infoData.timestamp) &&
						<div>
							<label className='node-label'>Last GPS: </label>{formatTimestamp(`${markerInfo.infoData.timestamp}`)}
						</div>
					}
					{!online &&
						<div>
							<label className='node-label'>Last Online: </label>{last_disconnected_ts ? formatTimestamp(last_disconnected_ts) : 'Not Available'}
						</div>
					}
				</div>
			)} */}
		</div>
	)
}

TreeVehicleNode.propTypes = {
	// device_id: PropTypes.string.isRequired,
	vehicle_name: PropTypes.string,
	onTreeDeviceClick: PropTypes.func.isRequired,
};

TreeVehicleNode.defaultProps = {
	vehicle_name: '',
}

export default TreeVehicleNode;
