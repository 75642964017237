import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router';
import { Button } from 'react-bootstrap';
import { withGoogleMap, GoogleMap, Rectangle, Marker } from 'react-google-maps';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux'
import onResize from 'simple-element-resize-detector'

import { default as DrawingManager } from 'react-google-maps/lib/components/drawing/DrawingManager';
import { default as SearchBox } from 'react-google-maps/lib/components/places/SearchBox';

import GreyHeader from '../../component/dump/menuComponents/GreyHeader'
import ModalDelete from '../../component/smart/modals/ModalDelete';
import { getCompanyWifipoints, saveCompanyWifipoints, deleteCompanyWifiPoint } from '../../action/WCSActions';
import { getDeviceAddress } from '../../action/DeviceActions'
import { googleMapStylesList } from '../../component/helpers/constants'
import CustomButton from '../../component/CustomButton';

const INPUT_STYLE = {
	boxSizing: 'border-box',
	MozBoxSizing: 'border-box',
	border: '1px solid transparent',
	width: '240px',
	height: '32px',
	marginTop: '10px',
	padding: '0 12px',
	borderRadius: '1px',
	boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
	fontSize: '14px',
	outline: 'none',
	textOverflow: 'ellipses',
};

const SimpleMapExampleHEREMap = (props) => {
	const { userRole, onMapMounted, onClickSnapRoad, onClickAddress, onSelectMarker, markers, polygons, tripsGPS, tripsGPSSnapRoad, tripsEvents, activeTreeItem, showSnapRoad, selectedTrip, center, zoom } = props;

	let mapRef = useRef(null);
	const [mapState, setMapState] = useState({});
	const [polyline, setPolyline] = useState(null);
	const [map, setMap] = useState(null);
	const [layer, setLayer] = useState(null);
	const [platform, setPlatform] = useState(null);
	const [mapUI, setMapUI] = useState(null);
	const [dropData, setDropData] = useState([]);
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		if (!map) {
			// instantiate a platform, default layers and a map as usual
			const platform = new H.service.Platform({
				apikey: process.env.REACT_APP_HERE_MAPS_API_KEY
			});
			const layers = platform.createDefaultLayers();
			const MAP = new H.Map(
				mapRef,
				layers.vector.normal.map,
				{
					center,
					zoom,
					pixelRatio: window.devicePixelRatio || 1
				},
			);

			onResize(mapRef, (a, p, c) => {
				MAP.getViewPort().resize();
			});
			// add the interactive behaviour to the map
			new H.mapevents.Behavior(new H.mapevents.MapEvents(MAP));
			// Create the default UI components
			const ui = H.ui.UI.createDefault(MAP, layers);
			ui.getControl('mapsettings').setAlignment('top-left');
			ui.getControl('zoom').setAlignment('right-top');

			setMap(MAP);
			onMapMounted(MAP);
			setMapUI(ui);
			setPlatform(platform);
		}
	}, []);

	useEffect(() => {
		props.bounds.map((bound, index) => {
			const bounds = map.getViewModel().getLookAtData().bounds.getBoundingBox();
			const center_map = map.getViewModel().getLookAtData().position;
			const location = { lat: center_map.lat, lng: center_map.lng };

			const boundingREct = new H.geo.Rect(bounds.getTop(), bounds.getRight(), bounds.getBottom(), bounds.getLeft());
			map.addObject(
				new H.map.Rect(boundingREct, {
					style: {
						fillColor: '#ff0000',
						strokeColor: '#ff0000',
						lineWidth: 2
					},
				})
			);

			if (props.zoom < 18) {
				map.addObject(new H.map.Marker(location, {
					data: { key: 'marker' + index },
					onAttach: function (clonedElement, domIcon, domMarker) {
						clonedElement.addEventListener('click', () => g_onclickMarker(bound));
					},
					onDetach: function (clonedElement, domIcon, domMarker) {
						clonedElement.removeEventListener('click', () => g_onclickMarker(bound));
					},
				}));
			}
		});
	}, [props.bounds]);

	globalThis.g_onclickMarker = (bound) => {
		onSelectMarker(bound)
	};

	globalThis.g_selectItem = (i) => {
		const item = dropData[i];
		setSearchValue(item.title);
		map.setCenter({ lat: item.position.lat, lng: item.position.lng });
		document.getElementById("list").innerHTML = "";
	};

	const autosuggest = async (e) => {
		const searchString = e.target.value;
		setSearchValue(searchString);
		document.getElementById("list").innerHTML = "";

		if (searchString !== "") {
			await fetch(`https://autosuggest.search.hereapi.com/v1/autosuggest?apiKey=${process.env.REACT_APP_HERE_MAPS_API_KEY}&at=${center.lat},${center.lng}&limit=5&resultType=city&q=${searchString}&lang=en-US`)
				.then((res) => res.json())
				.then((json) => {
					if (json.length !== 0) {
						setDropData(json.items);
						json.items.map((item, i) => {
							document.getElementById("list").innerHTML += `<li key=${item.title} onclick="g_selectItem(${i})">${item.title}</li>`;
						})
					}
				});
		}
	};

	return (
		<div
			id="map"
			style={{ position: 'relative', width: '100%', height: '100%' }}
			ref={(c) => { mapRef = c }}
		>
			<div className="searchContainer">
				<input placeholder="Search for a Place or an Address" type="text" name="search" id="search" value={searchValue} autoComplete="off" onChange={autosuggest} autoFocus />
				<i className="fa fa-search fa-search-custom" aria-hidden="true"></i>
				<div className="dropdown">
					<ul id="list"></ul>
				</div>
			</div>
		</div>
	)
}

const CompanyEditWCSPoints = (props) => {
	const { user, location, companyEditWCSPoints, toggleLoader } = props;
	const user_role = user.roles[0];
	const _company_name = location.state.company.company_name;
	const _partner_name = location.state.company.partner_company;

	const [center, setCenter] = useState({ lat: 37.09024, lng: -95.712891 });
	const [zoom, setZoom] = useState(6);
	const [bounds, setBounds] = useState([]);
	const [newbounds, setNewbounds] = useState([]);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deletePoint, setDeletePoint] = useState(null);
	const [_map, setMap] = useState(null);
	const [_searchBox, setSearchBox] = useState(null);

	useEffect(() => {
		// console.log('CompanyEditWCSPoints')
		// props.onRef(CompanyEditWCSPoints)

		toggleLoader(true);

		getWifiPoints(() => {
			centeringWifiPoints()
		})

		// return () => {
		// 	props.onRef(undefined)
		// }
	}, []);

	useEffect(() => {
		if (companyEditWCSPoints) setTimeout(() => centeringWifiPoints(), 200);
	}, [companyEditWCSPoints]);

	const handleMapMounted = (map) => {
		setMap(map);
	}

	const onZoomChangedManually = (e, p) => {
		const zoom = _map.getZoom();
		setZoom(zoom);
	}

	const getWifiPoints = (callback) => {
		getCompanyWifipoints({
			user_role,
			company_name: _company_name,
			partner_name: _partner_name
		})
			.then((res, err) => {
				toggleLoader(false);
				console.log('!getCompanyWifipoints res: ', res, err);

				setBounds(res.data.response.wifipoints)

				if (callback && typeof callback === 'function') {
					callback();
				}
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!getCompanyWifipoints error:', error);
			});
	}

	const centeringWifiPoints = () => {
		let bounds = _map?.getViewModel().getLookAtData().bounds.getBoundingBox();

		if (bounds.length) {
			if (bounds.length === 1) {
				if (_map) {
					const rect = H.geo.Rect.coverPoints(bounds, true);
					_map.getViewModel().setLookAtData({ bounds: rect.getBoundingBox() });
					setCenter(bounds[0].getCenter());
				}
			} else {
				if (_map) {
					const rect = H.geo.Rect.coverPoints(bounds, true);
					_map.getViewModel().setLookAtData({ bounds: rect.getBoundingBox() });
					const center_map = _map.getCenter();
					setCenter(center_map);
				}
			}
		}
	}

	const handleRectangleComplete = (rectangle, id) => {
		toggleLoader(true);
		const bounds = rectangle.getBounds();
		const center = bounds.getCenter();
		const ne = bounds.getNorthEast();
		const sw = bounds.getSouthWest()

		const data = { lat: center.lat(), lng: center.lng() };
		getDeviceAddress(data)
			.then(res => {
				let address = formatAddress(res.data.response.address)
				address = prompt('Enter the wifi pointer name or change address', address)

				let newboundsUpdate = newbounds;
				newboundsUpdate.push({
					id,
					area: { nelat: ne.lat(), nelng: ne.lng(), swlat: sw.lat(), swlng: sw.lng() },
					rectangle,
					name: address,
				})
				setNewbounds(newboundsUpdate);
				toggleLoader(false);
			})
			.catch(err => {
				console.log(err);
			})
	}

	const handleBoundsChanged = (rectangle, id) => {
		const bounds = rectangle.getBounds();
		const ne = bounds.getNorthEast();
		const sw = bounds.getSouthWest();

		let newboundsUpdate = newbounds;
		let point = _.find(newboundsUpdate, ['id', id]);
		if (point) {
			// point.area = JSON.stringify({nelat: ne.lat(), nelng: ne.lng(), swlat: sw.lat(), swlng: sw.lng()});
			point.area = { nelat: ne.lat(), nelng: ne.lng(), swlat: sw.lat(), swlng: sw.lng() };
			setNewbounds(newboundsUpdate);
		}

		let contentString = '' + ne.lat() + ', ' + ne.lng() + '-' + sw.lat() + ', ' + sw.lng();
	}

	const onTreeItemClick = (rectangle) => {
		// const address = JSON.parse(rectangle.address);
		const obj = _map.getObjects().filter(e => e && e instanceof H.map.Rect)[rectangle.id];
		const bounds = obj.getBoundingBox();
		const center = obj.getCenter();

		if (_map) {
			_map.getViewModel().setLookAtData({ bounds });
			setCenter(center);
		}
	}

	const handleSearchBoxMounted = (searchBox) => {
		setSearchBox(searchBox);
	}

	const handlePlacesChanged = () => {
		const places = _searchBox.getPlaces();

		if (places.length) {
			setZoom(18);
			setCenter(places[0].geometry.location);
		}
	}

	const cancel = () => {
		browserHistory.push('/company');
	}

	const saveWifipoints = () => {
		if (newbounds.length) {
			toggleLoader(true);

			// wifipoints   = _.map(this.state.newbounds, 'area');
			const wifipoints = _.map(newbounds, item => ({ area: item.area, name: item.name }))

			saveCompanyWifipoints({
				user_role,
				company_name: _company_name,
				partner_name: _partner_company,
				points: wifipoints,
			})
				.then((res, err) => {
					console.log('saveWifipoints res', res, err);
					getWifiPoints(() => {
						newbounds.map((point, i) => {
							point.rectangle.setMap(null);
						})

						setNewbounds([]);
						toastr.success('', 'Wifi points were saved');
					});
				})
				.catch((error) => {
					toggleLoader(false);
					console.log('saveWifipoints error', error);
					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription);
				});
		} else {
			toastr.warning('Warning', 'Company does not have new wifi points');
		}
	}

	const deleteWifiPoint = () => {
		if (deletePoint.type === 'exist') {
			toggleLoader(true);

			const wifipoint_id = deletePoint.id;
			deleteCompanyWifiPoint({
				user_role,
				company_name: _company_name,
				partner_name: _partner_name,
				wifipoint_id,
			})
				.then((res, err) => {
					toggleLoader(false);
					console.log('!deleteWifiPoint res:', res, err);

					const boundsUpdate = bounds.filter(bound => bound.id != wifipoint_id)
					setBounds(boundsUpdate);
				})
				.catch((error) => {
					toggleLoader(false);

					console.log('!deleteWifiPoint error', error);

					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription);
				});
		} else if (deletePoint.type === 'new') {
			let delRectangle = null;
			const newboundsUpdate = newbounds.filter((bound) => {
				if (bound.id === deletePoint.id) {
					delRectangle = bound.rectangle;
				} else {
					return true;
				}
			})

			if (delRectangle) {
				delRectangle.setMap(null);
			}

			setNewbounds(newboundsUpdate);
		}

		setShowDeleteModal(false);
		setDeletePoint(null);
	}

	const openDeleteModal = (type, id = null) => {
		setShowDeleteModal(true);
		setDeletePoint({ type, id });
	}

	const closeDeleteModal = () => {
		setShowDeleteModal(false);
	}

	return (
		<div>
			<GreyHeader
				{...props.headerOptions}
			/>
			<div className='page-subheader'>
				<div className='subheader-text-container'>
					<div className='subheader-text'>
						Wifi Points: {_company_name}
					</div>
				</div>
				<div className="subheader-section">
					<CustomButton type="button" variant="secondary" onClick={saveWifipoints}>SAVE</CustomButton>
					<CustomButton type="button" variant="secondary-outline" onClick={cancel}>BACK</CustomButton>
				</div>
			</div>

			<main className="track-container-inner" style={{ position: 'relative' }}>
				<div id="block-tree" style={{ float: 'left', position: 'relative', height: '800px', width: '340px', padding: '15px' }}>
					<div className="wifipoints-tree">
						<ol>
							{newbounds.map((rectangle, index) => {
								const { area } = rectangle;
								const { id } = rectangle;
								return (
									<li
										key={index}
										style={{ color: '#52e258' }}
										onClick={() => onTreeItemClick(rectangle)}
									>
										{rectangle.name ? rectangle.name : `${area.nelat}, ${area.nelng}`}
										<span className="delete-wifipointer-icon">
											<i className="fa fa-remove" onClick={() => { openDeleteModal('new', id) }} />
										</span>
									</li>
								)
							})}

							{bounds.map((rectangle, index) => {
								const { area } = rectangle;
								const { id } = rectangle;
								return (
									<li
										key={index}
										onClick={() => onTreeItemClick(rectangle)}
									>
										{rectangle.name ? rectangle.name : `${area.nelat}, ${area.nelng}`}
										<span className="delete-wifipointer-icon">
											<i className="fa fa-remove" onClick={() => { openDeleteModal('exist', id) }} />
										</span>
									</li>
								)
							})}
						</ol>
					</div>
				</div>

				<div id="map-block" style={{ top: 'auto', bottom: 'auto', left: '340px', minHeight: '800px' }}>
					<SimpleMapExampleHEREMap
						containerElement={
							(isMobileOnly)
								? <div style={{ height: 'calc(100vh - 50px)' }} className="container-element" />
								: <div style={{ height: '800px' }} className="container-element" />
						}
						mapElement={
							<div style={{ height: '100%' }} className="map-element" />
						}
						center={center}
						zoom={zoom}
						bounds={bounds}
						_map={_map}
						onMapMounted={handleMapMounted}
						onZoomChanged={onZoomChangedManually}
						handleRectangleComplete={handleRectangleComplete}
						handleBoundsChanged={handleBoundsChanged}
						onSelectMarker={onTreeItemClick}
						onSearchBoxMounted={handleSearchBoxMounted}
						onPlacesChanged={handlePlacesChanged}
					/>

					{showDeleteModal
						&& (
							<ModalDelete
								content="Are you sure you want to delete this wifi point ?"
								closeModal={closeDeleteModal}
								deleteModal={deleteWifiPoint}
							/>
						)}
				</div>
			</main>
		</div >
	)
}

CompanyEditWCSPoints.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(
	state => ({
		user: state.user.user,
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		},
	})
)(CompanyEditWCSPoints);
