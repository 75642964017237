import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown } from 'react-bootstrap'
import classNames from 'classnames'
import { BsDot } from 'react-icons/bs'
import { FaCheck } from 'react-icons/fa'

import CustomButton from './CustomButton'
import { filterIcon } from './helpers/iconHelpers'
import { isInternalUser } from './helpers/constants'
import { getFilterName } from './helpers/helperEvents'
import { isCustomUserPermissions } from './helpers/helperUsers'

import './FilterMenu.sass';

// TODO: needs to develop universal filter menu for events
const FilterMenu = (props) => {
    // console.log('PROPS EventClipsEventsFilters: ', props)
    const { userRole, user, filters, videostatuses, groups, selectAllFilters, fillFilters, fillVideostatuseFilters, fillGroups, selectAllGroups, onMoreGroupsClick, twoColumns, showGroups } = props
	const userPermissions = user.permissions
    const canEventFeedbackView = ['system_admin', 'customer_service', 'sales_demo', 'partner'].includes(userRole) 
		|| (['custom_user', 'custom_partner'].includes(userRole) && isCustomUserPermissions(userRole, userPermissions, 'event_feedback_add'))
		|| (['custom_user', 'custom_partner'].includes(userRole) && isCustomUserPermissions(userRole, userPermissions, 'event_feedback_edit'))

    let countSelectedFilters = canEventFeedbackView ? 0 : 1
    let showFilterActive = false
    for (let [filter, value] of Object.entries(filters)) {
        value && countSelectedFilters++
        if (!['pending', 'feedback'].includes(filter) && !value) {
            showFilterActive = true
        }
    }

    const countSelectedGroups = _.reduce(groups, (sum, value, key) => { if (value.selected) { return sum + 1 } return sum }, 0)
    const countVisibleGroups = _.reduce(groups, (sum, value, key) => { if (value.visible) { return sum + 1 } return sum }, 0)

    return (
        <Dropdown
            id="type-filter-btn"
            align='end'
            className="event-filters"
            drop
        >
            <Dropdown.Toggle
                as={Button}
                variant='outline-primary'
                bsPrefix="no-caret event-filter-toggle"
            >
                {filterIcon}
                {showFilterActive &&
                    <BsDot className='filter-dot' />
                }
            </Dropdown.Toggle>
            <Dropdown.Menu
                className="event-filter-menu"
            >
                <h5 className="category-title">Events</h5>
                <CustomButton
                    variant="link"
                    onClick={selectAllFilters}
                >
                    {`${countSelectedFilters === Object.keys(filters).length ? 'Unselect' : 'Select'} All`}
                </CustomButton>
                <ul className={classNames('filters-list', { 'two-columns': twoColumns })}>
                    <li>
                        <button className='filter-button' onClick={() => fillFilters('critical_gsensor')}>
                            High G-Force
                            {filters.critical_gsensor && <FaCheck className='filter-check' />}
                        </button>
                    </li>
                    <li>
                        <button className='filter-button' onClick={() => fillFilters('speed')}>
                            Max Speed
                            {filters.speed && <FaCheck className='filter-check' />}
                        </button>
                    </li>
                    <li>
                        <button className='filter-button' onClick={() => fillFilters('driver')}>
                            Driver
                            {filters.driver && <FaCheck className='filter-check' />}
                        </button>
                    </li>
                    <li>
                        <button className='filter-button' onClick={() => fillFilters('custom')}>
                            Custom
                            {filters.custom && <FaCheck className='filter-check' />}
                        </button>
                    </li>
                    <li>
                        <button className='filter-button' onClick={() => fillFilters('harshaccel')}>
                            Harsh Acceleration
                            {filters.harshaccel && <FaCheck className='filter-check' />}
                        </button>
                    </li>
                    {/* Only for Tenna and internal users (FWSD-7355) */}
                    {/* {(user?.partner_company_name === "TENNA LLC" || user?.company_name === "Tenna LLC" || isInternalUser(userRole)) && */}
                        <li>
                            <button className='filter-button' onClick={() => fillFilters('camerablock')}>
                                Potential Ch2 Obstruction
                                {filters.camerablock && <FaCheck className='filter-check' />}
                            </button>
                        </li>
                    {/* } */}
                    <li>
                        <button className='filter-button' onClick={() => fillFilters('harshbraking')}>
                            Harsh Braking
                            {filters.harshbraking && <FaCheck className='filter-check' />}
                        </button>
                    </li>
                    {!isInternalUser(userRole) &&
                        <li>
                            <button className='filter-button' onClick={() => fillFilters('pending')}>
                                Pending
                                {filters.pending && <FaCheck className='filter-check' />}
                            </button>
                        </li>
                    }
                    {canEventFeedbackView &&
                        <li>
                            <button className='filter-button' onClick={() => fillFilters('feedback')}>
                                Feedback
                                {filters.feedback && <FaCheck className='filter-check' />}
                            </button>
                        </li>
                    }
                </ul>

                {isInternalUser(userRole) && videostatuses && (
                    <div>
                        <h5 className="category-subtitle">Video Status</h5>
                        <div className='filters-list'>
                            <ul className={classNames('filters-list', { 'two-columns': twoColumns })}>
                                {Object.keys(videostatuses).map((item) => {
                                    return (
                                        <li key={item}>
                                            <button className='filter-button' onClick={() => fillVideostatuseFilters(item)} key={item}>
                                                {getFilterName(item)}
                                                {videostatuses[item] && <FaCheck className='filter-check' />}
                                            </button>
                                        </li>
                                    )
                                })
                                }
                            </ul>
                        </div>
                    </div>
                )}

                <h5 className="category-subtitle">DMS</h5>
                <div className='filters-list'>
                    <ul className='filters-list'>
                        <li>
                            <button className='filter-button' onClick={() => fillFilters('distraction')}>
                                Distraction
                                {filters.distraction && <FaCheck className='filter-check' />}
                            </button>
                        </li>
                        <li>
                            <button className='filter-button' onClick={() => fillFilters('drowsiness')}>
                                Drowsiness/Head Down
                                {filters.drowsiness && <FaCheck className='filter-check' />}
                            </button>
                        </li>
                        <li>
                            <button className='filter-button' onClick={() => fillFilters('phone_use')}>
                                Phone Use/Hand to Head
                                {filters.phone_use && <FaCheck className='filter-check' />}
                            </button>
                        </li>
                        <li>
                            <button className='filter-button' onClick={() => fillFilters('yawning')}>
                                Yawning
                                {filters.yawning && <FaCheck className='filter-check' />}
                            </button>
                        </li>
                        <li>
                            <button className='filter-button' onClick={() => fillFilters('seatbelt')}>
                                Seatbelt
                                {filters.seatbelt && <FaCheck className='filter-check' />}
                            </button>
                        </li>
                        <li>
                            <button className='filter-button' onClick={() => fillFilters('smoking')}>
                                Smoking
                                {filters.smoking && <FaCheck className='filter-check' />}
                            </button>
                        </li>
                    </ul>
                    <div style={{ clear: 'both' }} />
                </div>

                <h5 className="category-subtitle">ADAS</h5>
                <div className='filters-list'>
                    <ul className="filters-list">
                        <li>
                            <button className='filter-button' onClick={() => fillFilters('adas_ldw')}>
                                Lane Departure
                                {filters.adas_ldw && <FaCheck className='filter-check' />}
                            </button>
                        </li>
                        <li>
                            <button className='filter-button' onClick={() => fillFilters('adas_fcw')}>
                                Forward Collision
                                {filters.adas_fcw && <FaCheck className='filter-check' />}
                            </button>
                        </li>
                        <li>
                            <button className='filter-button' onClick={() => fillFilters('adas_hw')}>
                                Headway Warning
                                {filters.adas_hw && <FaCheck className='filter-check' />}
                            </button>
                        </li>
                        <li>
                            <button className='filter-button' onClick={() => fillFilters('adas_pcw')}>
                                Pedestrian Warning
                                {filters.adas_pcw && <FaCheck className='filter-check' />}
                            </button>
                        </li>
                        <li>
                            <button className='filter-button' onClick={() => fillFilters('tailgating')}>
                                Tailgating
                                {filters.tailgating && <FaCheck className='filter-check' />}
                            </button>
                        </li>
                    </ul>
                    <div style={{ clear: 'both' }} />
                </div>

                <h5 className="category-title">Groups</h5>
                <CustomButton
                    variant="link"
                    onClick={selectAllGroups}
                >
                    {`${countSelectedGroups === groups.length ? 'Unselect' : 'Select'} All`}
                </CustomButton>
                <ul className={classNames('filters-list', { 'two-columns': twoColumns })}>
                    {groups.map(({ group_name, id, selected, visible }) => (
                        <li key={id} className={`${!visible && 'hide'}`}>
                            <button className='filter-button' onClick={() => fillGroups(id)} title={group_name}>
                                <span className="groupName">{group_name || '[Unnamed]'}</span>
                                {selected && <FaCheck className='filter-check' />}
                            </button>
                        </li>
                    ))}
                </ul>
                {countVisibleGroups < groups.length
                    && <CustomButton variant="link" onClick={onMoreGroupsClick}>MORE</CustomButton>
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

FilterMenu.propTypes = {
    userRole: PropTypes.string.isRequired,
    filters: PropTypes.objectOf(PropTypes.bool).isRequired,
    videostatuses: PropTypes.objectOf(PropTypes.bool),
    groups: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectAllFilters: PropTypes.func.isRequired,
    fillFilters: PropTypes.func.isRequired,
    fillVideostatuseFilters: PropTypes.func,
    fillGroups: PropTypes.func.isRequired,
    selectAllGroups: PropTypes.func.isRequired,
    onMoreGroupsClick: PropTypes.func.isRequired,
}

export default FilterMenu;
