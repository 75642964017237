// FWSD-2842
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { InfoWindow } from 'react-google-maps'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import _ from 'lodash'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'

import SnapshotChannelNode from '../../../component/smart/modals/SnapshotChannelNode'
import momenttz from '../../../component/helpers/momenttz'
import { apiCall } from '../../../action/RouterActions'

import './MarkerAlertPopup.sass'

momentDurationFormatSetup(moment);

const MarkerAlertPopup = (props) => {

	const { timestamp, eventType, event, snapshots, onCloseAlertWindow, setTripAdvancedPlayerEvent, setTripShowShareEvent, toggleLoader, setEventArchiveRestoring } = props

	const [activeChannel, setActiveChannel] = useState(null)
	const [channelsState, setChannelsState] = useState([])

	useEffect(() => {
		// console.log('componentDidMount MarkerAlertPopup')
		setChannels(snapshots)

		setTimeout(() => {
			$('.gm-style-iw-c').css({
				'border-radius': '8px'
			})
			$('.gm-ui-hover-effect').css({
				'bottom': '225px', 'right': '0px', 'top': '-8px', 'border-bottom-left-radius': '8px', 'background-color': '#fff', 'width': '25px', 'height': '38px'
			})
			$('.gm-ui-hover-effect images').css({
				'width': '25px !important', 'height': '25px !important'
			})
		}, 10)
	}, [])

	useEffect(() => {
		console.log('componentWillReceiveProps MarkerAlertPopup')
		setChannels(snapshots)

		return () => {
			console.log('componentWillUnmount MarkerAlertPopup');
			const { setTripAdvancedPlayerEvent, setTripShowShareEvent, onCloseAlertWindow } = props
			setTripAdvancedPlayerEvent(null)
			setTripShowShareEvent(null)
		}
	}, [snapshots])

	const setChannels = (snapshots) => {
		// console.log('setChannels: ', snapshots)
		const channels = []
		let activeChannel = null

		const re = /\/(\d).jpeg?/i
		snapshots.forEach((item) => {
			const found = item.match(re)
			if (found) {
				channels.push({ number: found[1], src: item })
			}
		})
		activeChannel = channels[0] || null

		setChannelsState(channels);
		setActiveChannel(activeChannel);
	}

	const handleCloseInfoWindow = () => {
		const { device_id, onCloseInfo } = props
		onCloseInfo(device_id)
	}

	const changeChannel = (channelNumber) => {
		console.log('changeChannel: ', channelNumber)

		if (activeChannel.number === channelNumber) {
			return
		}

		const obj = channelsState.find(({ number }) => number === channelNumber)
		if (obj) {
			setActiveChannel(obj)
		}
	}

	const channelsList = () => {
		return channelsState.map((item) => {
			return <SnapshotChannelNode
				key={item.number}
				index={item.number}
				activechannel={activeChannel.number}
				changeChannel={changeChannel}
			/>
		})
	}

	const onFlagEvent = () => {
		const { event, setProps } = props
		const { id, flagged } = event

		toggleLoader(true)
		if (!flagged) {
			apiCall('PUT', '/events/{id}/flagged', { id })
				.then((res, err) => {
					toggleLoader(false)
					setProps({ event: { ...event, flagged: true } })
				})
				.catch((error) => {
					toggleLoader(false)
					console.log('!onFlagEvent put error:', error.response, error)

					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				})
		} else {
			apiCall('DELETE', '/events/{id}/flagged', { id })
				.then((res, err) => {
					toggleLoader(false)
					setProps({ event: { ...event, flagged: false } })
				})
				.catch((error) => {
					toggleLoader(false)
					console.log('!onFlagEvent delete error:', error.response, error)

					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				})
		}
	}

	// console.log('PROPS MarkerAlertPopup: ', props)

	return (
		<InfoWindow
			onCloseClick={onCloseAlertWindow}
			// onCloseClick={handleCloseInfoWindow}
			// zIndex={zIndex}
			zIndex={1000002}
		>
			<div className="markerAlertPopup">
				<div
					className={`imgWrapper ${!activeChannel && 'no-snapshot'}`}
					onClick={() => event.uploading_status && setTripAdvancedPlayerEvent(event)}
				>
					{activeChannel ? <img src={activeChannel.src} alt="" /> : <span className="snapshot-error-message">No Snapshot Available</span>}
					{/* <images src="https://rosco.roscocloud.com/rosco-user-98/1010501744/9609219/1.jpeg?AWSAccessKeyId=AKIAR34ZBXI7AYIBPCS5&Signature=xYDbDaSGxnjyNkup8%2Buq5vFTf2Y%3D&Expires=1612474709" /> */}
				</div>
				<div className="controlsWrapper">
					<div style={{ marginBottom: '15px' }}>
						<span className="eventType">
							{eventType}
						</span>
						<span className="eventTime">
							{momenttz(`${timestamp}+00`).format('h:mm a')}
							{/* 11:40 pm */}
						</span>
					</div>
					<div className="controls">
						<ul className="channelsList">
							{channelsList()}
						</ul>

						<div className="controlButtons">
							{event.archive === 'archived'
								&& (
									<a
										className="button archivedButton"
										title="Restore Event"
										style={{ fontSize: '26px', marginRight: '2px' }}
										onClick={() => setEventArchiveRestoring({ showModal: true, message_type: 'event_restore', event_id: event.event_id })}
									/>
							)}

							{event.archive === 'restoring'
								&& (
									<a
										className="button archivedButton restoring"
										title="Event Restoring"
										style={{ fontSize: '26px', marginRight: '2px' }}
										onClick={() => setEventArchiveRestoring({ showModal: true, message_type: 'event_restoring', event_id: event.event_id, request_ts: event.request_ts })}
									/>
							)}

							{event.uploading_status === 'VIDEO_UPLOADED'
								&& (
									<a
										className="button playButton"
										title="Play Video"
										style={{ fontSize: '26px', marginRight: '2px' }}
										onClick={() => setTripAdvancedPlayerEvent(event)}
									/>
							)}
							{(event.uploading_status !== 'VIDEO_UPLOADED' && event.snapshot_status === 'SNAPSHOT_UPLOADED')
								&& (
									<a
										className="button snapshotButton"
										title="View Snapshot"
										style={{ fontSize: '26px', marginRight: '2px' }}
										onClick={() => setTripAdvancedPlayerEvent(event)}
									/>
							)}
							{props.user.user.roles[0] !== 'sales_demo' &&
								<a
									className="button shareButton"
									title="Share Event"
									onClick={() => setTripShowShareEvent(event)}
								/>
							}

							{/* flaggedButton = <a className={`imageButton flaggedButton ${flagged && "on"}`} title={`${flagged ? "Unflag" : "Flag"} Event`} onClick={() => onFlagEvent(row, 'Table')}></a> */}
							<a
								className={`button flaggedButton ${event.flagged && 'on'}`}
								title={`${event.flagged ? 'Unflag' : 'Flag'} Event`}
								onClick={onFlagEvent}
							/>
						</div>
					</div>
				</div>
			</div>
		</InfoWindow>
	)
}

const mapStateToProps = ({ user }) => ({
	user
});

const mapDispatchToProps = dispatch => ({
	setTripAdvancedPlayerEvent: (state) => {
		dispatch({ type: 'SET_TRIP_ADVANCED_PLAYER_EVENT', payload: state })
	},
	setTripShowShareEvent: (state) => {
		dispatch({ type: 'SET_TRIP_SHOW_SHARE_EVENT', payload: state })
	},
	setEventArchiveRestoring: (state) => {
		dispatch({ type: 'SET_EVENT_ARCHIVE_RESTORING', payload: state });
	},
	toggleLoader: (show) => {
		dispatch({ type: 'TOGGLE_LOADER', payload: show });
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(MarkerAlertPopup);
