import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import classnames from 'classnames';
import Autocomplete from 'react-autocomplete';
import axios from 'axios'
import { toastr } from 'react-redux-toastr';
// import API from 'mg-api-node';
// import GeotabApi from 'mg-api-js';
// import { promisify } from 'util';

import { deviceAddGeotabSerialN } from '../../../action/GeotabActions'
import { getWebfleetVehicles, setWebfleetCard } from '../../../action/GeotabActions'
import { apiCall, apiCallGet } from '../../../action/RouterActions'
import { geotabAuthentication } from '../../helpers/helperGeotab'
import CustomButton from '../../CustomButton';

const ModalProvisionDevice = (props) => {

    const { device, onHide, provisionType, webfleetCredentials, toggleLoader } = props;

    const [state, setState] = useState({
        devices: [],
        autoValue: '',
        data: {
            serial_number: '',
        },
        errMessage: {
            serial_number: '',
        },
    });

    useEffect(() => {
        switch (provisionType) {
            case 'geotab':
                getGeotabData();
                break
            case 'webfleet':
                getWebfleetData();
                break
        }
    }, [])

    const getWebfleetData = () => {
        toggleLoader(true);
        const params = {
            account: webfleetCredentials.account,
            username: webfleetCredentials.username,
            password: encodeURI(webfleetCredentials.password),
        }
        getWebfleetVehicles(params)
            .then((resVeh) => {
                console.log('getWebfleetVehicles resVeh: ', resVeh)
                toggleLoader(false);
                if (resVeh.data.errorMsg) {
                    toastr.error(`Webfleet: ${resVeh.data.errorMsg}`);
                }
                else {
                    toggleLoader(false);
                    const newStates = {
                        devices: resVeh.data.map(({ objectuid, objectname }) => { return { 'serialNumber': objectuid, 'name': objectname } }),
                        autoValue: '',
                    }

                    if (device.webfleet && device.webfleet.serial_number) {
                        const objDevice = _.find(resVeh.data, ({ objectuid }) => {
                            return objectuid === device.webfleet.serial_number;
                        });

                        if (objDevice) {
                            newStates.autoValue = objDevice.objectname || objDevice.objectuid
                        }
                    }

                    setState(prevState => ({ ...prevState, ...newStates }));
                }
            })
            .catch((error) => {
                toggleLoader(false);
                console.log('getWebfleetData error: ', error)
                let errDescription = 'An unexpected error occurred. Please try again later'
                if (error.response.data.response.error) {
                    errDescription = error.response.data.response.error
                }
                toastr.error(errDescription)
            });
    }

    const getGeotabData = useCallback(async () => {
        const { geotab_company, getCompanyGeotabData } = props;
        toggleLoader(true);

        // FWSD-4475
        let data = {
            company_id: device.company_id,
            device_id: device.device_id,
            serial_number: (device.geotab && device.geotab.serial_number) ? device.geotab.serial_number : '',
            password: geotab_company.password,
            path: geotab_company.path,
            fleet_token: geotab_company.token,
            database: geotab_company.database,
            email: geotab_company.email,
            sessionId: geotab_company.session_id,
        }

        const resultAuth = await geotabAuthentication(data, getCompanyGeotabData);
        if (resultAuth.error) {
            toggleLoader(false);
            toastr.error('An unexpected error occurred. Please try again later');
            return;
        }

        const { geotabApi, geotabData } = resultAuth;
        data = { ...data, ...geotabData }

        // gets list of geotab devices
        geotabApi.call(
            'Get',
            {
                typeName: 'Device',
                credentials: {
                    database: data.database,
                    userName: data.email,
                    sessionId: data.sessionId,
                },
            },
            resGet => {
                // console.log('!GET Device res:', resGet);
                toggleLoader(false);

                // if (err) {
                //     console.log('!GET Device error:', err);
                //     toastr.error('An unexpected error occurred. Please try again later');
                //     return;
                // }

                if (!resGet || _.isEmpty(resGet)) {
                    toastr.error('The geotab fleet does not have assigned devices');
                    return;
                }

                setState(prevState => ({ ...prevState, data, devices: resGet, autoValue: '' }));

                if (device.geotab && device.geotab.serial_number) {
                    const objDevice = _.find(resGet, (item) => {
                        return item.serialNumber === device.geotab.serial_number;
                    });

                    if (objDevice) {
                        setState(prevState => ({ ...prevState, autoValue: objDevice.name || objDevice.serialNumber }));
                    }
                }
            },
            errGet => {
                toggleLoader(false);
                console.log('!GET Device error: ', errGet)
                toastr.error('An unexpected error occurred. Please try again later');
            }
        )
    }, [])

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let dataUpdate = state.data;
        dataUpdate[name] = value;
        setState(prevState => ({ ...prevState, data: dataUpdate }));
    }

    const checkForm = (data) => {
        let error = false;
        let errMessageUpdate = state.errMessage;
        for (let i in data) {
            if ((data[i] == '') && (i !== 'serial_number')) {
                error = true;
                errMessageUpdate[i] = 'Enter your ' + i + '!';
            }
            else if (errMessageUpdate[i]) {
                errMessageUpdate[i] = null;
            }
        }

        if (provisionType === 'geotab' && ((!data.serial_number && !device.geotab.serial_number) || (device.geotab.serial_number && !data.serial_number && state.autoValue))) {
            error = true;
            errMessageUpdate.serial_number = 'Select a vehicle from list';
        }
        else if (errMessageUpdate.serial_number) {
            errMessageUpdate.serial_number = null;
        }

        setState(prevState => ({ ...prevState, errMessage: errMessageUpdate }));
        return error;
    }

    const onSaveClick = (e) => {
        switch (provisionType) {
            case 'geotab':
                saveGeotabPairing()
                break
            case 'webfleet':
                saveWebfleetPairing()
                break
        }
    }

    const saveWebfleetPairing = (e) => {
        const params = {
            device_id: device.device_id,
            serial_number: state.data.serial_number,
        }

        const error = checkForm(params)
        if (!error) {
            apiCall('PUT', '/external_service/webfleet/pair', params)
                .then((res, err) => {
                    console.log('saveWebfleetPairing res: ', res, err)
                    toggleLoader(false);
                    toastr.success('', 'Serial number saved');

                    // FWSD-5295 - Sends webfleet setExternalObjectData request to update Rosco Live webfleet card
                    if (state.data.serial_number) sendWebfleetCard()

                    props.updateDeviceData();
                })
                .catch((error) => {
                    console.log('saveWebfleetPairing error: ', error.response, error)
                    toggleLoader(false);
                    const { status, data } = error.response
                    if (status >= 400 && status < 500) {
                        toastr.error(data.response.error);
                    } else {
                        toastr.error('An unexpected error occurred. Please try again later');
                    }
                })
        }
    }

    const sendWebfleetCard = () => {
        const params = {
            account: webfleetCredentials.account,
            username: webfleetCredentials.username,
            password: encodeURI(webfleetCredentials.password),
            access_hash: webfleetCredentials.access_hash,
            vehicle_name: device.vehicle_name,
            online: device.ads_online,
            webfleet_sn: state.data.serial_number,
        }
        setWebfleetCard(params)
            .then()
            .catch(error => {
                console.log('sendWebfleetCard error:', error.response, error)
                toastr.error('An unexpected error of Rosco webfleet card updating.')
            })
    }

    const saveGeotabPairing = (e) => {
        const error = checkForm(state.data);
        if (!error) {
            toggleLoader(true);

            deviceAddGeotabSerialN(state.data).then((res, err) => {
                toggleLoader(false);
                console.log('!deviceAddGeotabSerialN res', res);

                toastr.success('', 'Serial number saved');

                props.updateDeviceData();
            })
                .catch((error) => {
                    toggleLoader(false);
                    console.log('!deviceAddGeotabSerialN error', error);

                    let errDescription = 'An unexpected error occurred. Please try again later'
                    if (error.response.data.response.error) {
                        errDescription = error.response.data.response.error
                    }
                    toastr.error(errDescription)
                });
        }
    }

    const getModalTitle = () => {
        switch (provisionType) {
            case 'geotab':
                return 'Pair GO Device'
            case 'webfleet':
                return 'Pair Webfleet Device'
        }
    }

    const { serial_number } = state.errMessage;

    const displayItemName = (item) => {
        return item.name || item.serialNumber;
    }

    const getItemValue = (item) => {
        return item.name || item.serialNumber;
    }

    const matchStateToTerm = (item, value) => {
        if (item.name)
            return item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
        else
            return item.serialNumber.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    }

    return (
        <Modal
            size='lg'
            show={true}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-lg">{getModalTitle()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>
                    {`Select ${provisionType.charAt(0).toUpperCase() + provisionType.slice(1)} device associated`} with vehicle <b>"{device.vehicle_name || device.device_id}"</b>
                </h4>
                <div className={classnames('form-group', { 'has-error': serial_number })} style={{ paddingTop: '23px', paddingBottom: '5px' }}>
                    <label>{provisionType.charAt(0).toUpperCase() + provisionType.slice(1)} Device(s):</label>
                    <Autocomplete
                        autoHighlight={true}
                        value={state.autoValue}
                        inputProps={{ id: 'states-autocomplete' }}
                        wrapperProps={{ className: 'search-autocomplete' }}
                        wrapperStyle={{ position: 'relative', zIndex: 1 }}
                        getItemValue={getItemValue}
                        shouldItemRender={matchStateToTerm}
                        menuStyle={{
                            borderRadius: '3px',
                            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                            background: 'rgba(255, 255, 255, 0.9)',
                            padding: '2px 0',
                            fontSize: '90%',
                            position: 'absolute',
                            overflow: 'auto',
                            maxHeight: '200px',
                            left: '0',
                            top: 'auto'
                        }}
                        // sortItems={sortStates}
                        onChange={(e, value) => {
                            console.log('onChange', value);
                            let dataUpdate = state.data;
                            dataUpdate.serial_number = '';
                            setState(prevState => ({ ...prevState, autoValue: value, data: dataUpdate }));
                        }}
                        // onSelect={handleInputChange}
                        onSelect={(value, item) => {
                            console.log('onSelect', value, item);
                            let dataUpdate = state.data;
                            dataUpdate.serial_number = item.serialNumber;
                            setState(prevState => ({ ...prevState, autoValue: value, data: dataUpdate }));
                        }}
                        renderMenu={(items, value, style) => {
                            return <div className="items-list" style={{ ...style }} children={items} />
                        }}
                        renderItem={(item, isHighlighted) => (
                            <div
                                className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                key={item.serialNumber}
                            >
                                {/* ` */}
                                {displayItemName(item)}
                            </div>
                        )}
                        renderInput={(props) => (
                            <input {...props} placeholder="Search Vehicle" className="form-control" />
                        )}
                        items={state.devices}
                    />
                    {/* <input type="text" placeholder="Serial number..." ref="serial_number" name="serial_number" className="form-control" value={state.data.serial_number} onChange={handleInputChange} /> */}
                    {serial_number && <span className="help-block">{serial_number}</span>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton variant='primary' size='sm' onClick={onSaveClick}>Save</CustomButton>
            </Modal.Footer>
        </Modal>
    )
}

ModalProvisionDevice.propTypes = {
    device: PropTypes.objectOf(PropTypes.any).isRequired,
    webfleetCredentials: PropTypes.objectOf(PropTypes.any).isRequired,
    provisionType: PropTypes.string.isRequired,
    onHide: PropTypes.func.isRequired,
}

export default connect(
    null,
    dispatch => ({
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        }
    })
)(ModalProvisionDevice);
