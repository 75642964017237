// FWSD-7159
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import moment from 'moment'
import ModalDelete from '../../smart/modals/ModalDelete'
import { apiCallGet, apiCall } from '../../../action/RouterActions'

const ModalLayout = (props) => {
	const { user, eventArchiveRestoring, setEventArchiveRestoring, toggleLoader } = props
	const { message_type, device_id, event_id, request_ts, callback } = eventArchiveRestoring

	const restoreEvent = () => {
		toggleLoader(true)
		apiCall('POST', '/event/{event_id}/restore', {event_id})
			.then(res => {
				toggleLoader(false)
				setEventArchiveRestoring({ showModal: false })

				if (typeof callback === 'function') {
					return callback()
				}

				toastr.success('Request sent successfully')
			})
			.catch(error => {
				toggleLoader(false)
				console.log('!restoreEvent error: ', error.response, error)
				toastr.error('Unable to sent request. Please try again later.');
			})
	}

	const addSubscriber = () => {
		toggleLoader(true)
		apiCall('POST', '/event/{event_id}/restore/subscribers', {event_id, user_ids: user.id})
			.then(res => {
				toggleLoader(false)
				setEventArchiveRestoring({ showModal: false })
				toastr.success('Request sent successfully')
			})
			.catch(error => {
				toggleLoader(false)
				console.log('!restoreEvent error: ', error.response, error)
				toastr.error('Unable to sent request. Please try again later.');
			})
	}

	const closeButtonHandle = () => {
		setEventArchiveRestoring({ showModal: false })
	}

	const closeModal = () => {
		switch (message_type) {
			case 'event_restore':
				return setEventArchiveRestoring({ showModal: false })
			case 'event_restoring':
				return addSubscriber()
			default:
				break;
		}
	}

	const deleteModal = () => {
		switch (message_type) {
			case 'event_restore':
				return restoreEvent()
			case 'event_restoring':
				return setEventArchiveRestoring({ showModal: false })
			default:
				break;
		}
	}

	const setContent = () => {
		let content = {}

		switch (message_type) {
			case 'event_restore':
				content = {
					title: "Retrieve Archived Event?",
					content: "This event video has been automatically archived and must first be retrieved before it is available for viewing. \n\n To restore the event, click <b>Restore</b> and the video will be available within approximately 3 - 5 hours after the request is made. When the video is available, a notification will be sent to your email as well as appear in your Notification Bell. \n\n <i>Why was this event archived?</i> \n In order to reduce costs to our customers, as a standard practice events after 180 days from date of upload will be archived. This simply means that the event video is stored in a different way on our servers and as a result needs some time to be retrieved. This event will then be re-archived 180 days after it has been retrieved. Don’t worry! Your event video will be ready shortly.",
					showClose: true,
					deleteBtnName: "Restore",
					closeBtnName: "Back",
				}
				break;
			case 'event_restoring':
				content = {
					title: "Event Archive Retrieval In Progress",
					content: `A request has been made on <b>${moment(request_ts).format('MM/DD/YYYY hh:mm:ss A')}</b> to restore this event from being archived and is currently still in the process of being retrieved. This event will be retrieved in approximately 3 - 5 hours after request where afterwards it will be available for viewing. To be notified when this event is restored, click the <b>Notify Me</b> button and an email will be sent to your inbox to notify you when it is available for viewing.`,
					showClose: true,
					deleteBtnName: "OK",
					closeBtnName: "Notify Me",
				}
				break;			
			default:
				break;
		}

		return content

	}

	return (
		<>
		{eventArchiveRestoring.showModal && 
			<ModalDelete
				{...setContent()}
				styleView="primary"
				closeButtonHandle={closeButtonHandle}
				closeModal={closeModal}
				deleteModal={deleteModal}
				// deleteModal={() => eval(eventArchiveRestoring.deleteModal + '()')}
				// closeButtonHandle={() => eval(eventArchiveRestoring.closeButtonHandle + '()')}
				// closeModal={closeEventArchiveRestoring}
				// title=eventArchiveRestoring.title
				// content={`Camera "${notification.globalPopupData.vehicle_name}" is now awake and available for live streaming. \n\n Open your notifications and click "Open Live Stream" in the notification to open the live streaming window. \n\n Click "OK" to dismiss`}
				// styleView="primary"
				// backdropClassName="upper"
				// showClose
				// deleteBtnName="Open Notifications"
				// closeBtnName="OK"
				// closeModal={() => toggleGlobalPopup(false)}
				// deleteModal={() => setEventArchiveRestoring({ showModal: false })}
			/>
		}
		</>
	)
}

const mapStateToProps = ({ user, eventsData }) => ({	
	user: user.user,
	eventArchiveRestoring: eventsData.eventArchiveRestoring,
})

const mapDispatchToProps = dispatch => ({
	toggleLoader: (show) => {
		dispatch({ type: 'TOGGLE_LOADER', payload: show });
	},
	setEventArchiveRestoring: (state) => {
		dispatch({ type: 'SET_EVENT_ARCHIVE_RESTORING', payload: state });
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalLayout);
