import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button } from 'react-bootstrap'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

import './MarkerInfoPopup.sass'

momentDurationFormatSetup(moment);

const MarkerHistoryPopup = (props) => {
	const {
		marker,
		mapUI,
		speed_unit,
		onClickAddress,
	} = props;

	const { lat, lng } = marker?.position,
		location_lat = marker.position?.lat,
		location_lng = marker.position?.lng,
		position = { lat: lat || location_lat, lng: lng || location_lng },
		speed = marker.infoData?.speed || 0,
		address = marker.infoData?.address,
		device_id = marker?.device_id;

	const [showAddress, setShowAddress] = useState(false);
	const elm = mapUI.getBubbles().filter(bubble => bubble.getElement().className.includes("infoTripBubble"));
	const bubble = elm.length ? elm[0] : null;

	useEffect(() => {
		const bubbleElm = new H.ui.InfoBubble(position, { content: content });
		bubbleElm.addClass("infoTripBubble");
		mapUI.addBubble(bubbleElm);

		return () => {
			mapUI.removeBubble(bubbleElm);
		}
	}, []);

	useEffect(() => {
		if (bubble) {
			setShowAddress(false);
			bubble.setPosition(position);
			bubble.setContent(content);
		}
	}, [lat, lng]);

	useEffect(() => {
		if (bubble) {
			bubble.setContent(content)
		}
	}, [showAddress, address]);

	globalThis.g_handleClickAddress = () => {
		if (!showAddress) {
			onClickAddress(device_id)
		}
		setShowAddress(!showAddress)
	}

	const vehicleSpeed = () => {
		let speedRes = speed * 1
		if (speed_unit !== 'mph') {
			speedRes *= 1.609344
		}

		return speedRes.toFixed(0)
	}

	const content = `<div class="markerInfoPopup markerHistoryPopup">
		<div class="headerWrapper">
			<div class="header">
				<div class="row" style="--bs-gutter-x: 0; --bs-gutter-y: 0">
					<div class="menu col-md-12 col-sm-12 col-12"><div class="col-md-12 col-sm-12 col-12" style="display: inline-flex; align-items: center; justify-content: center; margin-bottom: 10px; font: 400 11px Roboto, Arial, sans-serif;">
						<div class="menuItem speed"></div>
						<div>
							<div style="color: rgb(255, 255, 255); font-size: 22px; font-weight: bold">${vehicleSpeed()}</div>
							<div style="color: rgb(255, 255, 255); font-size: 14px">${speed_unit.toUpperCase()}</div>
						</div>
					</div>
					</div>
				</div>
				<div class="addressWrapper"><div class="row" style="min-height: 60px; --bs-gutter-x: 0; --bs-gutter-y: 0; padding-right: 15px">
					<div class="menu col-md-12 col-sm-12 col-12">
						<div class="col-md-${showAddress ? 3 : 12} col-sm-3 col-3" style="padding: 0px 15px; width: 15%">
							<button class="btn-address" type="button" title="Lookup Address" tabindex="0" onclick="g_handleClickAddress()">
								<svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16"><path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z">
								</path>
								</svg>
							</button>
						</div>
						<div class="col-md-7 col-sm-9 col-9" style="font-size: 14px; line-height: 18px; width: max-content; max-width: 200px">
							${showAddress ? address : ''}
						</div>
					</div>
				</div>
				</div>
			</div>
		</div>
	</div>`;

	return (<></>)
}

export default MarkerHistoryPopup;
