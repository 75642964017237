// FWSD-6457
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import _ from 'lodash'

import CustomButton from '../../component/CustomButton'

import { errorStatusCodes } from '../../component/helpers/helperVehicle'

import './CameraHealth.sass'

const ModalSilencedErrors = (props) => {
	// console.log('PROPS ModalSilencedErrors: ', props)
	const { selectedDevice: { device_id, vehicle_name, silenced }, setCameraHealthAlert, isInternalUser, onHide } = props
	
	// const errorStatus = errorStatusCodes.find(({ code })=> code.split(',').includes(err_code)) || {}
	// const { code, name, type, definitiona, resolutions } = errorStatus
	// const resolutionsList = resolutions.trim().split('.').slice(0,-1)

	// // flag of clicking on the Clear button of acknowledged critical alert
	// const [clearCriticalConfirmation, setClearCriticalConfirmation] = useState(false)
	const [availableErrors, setAvailableErrors] = useState([])
	const [newSilenced, setNewSilenced] = useState([])

	const hasSelectedAvailable = availableErrors.filter(({selected}) => selected === true).length
	const hasSelectedSilenced = newSilenced.filter(({selected}) => selected === true).length
	const isSilencedUpdated = _.difference(_.map(newSilenced.filter(({active}) => active), 'code'), silenced).length // were added alerts
							|| _.difference(silenced, _.map(newSilenced.filter(({active}) => active), 'code')).length // were removed alerts

	// console.log('STATE availableErrors: ', availableErrors)
	// console.log('STATE newSilenced: ', newSilenced)

	useEffect(() => {
		const availableErrorsFull = []
		errorStatusCodes.forEach(({code, type}) => {
			//For internal users we have to display all of error BUT for other users just SD20 
			if (!isInternalUser && code !== 'SD20') {
				return
			}

			code.split(',').forEach((item) => {
				availableErrorsFull.push({ code: item, type, active: true, selected: false })
			})
		})
		// sets available errors list
		let availableArr = [...availableErrorsFull]
		if (silenced) {
			availableArr = availableArr.map((alert) => {
				if (silenced.includes(alert.code)) {
					return { ...alert, active: false }
				}
				return {...alert}
			})
		}
		setAvailableErrors(availableArr)

		// looks for types of the device silenced alerts
		let silencedArr = availableErrorsFull.map((alert) => ({...alert, active: false}))
		if (silenced) {
			silencedArr = silencedArr.map((alert) => {
				if (silenced.includes(alert.code)) {
					return { ...alert, active: true }
				}
				return {...alert}
			})
		}
		setNewSilenced(silencedArr)
	}, [])

	const getAvailableErros = () => {
		const setSelectedAlert = (codeArg) => {
			const arr = availableErrors.map((alert) => {
				return {...alert, selected: alert.code === codeArg ? !alert.selected : alert.selected}
			})
			setAvailableErrors(arr)
		}
		
		const res = availableErrors.filter(({active}) => active === true).map(({code, type, selected}) => {
			return (
				<CustomButton
					key={code}
					variant="primary"
					style={{ marginRight: '5px', marginBottom: '5px' }}
					size="hs"
					prefix={`${type}${selected ? ' selected' : ''}`}
					onClick={() => setSelectedAlert(code)}
				>
					{code}
				</CustomButton>
			)
		})

		return res
	}

	const getSilencedErros = () => {
		const setSelectedAlert = (codeArg) => {
			const arr = newSilenced.map((alert) => {
				return {...alert, selected: alert.code === codeArg ? !alert.selected : alert.selected}
			})
			setNewSilenced(arr)
		}

		const res = newSilenced.filter(({active}) => active === true).map(({code, type, selected}) => {
			return (
				<CustomButton
					key={code}
					variant="primary"
					style={{ marginRight: '5px', marginBottom: '5px' }}
					size="hs"
					prefix={`${type}${selected ? ' selected' : ''}`}
					onClick={() => setSelectedAlert(code)}
				>
					{code}
				</CustomButton>
			)
		})

		return res
	}

	const onAddClick = () =>{
		let arrAvailable = []
		let arrSilenced = [...newSilenced]
		arrAvailable = availableErrors.map((alert) => {
			if (alert.selected) {
				arrSilenced = arrSilenced.map((item) => {
					if (item.code === alert.code) {
						return {...item, active: true, selected: false}
					}
					return {...item}
				})
					
				return {...alert, active: false, selected: false}
			}
			return {...alert}
		})
		setAvailableErrors(arrAvailable)
		setNewSilenced(arrSilenced)
	}

	const onRemoveClick = () => {
		let arrAvailable = [...availableErrors]
		let arrSilenced = []
		arrSilenced = newSilenced.map((alert) => {
			if (alert.selected) {
				arrAvailable = arrAvailable.map((item) => {
					if (item.code === alert.code) {
						return {...item, active: true, selected: false}
					}
					return {...item}
				})

				return {...alert, active: false, selected: false}
			}
			return {...alert}
		})
		setAvailableErrors(arrAvailable)
		setNewSilenced(arrSilenced)
	}

	const onSaveClick = () => {
		const silencedCodes = []
		newSilenced.forEach((item) => {
			if (item.active) {
				silencedCodes.push(item.code)
			}
		})
		
		const params = {
			device_id,
			silence_err_notifs: silencedCodes.length ? silencedCodes.join(',') : '[]'
		}
		setCameraHealthAlert(params)
		// debugger
		// toggleLoader(true);
		// apiCall('POST', '/devices/{device_id}', params)
		// 	.then(res => {
		// 		toggleLoader(false)
		// 		toastr.success('', 'Changes were successfully saved')
		// 		// const obj = _.find(vehicles, ['device_id', device_id])
		// 		// if (obj) {
		// 		// 	obj.active = +value
		// 		// }
		// 		// setVehicles(vehicles);
		// 	})
		// 	.catch((error) => {
		// 		toggleLoader(false)
		// 		console.log('!updateDevice error: ', error.response, error)
		// 		toastr.error('Unable to save silence for vehicle. Please try again later.');
		// 	})


		// url = `${API_URL}/devices/${data.deviceId}`;

		// const params = {
		// 	data: {
		// 		silence_err_notifs: newSilenced ? newSilenced.join(',') : ''
		// 	},
		// 	deviceId: device_id
		// }

		// updateDevice(params, user.roles[0], company_name, (err, res) => {
		// 	if (err) {
		// 		console.log('!updateDevice sendForm error', err);
		// 		toastr.error('An unexpected error occurred. Please try again later');
		// 	} else {
		// 		toastr.success('', 'Changes were successfully saved')

		// 		const obj = _.find(vehicles, ['device_id', device_id])
		// 		if (obj) {
		// 			obj.active = +value
		// 		}
		// 		setVehicles(vehicles);
		// 	}
		// })
	}

	return (
		<Modal
			show
			onHide={onHide}
			dialogClassName="custom-request-modal modal-error-status"
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{vehicle_name} - Silenced Errors
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="mb-20">
					Errors added to the <b>Silenced Errors</b> list will be automatically acknowledged for this camera and no notifications will be sent. Remove errors from the <b>Silenced Errors</b> list to resume notifications for that error.
				</div>
							
				<div><b>Available Errors</b> select error(s) and click Add to move to Silenced Errors</div>
				<div className="alerts-list">
					{getAvailableErros()}
				</div>
				<div style={{ textAlign: 'right' }}>
					<CustomButton
						variant="primary"
						size="sm"
						style={{ marginTop: '10px', marginBottom: '25px' }}
						disabled={!hasSelectedAvailable}
						prefix={`${!hasSelectedAvailable ? 'custom-disabled' : ''}`}
						onClick={onAddClick}
					>
						Add
					</CustomButton>
				</div>

				<div><b>Silenced Errors</b> select error(s) and click Remove to move to Available Errors</div>
				<div className="alerts-list">
					{getSilencedErros()}
				</div>
				<div style={{ textAlign: 'right' }}>
					<CustomButton
						variant="primary"
						size="sm"
						style={{ marginTop: '10px', marginBottom: '10px' }}
						disabled={!hasSelectedSilenced}
						prefix={`${!hasSelectedSilenced ? 'custom-disabled' : ''}`}
						onClick={onRemoveClick}
					>
						Remove
					</CustomButton>
				</div>
			</Modal.Body>
			<Modal.Footer bsPrefix="default-modal-footer modal-footer">
				<CustomButton
					variant='primary-outline'
					onClick={onHide}
				>
					Close
				</CustomButton>
				<CustomButton
					variant='primary'
					disabled={!isSilencedUpdated}
					prefix={`${!isSilencedUpdated ? 'custom-disabled' : ''}`}
					onClick={onSaveClick}
				>
					Save
				</CustomButton>
			</Modal.Footer>
		</Modal>
	)
}

ModalSilencedErrors.propTypes = {
	selectedDevice: PropTypes.objectOf(PropTypes.any).isRequired,
	setCameraHealthAlert: PropTypes.func.isRequired,
	onHide: PropTypes.func.isRequired,
}

export default ModalSilencedErrors
