import React from 'react';
import { Line } from 'react-chartjs-2';
import _ from 'lodash'
import PropTypes from 'prop-types';

const SpeedGraph = (props) => {

	const { speedData, speed, timestamp, speed_unit } = props;

	const optionsLine = {
		elements: {
			lines: {
				borderWidth: 4,
				borderColor: 'rgb(255, 255, 0)',
			},
		},
		scales: {
			xAxes: [{
				// type: 'linear',
				// position: 'bottom',
				display: true,
				gridLines: {
					// color: "#464f56",
					lineWidth: 0,
					showTickMarks: false,
					drawBorder: true,
				},
				ticks: {
					beginAtZero: true,
				},
				borderWidth: 4
			}]
		},
		// scaleStartValue: 0,
		// maintainAspectRatio: true,

		title: {
			display: false,
		},
		legend: {
			display: false,
		},
		maintainAspectRatio: false,
		// layout: chart_padding,
		responsive: true,
		showXLabels: 5
	}

	const data = {
		labels: ['', '', '', '', '', '', '', '', '', ''],
		datasets: [
			{
				fill: false,
				pointRadius: 0,
				backgroundColor: 'blue',
				borderColor: 'blue',
				borderWidth: 1.5,
				data: speedData.filter(elm => elm.t <= timestamp).slice(-55).map(item => (speed_unit === 'KPH') ? item.speed : Math.round(item.speed * .62))
			}]
	}

	if (data.datasets[0].data[data.datasets[0].data.length - 1] !== speed) data.datasets[0].data.push(speed);

	return (
		<div className="speed-data">
			<div id="captureSpeed" style={{ width: '285px', height: '180px', position: 'absolute', bottom: '-100%', paddingBottom: "20px" }}>
				<div style={{ color: 'pink', fontSize: `16px`, fontWeight: 'bold', textAlign: 'center' }}>Speed / Time</div>
				<Line
					data={data}
					options={optionsLine}
				/>
				<div className="acceleration-values">
					<div style={{ textAlign: 'center', fontWeight: 'bold', padding: 0, color: 'blue' }}>{`SPEED = ${speed + " " + speed_unit}`}</div>
				</div>
			</div>
		</div>
	)
}

SpeedGraph.propTypes = {
	speedData: PropTypes.arrayOf(PropTypes.any).isRequired,
	timestamp: PropTypes.number.isRequired
}

export default SpeedGraph;
