import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'
import { checkEmail, formatName } from '../../component/helpers/tablesFuncHelpers'
import moment from 'moment'
import Datetime from '@iftek/react-datetime'
import { completeCoachingSesion, createCoachingSession, deleteCoachingSession } from '../../action/DriverActions'
import { getPreferredSnapshot } from '../../component/helpers/helperEvents'
import { getEventSnapshots } from '../../action/AlertActions'
import classNames from 'classnames'
import CustomButton from '../../component/CustomButton';
import Toggle from 'react-toggle'

const ModalCreateCoachEvent = (props) => {

    const { coachingItem, user, company, type, onHide } = props;

    const [deadlineDate, setDeadlineDate] = useState(moment(coachingItem.due_date) || moment().add(1, 'week'));
    const [coachEmail, setCoachEmail] = useState(coachingItem.coach_email || '');
    const [coachEmailErr, setCoachEmailErr] = useState('');
    const [driverEmail, setDriverEmail] = useState(coachingItem.driver_email || '');
    const [note, setNote] = useState(coachingItem.note || '');
    const [snapshot_url, setSnapshot_url] = useState(coachingItem.type && getPreferredSnapshot(coachingItem));
    const [sendDriverEmail, setSendDriverEmail] = useState(true);

    useEffect(() => {
        if (coachingItem.coaching_type === 'event' && coachingItem.due_date) {
            const params = {
                user_role: user.roles[0],
                partner_name: company.partner,
                company_name: company.name,
                event_id: coachingItem.action_item,
            }
            props.toggleLoaderModal(true);
            getEventSnapshots(params)
                .then(res => {
                    props.toggleLoaderModal(false);
                    setSnapshot_url(res.data.response.snapshots_urls[0]);
                })
                .catch(err => {
                    props.toggleLoaderModal(false);
                    console.log(err)
                })
        }
    }, []);

    const checkForm = () => {
        if (!checkEmail(coachEmail)) {
            setCoachEmailErr('Please enter a valid email address');
            return true
        }
        return false
    };

    const createCoachEvent = () => {
        if (checkForm()) {
            return
        }
        const { id, driver_id, driver_score } = coachingItem;
        const data = {
            driver_id: type === 'score' ? id : driver_id,
            driver_email: sendDriverEmail ? driverEmail : '',
            coach_email: coachEmail,
            coaching_type: type,
            action_item: type === 'score' ? driver_score.toString() : coachingItem.id,
            note: note,
            due_date: moment(deadlineDate).format('YYYYMMDD')
        }
        props.toggleLoaderModal(true);
        createCoachingSession(data)
            .then(res => {
                props.toggleLoaderModal(false);
                toastr.success('Successfully created Coaching Session.')
                onHide();
            })
            .catch(err => {
                console.log(err)
                props.toggleLoaderModal(false);
                toastr.error("There was an issue creating this Coaching session. Please try again later.")
            })
    };

    const markComplete = () => {
        props.toggleLoaderModal(true);
        completeCoachingSesion(coachingItem.id)
            .then(res => {
                props.toggleLoaderModal(false);
                toastr.success('Successfully completed Coaching Session.')
                props.updateCoachingSessions();
            })
            .catch(err => {
                props.toggleLoaderModal(false);
                toastr.error('Unable to complete Coaching Session. Please try again later.')
                props.onHide();
            })
    };

    const deleteSession = () => {
        props.toggleLoaderModal(true);
        deleteCoachingSession(coachingItem.id)
            .then(res => {
                props.toggleLoaderModal(false);
                toastr.success('Successfully cancelled Coaching Session.')
                props.updateCoachingSessions();
            })
            .catch(err => {
                props.toggleLoaderModal(false);
                toastr.error('Unable to cancel Coaching Session. Please try again later.')
                props.onHide();
            })
    };

    const modalTitle = type === 'score' ?
        `Coach Score: ${formatName(null, coachingItem)}` :
        `Coach Event: ${coachingItem.driver_name || formatName(null, coachingItem)}`;
    const isComplete = coachingItem.status === "complete";
    const isNewSession = !coachingItem.due_date;
    const scoreLabel = coachingItem.driver_score || coachingItem.action_item || 0

    return (
        <Modal
            show
            onHide={onHide}
            className="modal-lg-size"
        >
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="coaching-driver-modal modal-text">
                    <div className='flex-wrapper'>
                        <label>Coaching Event Deadline: </label>
                        <Datetime
                            timeFormat={false}
                            closeOnSelect
                            inputProps={{ readOnly: true }}
                            value={deadlineDate.format('M/D/YY')}
                            isValidDate={date => moment(date).isAfter(moment())}
                            onChange={date => setDeadlineDate(date)}
                        />
                    </div>

                    <div className={classNames('input-wrapper', { 'has-error': coachEmailErr })}>
                        <label>Coach Email: </label>
                        <input
                            className='vehicle-search'
                            placeholder='Email...'
                            readOnly={!isNewSession}
                            onChange={(e) => {
                                setCoachEmail(e.target.value);
                                setCoachEmailErr('');
                            }}
                            value={coachEmail}
                        />
                        {coachEmailErr && <div className="error-label">{coachEmailErr}</div>}
                    </div>

                    {!coachingItem.driver_email ?
                        <div className='input-wrapper'>
                            <label>Driver Email (optional): </label>
                            <input
                                className='vehicle-search'
                                placeholder='Email...'
                                readOnly={!isNewSession}
                                onChange={(e) => setDriverEmail(e.target.value)}
                                value={driverEmail}
                            />
                        </div> :
                        <div className='toggle-wrapper'>
                            <label>Send Email to Driver</label>
                            <Toggle
                                checked={sendDriverEmail}
                                onChange={(e) => setSendDriverEmail(e.target.checked)}
                            />
                        </div>
                    }

                    {type === 'score' &&
                        <label className='driver-score-label'>
                            Driver Score: <span className={scoreLabel > 100 ? 'error-label' : ''}>{Math.round(scoreLabel)}</span>
                        </label>
                    }
                    {(type === 'event' && snapshot_url) &&
                        <img src={snapshot_url} className='modal-image' />
                    }

                    {isNewSession &&
                        <textarea
                            className='modal-textarea'
                            placeholder='Leave a note...'
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                {!isNewSession && !isComplete &&
                    <div>
                        <CustomButton variant="primary-outline" onClick={onHide}>OK</CustomButton>
                        <CustomButton variant="primary" onClick={markComplete}>Mark as Complete</CustomButton>
                        <CustomButton variant="delete" onClick={deleteSession}>Cancel</CustomButton>
                    </div>
                }
                {isComplete &&
                    <CustomButton variant="primary-outline" onClick={onHide}>OK</CustomButton>
                }
                {isNewSession &&
                    <CustomButton variant="primary" onClick={createCoachEvent}>Create Coaching Event</CustomButton>
                }
            </Modal.Footer>
            {props.loaderModal}
        </Modal>
    )
};

ModalCreateCoachEvent.propTypes = {
    onHide: PropTypes.func.isRequired
};

export default connect(
    state => ({
        user: state.user.user,
        company: state.company.company,
        loaderModal: state.loader.loaderModal
    }),
    dispatch => ({
        toggleLoaderModal: (show) => {
            dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
        }
    })
)(ModalCreateCoachEvent);
