import React, { useState, useEffect, useRef } from 'react'
import { browserHistory } from 'react-router'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import moment from 'moment'
import { isMobileOnly } from 'react-device-detect'
import classnames from 'classnames'
import _ from 'lodash'
import momentDurationFormatSetup from 'moment-duration-format'
import PropTypes from 'prop-types';

import EventClipsDateRange from './EventClipsDateRange'
import EventClipsSearchType from './EventClipsSearchType'
// import EventClipsEventsFilters from './EventClipsEventsFilters'
import EventClipsTable from './EventClipsTable'
import EventClipsGrid from './EventClipsGrid'
import EventViewSnapshotModal from '../modals/EventViewSnapshotModal'
import CustomVideoRequest from '../modals/CustomVideoRequest'
import EventNoteModal from '../modals/EventNoteModal'
import ModalDelete from '../modals/ModalDelete'
import TrackTraceAlertSearch from './TrackTraceAlertSearch'
import AdvancedPlayer from '../../AdvancedPlayer'
import EventShareModal from '../modals/EventShareModal'
import momenttz from '../../helpers/momenttz'
import { sendGA } from '../../helpers/helperUsers'
import ModalRequestContextVideo from '../modals/ModalRequestContextVideo';

import { getDevicesList } from '../../../action/DeviceActions'
import { getCompanyGroups } from '../../../action/GroupsActions'
import { getAvailableDatesOfCompany, getEventsOfCompanyAndRange, getEventVideo, restoreFromArchiveWCSEvent, requestFloodDownload, getAvailableDatesOfDevice } from '../../../action/AlertActions'
import { apiCallGet, apiCall } from '../../../action/RouterActions'
import { isInternalUser } from '../../helpers/constants'
import { getDateRangeFromTabKey } from '../reportsComponents/ReportsHelpers'
import { generalIcons } from '../../helpers/iconHelpers'
import { errorDetection } from '../../helpers/helperErrors'
import { isCustomUserPermissions } from '../../helpers/helperUsers'

import 'react-datetime/css/react-datetime.css'
import '../../dump/menuComponents/cloudStorage.sass'
import { eventIcons } from '../../helpers/iconHelpers'
import { getFilterName } from '../../helpers/helperEvents'
import CustomButton from '../../CustomButton'
import FilterMenu from '../../FilterMenu'

momentDurationFormatSetup(moment);

function usePreviousValue(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

const EventClipsContainer = (props) => {
	const INITIAL_STATE = {
		location: null,
		event_date: momenttz(),
		searchCategory: props.webfleetDeviceId.length > 0 ? 'driver' : 'vehicle',
		autoValue: '',
		tabKey: 0, // stores type of selected date range (Today, Yesterday, ...)
		start_date: null,
		end_date: null,

		page: 1,
		sizePerPage: 20,
		totalSize: 0,

		groups: [],
		drivers: [],
		devices: [],
		events: [],
		availableDates: [],
		requestedEventIDs: [],
		// allEvents: [],

		eventsView: localStorage.getItem('eventsView') || 'Table',

		filters: {
			critical_gsensor: true,
			speed: true,
			driver: true,
			custom: true,
			pending: isInternalUser(props.user.roles[0]) ? true : false,
			harshaccel: true,
			harshbraking: true,
			adas_fcw: true,
			adas_ldw: true,
			adas_pcw: true,
			adas_hw: true,
			tailgating: true,
			distraction: true,
			drowsiness: true,
			phone_use: true,
			yawning: true,
			seatbelt: true,
			smoking: true,
			camerablock: true,
			feedback: false,
		},
		videostatuses: {
			videouploaded: true,
			videopending: true,
			videononexist: true,
			videoconvertedfailed: true,
			videotransferringfailed: true,
			videofilesconverted: true,
			videofailed: true,
			videoundefined: true,
		},
		showVideoRequest: false,
		showEventVideoAll: false,
		selectedEvent: null,
		showEventSnapshot: false,
		showEventNote: false,
		showShareEvent: false,
		showRequestContextVideo: false,

		showModalDelete: false,
		deleteEventId: '',

		sortName: 'status',
		sortOrder: 'desc',

		showEventAlertSearch: false,
		alertSearchType: 'storage_local',
	}
	const { company, user, webfleetDeviceId, toggleLoader, eventsRedirect, setEventsRedirect, setEventArchiveRestoring } = props
	const userRole = user.roles[0]
	const userPermissions = user.permissions
	const canEventFeedbackView = ['system_admin', 'customer_service', 'sales_demo', 'partner'].includes(userRole) 
		|| (['custom_user', 'custom_partner'].includes(userRole) && isCustomUserPermissions(userRole, userPermissions, 'event_feedback_add'))
		|| (['custom_user', 'custom_partner'].includes(userRole) && isCustomUserPermissions(userRole, userPermissions, 'event_feedback_edit'))

	const prevAutoValue = useRef('')
	const prevPropsRef = useRef(_.cloneDeep(props));
	const prevStateRef = useRef(_.cloneDeep(INITIAL_STATE));

	const [interval, setIntervalState] = useState(null);
	const [state, setState] = useState(_.cloneDeep(INITIAL_STATE));

	useEffect(() => {
		if (props.location) {
			if (!localStorage.getItem('updEvent') || (state.location && localStorage.getItem('updEvent') === 'true')) {
				localStorage.setItem('updEvent', 'false');
			} else {
				localStorage.setItem('updEvent', 'true');
			}

			const locationNew = {
				type: props.location.state.type,
				date_ts: props.location.state.event_ts,
				device_id: props.location.state.event_device_id,
				event_id: props.location.state.event_id,
				search_by_id: props.location.state.event_id,
			}
			setState((prevState) => { return { ...prevState, location: locationNew, event_date: momenttz(locationNew.date_ts + '+00').format('YYYY-MM-DD'), showEventAlertSearch: false, autoValue: '', page: 1, totalSize: 0 } })
			if (props.location.state.type === 'storage_local') { // redirect from the Events Search popup on the same Daily Events page
				getFleetEventsByRole(locationNew)
			}
		}

		return () => {
			setState((prevState) => { return { ...prevState, location: null } });
			localStorage.getItem('updEvent') && localStorage.removeItem('updEvent');
		}
	}, [props.location])

	useEffect(() => {
		// debugger
		if (prevPropsRef.current.company.company_id !== props.company.company_id) {
			if (!!state.start_date && !!state.end_date) {
				setState((prevState) => { return { ...prevState, tabKey: 0, start_date: null, end_date: null } })
				clearTimer();
			}
			if (interval) {
				clearInterval(interval);
				setIntervalState(null);
			}

			setState((prevState) => { return { ...prevState, location: null, autoValue: '', page: 1, totalSize: 0, groups: [] } })
		}

		if (company && company.company_id) {
			getFleetGroups(company)
			getFleetDrivers(company.company_id)
			getFleetDevices(null, company.name, company.partner)
		}

		return () => {
			clearTimer();
		}
	}, [company.company_id])

	useEffect(() => {
		// debugger
		if (state.devices.length) {
			getAvailableDates()
		}
	}, [state.devices])

	useEffect(() => {
		// debugger
		if (state.availableDates.length) {
			let new_event_date = state.event_date
			if (state.location && state.location.date_ts) {
				new_event_date = momenttz(location.date_ts + '+00').format('YYYY-MM-DD')
			}
			else {
				new_event_date = state.availableDates[state.availableDates.length - 1]
			}

			setState((prevState) => { return { ...prevState, event_date: new_event_date } })
			getFleetEvents(state.page, state.sizePerPage, true, new_event_date)
		}
	}, [state.availableDates])


	useEffect(() => {
		if (!_.isEqual(state.filters, prevStateRef.current.filters)
			|| !_.isEqual(state.videostatuses, prevStateRef.current.videostatuses)
			|| (prevStateRef.current.groups.length && state.groups.length && !_.isEqual(state.groups, prevStateRef.current.groups))
		) {
			// debugger
			getFleetEvents(1, state.sizePerPage, true)
		}
		prevStateRef.current = _.cloneDeep(state)
	}, [state])

	useEffect(() => {
		// debugger
		prevPropsRef.current = _.cloneDeep(props)
	}, [props])

	const getFleetEventsByRole = (location) => {
		switch (userRole) {
			case 'system_admin':
			case 'customer_service':
			case 'sales_demo':
				if (company.name) {
					getFleetGroups()
					getFleetDrivers()
					// getFleetDevices(null, company.name, company.partner)
					getFleetDevices(
						() => {
							getAvailableDates(() => {
								// debugger
								if (location && location.date_ts) {
									// if (varEventsRedirect && varEventsRedirect.event_ts) {
									setState((prevState) => { return { ...prevState, event_date: momenttz(location.date_ts + '+00').format('YYYY-MM-DD') } })
									getFleetEvents(
										1,
										state.sizePerPage,
										true,
										momenttz(location.date_ts + '+00').format('YYYY-MM-DD')
										// momenttz(varEventsRedirect.event_ts + '+00').format('YYYY-MM-DD')
									)
								}
							})
						},
						company.name,
						company.partner
					)
				}
				break;
			case 'partner':
			case 'partner_view':
				if (company.name) {
					getFleetGroups()
					getFleetDrivers()
					// getFleetDevices(null, company.name)
					getFleetDevices(
						() => {
							getAvailableDates(() => {
								// debugger
								if (location && location.date_ts) {
									// if (varEventsRedirect && varEventsRedirect.event_ts) {
									setState((prevState) => { return { ...prevState, event_date: momenttz(location.date_ts + '+00').format('YYYY-MM-DD') } })
									getFleetEvents(
										1,
										state.sizePerPage,
										true,
										momenttz(location.date_ts + '+00').format('YYYY-MM-DD')
										// momenttz(varEventsRedirect.event_ts + '+00').format('YYYY-MM-DD')
									)
								}
							})
						},
						company.name
					)
				}
				break;
			default:
				getFleetGroups()
				getFleetDrivers()
				// getFleetDevices(null, company.name)
				getFleetDevices(
					() => {
						getAvailableDates(() => {
							// debugger
							if (location && location.date_ts) {
								// if (varEventsRedirect && varEventsRedirect.event_ts) {
								setState((prevState) => { return { ...prevState, event_date: momenttz(location.date_ts + '+00').format('YYYY-MM-DD') } })
								getFleetEvents(
									1,
									state.sizePerPage,
									true,
									momenttz(location.date_ts + '+00').format('YYYY-MM-DD')
									// momenttz(varEventsRedirect.event_ts + '+00').format('YYYY-MM-DD')
								)
							}
						})
					},
					company.name
				)
				break;
		}
	}

	const getFleetDevices = (callback) => {
		toggleLoader(true);

		getDevicesList(company.company_id)
			.then(res => {
				let { devices } = res.data.response || [];
				if (webfleetDeviceId) {
					devices = devices.filter(({ device_id }) => device_id === webfleetDeviceId)
				}
				setState((prevState) => { return { ...prevState, devices, events: [], event_date: momenttz() } })

				if (!res.data.response.devices.length) {
					toggleLoader(false);
				}

				if (callback && typeof callback === 'function') {
					callback();
				}
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('getDevicesList error: ', error)
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const getAvailableDates = (callback, company_name = null) => {
		let defaultFunction = getAvailableDatesOfCompany;

		let params = {
			user_role: user.roles[0],
			company_name: company_name || company.name || user.company_name,
			partner_name: company.partner,
			start_ts: '20150101000000',
			search_type: searchCategory,
			search: autoValue,
		}
		if (webfleetDeviceId) {
			defaultFunction = getAvailableDatesOfDevice
			params = { ...params, device_id: webfleetDeviceId }
		}
		defaultFunction(params)
			.then((res, err) => {
				// console.log('!getAvailableDates getAvailableDatesOfCompany res: ', res, err);
				setState((prevState) => { return { ...prevState, availableDates: res.data.response.events_dates } })

				if (!res.data.response.events_dates.length) {
					toggleLoader(false);
					toastr.info('', 'No events available for this company')
				}

				if (callback && typeof callback === 'function') {
					callback();
				}
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!getAvailableDates getAvailableDatesOfCompany error: ', error.response, error);

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	// Selects event types filters which should be send in /events request
	const getFiltersEventTypes = () => {
		const res = [];
		let filters = state.filters;
		_.forOwn(filters, (value, key) => {
			if (!value) {
				res.push(key)
			}
		})

		return res.join(',');
	}

	// Selects video/snapshot statuses filters with geotab === 3 which should be send in /events request
	const getFiltersVideoStatuses = () => {
		const res = [];
		let videostatuses = state.videostatuses;
		_.forOwn(videostatuses, (value, key) => {
			if (!value) {
				res.push(key)
			}
		})

		return _.uniq(res).join(',');
	}

	const getGroups = () => {
		let groups = state.groups;
		const res = _.reduce(groups, (result, value, index, arr) => {
			if (!value.selected) {
				result.push(value.id);
			}
			return result;
		}, []);

		return res.join(',');
	}

	const getFleetDrivers = (companyId) => {
		if (['system_admin', 'customer_service', 'partner', 'fleet_manager', 'fleet_maintainer', 'partner_view', 'sales_demo', 'group_manager', 'custom_user', 'user'].indexOf(userRole) === -1) {
			return
		}

		const company_id = companyId || company.company_id
		apiCallGet('/company/{company_id}/driver', { company_id })
			.then((res, err) => {
				// console.log('!getFleetDrivers res:', res, err)
				const { drivers } = res.data.response;
				setState((prevState) => { return { ...prevState, drivers } })
			})
			.catch((error) => {
				console.log('!getFleetDrivers error:', error.response, error)
				setState((prevState) => { return { ...prevState, drivers: [] } })

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data && error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const getFleetGroups = (argСompany = null) => {
		const paramCompany = argСompany || company

		getCompanyGroups(userRole, paramCompany, '?getids')
			.then(res => {
				setState((prevState) => {
					return {
						...prevState,
						groups: res.data.response.groups.map((item, i) => {
							if (i < 10) {
								return { ...item, selected: true, visible: true }
							}
							return { ...item, selected: true, visible: false }
						})
					}
				})
			})
			.catch((error) => {
				console.log('!getCompanyGroups error:', error.response, error);
				setState((prevState) => { return { ...prevState, groups: [] } });
			})
	}

	const getAllFleetEvents = async () => {
		// debugger
		let { event_date, start_date, end_date } = state
		!!start_date ? start_date : start_date = event_date
		!!end_date ? end_date : end_date = event_date

		toggleLoader(true)

		let data = {
			user_role: userRole,
			company_name: company.name || user.company_name,
			partner_name: company.partner,
			start_ts: momenttz(moment(start_date).format('YYYY-MM-DD 00:00:00')).utc().format('YYYYMMDDHHmmss'),
			end_ts: momenttz(moment(end_date).format('YYYY-MM-DD 23:59:59')).utc().format('YYYYMMDDHHmmss'),
			filters: getFiltersEventTypes(),
			videostatuses: getFiltersVideoStatuses(),
			groups: getGroups(),
			search_type: state.searchCategory,
			search: state.autoValue,
			order: `&order=${state.sortName}&direction=${state.sortOrder}`,
			search_by_id: (state.location && state.location.search_by_id) ? state.location.search_by_id : '',
		}

		if (webfleetDeviceId) {
			data = { ...data, device_ids: webfleetDeviceId };
		}

		try {
			const request = await getEventsOfCompanyAndRange(data)
			const { events } = request.data.response
			return events
		} catch (error) {
			console.log('!EventClipsContainer getAllFleetEvents getEventsOfCompanyAndRange error: ', error.response, error);
			if (!error.response.data.body) {
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			}
		}
		finally {
			toggleLoader(false)
		}
	}

	const getFleetEvents = (page = state.page, sizePerPage = state.sizePerPage, showLoader = true, event_date = null, company_name = null, callback = null, eventIds = null, start_date = null /*|| state.start_date*/, end_date = null /*|| state.end_date*/) => {
		// debugger
		event_date = event_date || state.event_date;
		start_date = start_date || state.start_date;
		end_date = end_date || state.end_date;
		if (!start_date && !end_date) {
			if (moment(event_date).format('MM/DD/YY') === moment().format('MM/DD/YY')) {
				setState((prevState) => { return { ...prevState, tabKey: 1 } })
				clearTimer()
			} else {
				setState((prevState) => { return { ...prevState, tabKey: null } })
				clearTimer();
			}
		}
		!!start_date ? start_date : start_date = event_date;
		!!end_date ? end_date : end_date = event_date;

		if (showLoader) {
			toggleLoader(true);
		}

		let data = {
			user_role: userRole,
			company_name: company_name || company.name || user.company_name,
			partner_name: company.partner,
			start_ts: momenttz(moment(start_date).format('YYYY-MM-DD 00:00:00')).utc().format('YYYYMMDDHHmmss'),
			end_ts: momenttz(moment(end_date).format('YYYY-MM-DD 23:59:59')).utc().format('YYYYMMDDHHmmss'),
			page_number: page,
			page_size: sizePerPage,
			filters: getFiltersEventTypes(),
			videostatuses: getFiltersVideoStatuses(),
			groups: getGroups(),
			search_type: state.searchCategory,
			search: state.autoValue,
			order: `&order=${state.sortName}&direction=${state.sortOrder}`,
			search_by_id: (state.location && state.location.search_by_id) ? state.location.search_by_id : '',
			event_ids: eventIds
		}

		if (webfleetDeviceId) {
			data = { ...data, device_ids: webfleetDeviceId };
		}

		if (eventIds) {
			delete data.page_number
			delete data.page_size
		}

		// let allEventsData = _.cloneDeep(data);
		// delete allEventsData.page_number
		// delete allEventsData.page_size

		// getEventsOfCompanyAndRange(allEventsData)
		// .then(res => {
		// 	setState((prevState) => {
		// 		return {
		// 			...prevState,
		// 			allEvents: res.data.response.events
		// 		}
		// 	})
		// });

		getEventsOfCompanyAndRange(data)
			.then((res, err) => {
				// debugger
				// console.log('!getFleetEvents getEventsOfCompanyAndRange res', res)
				const { response } = res.data;

				if (data.search_type === 'event' && data.search && response.events.length) {
					const newEventDate = momenttz(response.events[0].timestamp).format('YYYY-MM-DD')
					setState((prevState) => {
						return {
							...prevState,
							event_date: newEventDate,
							events: response.events || [],
							totalSize: response.events.length || 0,
							page: 1,
						}
					})
				}
				else if (eventIds && eventIds.length) {
					if (response.events.length) {
						let events = state.events;
						const filteredEvents = events.map(event => {
							const index = eventIds.indexOf(event.id);
							if (index > -1) {
								event = _.find(response.events, ['id', event.id]);
								if (event
									&& ((event.uploading_status === 'VIDEO_UPLOADED' && event.snapshot_status === 'SNAPSHOT_UPLOADED')
										|| event.uploading_status === 'VIDEO_NON_EXIST')
								) {
									let requestedEventIDs = state.requestedEventIDs;
									requestedEventIDs.splice(index, 1);
									setState((prevState) => {
										return {
											...prevState,
											requestedEventIDs: requestedEventIDs
										}
									})
								}
							}

							return event
						})

						setState((prevState) => {
							return {
								...prevState,
								events: filteredEvents
							}
						})
					}
				}
				else {
					setState((prevState) => {
						return {
							...prevState,
							events: response.events || [],
							totalSize: response.total_number || 0,
							page: (response.page_number) ? response.page_number * 1 : page,
							// location: (location1 && location1.search_by_id) ? { ...location1, search_by_id: null } : location1,
							location: (prevState.location && prevState.location.search_by_id) ? { ...prevState.location, search_by_id: null } : prevState.location,
							sizePerPage,
						}
					})
				}

				// if (!response.events.length && !state.devices.length && !state.availableDates.length && !state.events.length) {
				// 	toastr.info('', 'Device does not have events');
				// }
				if (!response.events.length) {
					toastr.info('', 'Device does not have events');
				}

				if (callback && typeof callback === 'function') {
					callback();
				}

				if (showLoader) {
					toggleLoader(false);
				}
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!EventClipsContainer getFleetEvents getEventsOfCompanyAndRange error: ', error.response, error);

				if (!error.response.data.body) {
					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				}
			});
	}

	const getPreviousEvents = () => {
		let event_date = state.event_date;
		if (!!state.start_date) {
			event_date = momenttz(state.start_date).format('YYYY-MM-DD')
		}
		let availableDates = state.availableDates;
		const pos = _.indexOf(availableDates, event_date)
		if (pos === -1 && availableDates.length) {
			const arr = availableDates.filter(date => {
				return (momenttz(date).format('YYYY-MM-DD') < momenttz(event_date).format('YYYY-MM-DD'))
			})

			if (arr.length) {
				const elm = arr[arr.length - 1]
				setState((prevState) => { return { ...prevState, tabKey: 0, event_date: elm, start_date: elm, end_date: elm } })
				clearTimer();
				getFleetEvents(1, state.sizePerPage, true, elm, null, null, null, elm, elm)
			} else {
				toastr.info('', 'No previous events are available.')
			}
		} else if (pos > 0) {
			const elm = availableDates[pos - 1]
			setState((prevState) => { return { ...prevState, tabKey: 0, event_date: elm, start_date: elm, end_date: elm } })
			clearTimer();
			getFleetEvents(1, state.sizePerPage, true, elm, null, null, null, elm, elm)
		} else {
			toastr.info('', 'No previous events are available.')
		}
	}

	const getNextEvents = () => {
		let event_date = state.event_date;
		let availableDates = state.availableDates;
		if (!!state.end_date) {
			event_date = momenttz(state.end_date).format('YYYY-MM-DD')
		}
		const pos = _.indexOf(availableDates, event_date)
		if (pos === -1 && availableDates.length) {
			const arr = availableDates.filter(date => {
				return (momenttz(date).format('YYYY-MM-DD') > momenttz(event_date).format('YYYY-MM-DD'))
			})

			if (arr.length) {
				const elm = arr[0];
				setState((prevState) => { return { ...prevState, tabKey: 0, event_date: elm, start_date: elm, end_date: elm } })
				clearTimer();
				getFleetEvents(1, state.sizePerPage, true, elm, null, null, null, elm, elm);
			} else {
				toastr.info('', 'No future events are available.');
			}
		} else if (pos > -1 && pos < (availableDates.length - 1)) {
			const elm = availableDates[pos + 1];
			setState((prevState) => { return { ...prevState, tabKey: 0, event_date: elm, start_date: elm, end_date: elm } })
			clearTimer();
			getFleetEvents(1, state.sizePerPage, true, elm, null, null, null, elm, elm);
		} else {
			toastr.info('', 'No future events are available.')
		}
	}

	const clearTimer = () => {
		if (interval) {
			clearInterval(interval)
			setIntervalState(null);
		}
	}

	const restoreWCSEvent = (row) => {
		toggleLoader(true)
		const data = {
			user_role: userRole,
			company_name: company.name || user.company_name,
			partner_name: company.partner,
			event_id: row.id,
		}

		restoreFromArchiveWCSEvent(data)
			.then((res, err) => {
				getFleetEvents();
				toggleLoader(false)
			})
			.catch((error) => {
				toggleLoader(false)
				console.log('!restoreWCSEvent error: ', error)
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const onSearchCategoryChange = (e) => {
		// setState((prevState) => { return { ...prevState, searchCategory: e.target.value } })
		// debugger
		if (state.autoValue) {
			// debugger
			searchVehicle()
		}
	}

	const searchVehicle = () => {
		// console.log('searchVehicle called');
		// debugger
		// getAvailableDates()
		// debugger
		getFleetEvents(state.page, state.sizePerPage, true);
	}

	const fillFilters = (name) => {
		let filters = state.filters;
		filters[name] = !filters[name]
		setState((prevState) => { return { ...prevState, filters } })
		// getFleetEvents(1, state.sizePerPage, true);
	}

	const fillVideostatuseFilters = (name) => {
		let videostatuses = state.videostatuses;
		videostatuses[name] = !videostatuses[name]
		setState((prevState) => { return { ...prevState, videostatuses } })
		// getFleetEvents(1, state.sizePerPage, true);
	}

	const fillGroups = (argGroupId) => {
		let groupsData = state.groups;
		const result = groupsData.map((item) => {
			if (item.id === argGroupId) {
				return Object.assign({}, item, { selected: !item.selected })
			}
			return item;
		})

		setState((prevState) => { return { ...prevState, groups: result } })
		// getFleetEvents(1, state.sizePerPage, true);
	}

	const selectAllGroups = () => {
		let groupsData = state.groups;
		const countSelectedGroups = _.reduce(groupsData, (sum, value) => { if (value.selected) { return sum + 1 } return sum }, 0)
		if (countSelectedGroups === groupsData.length) {
			setState((prevState) => { return { ...prevState, groups: groupsData.map(item => ({ ...item, selected: false })) } })
			// getFleetEvents(1, state.sizePerPage, true)
		}
		else {
			setState((prevState) => { return { ...prevState, groups: groupsData.map(item => ({ ...item, selected: true })) } })
			// getFleetEvents(1, state.sizePerPage, true)
		}
	}

	const selectAllFilters = () => {
		let filters = state.filters;
		const countSelectedFilters = _.reduce(filters, (sum, value) => { return sum + value }, 0) + (canEventFeedbackView ? 0 : 1)
		if (countSelectedFilters === Object.keys(filters).length) {
			setState((prevState) => { return { ...prevState, filters: _.reduce(filters, (res, value, key) => { res[key] = false; return res; }, {}) } })
			// getFleetEvents(1, state.sizePerPage, true)
		}
		else {
			setState((prevState) => { 
				return { 
					...prevState, 
					filters: _.reduce(filters, (res, value, key) => { 
						if (key === 'feedback' && !canEventFeedbackView) {
							res[key] = false
						}
						else {
							res[key] = true
						}
						return res; 
					}, {}) 
				} 
			})
			// getFleetEvents(1, state.sizePerPage, true)
		}
	}

	const onMoreGroupsClick = (e) => {
		e.preventDefault()
		let groupsData = state.groups;

		let counter = 0
		const result = groupsData.map((item) => {
			if (!item.visible && (counter < 10)) {
				counter += 1
				return { ...item, visible: true }
			}
			return item
		})

		setState((prevState) => { return { ...prevState, groups: result } })
	}

	const onCloseVideoRequest = () => {
		setState((prevState) => { return { ...prevState, showVideoRequest: false } })
		sendGA({
			category: userRole,
			action: 'Custom Video Request',
			label: 'Cancel Custom Video Request'
		})
	}

	const onSaveVideoRequest = (data, staySamePage = false, length) => {
		console.log('onSaveVideoRequest: ', data)

		let _requestedEventIDs = state.requestedEventIDs;
		_requestedEventIDs.push(data.event_id)
		setState((prevState) => {
			return {
				...prevState,
				// showVideoRequest: false,
				location: { date_ts: data.event_ts, device_id: data.event_device_id, event_id: data.event_id },
				requestedEventIDs: _requestedEventIDs,
			}
		})
		sendGA({
			category: userRole,
			action: 'Custom Video Request',
			label: 'Length: ' + length + ' seconds'
		})

		if (staySamePage) {
			getFleetEvents(state.page, state.sizePerPage, false, null, null, null, _requestedEventIDs.join(','))
		} else {
			getAvailableDates(() => {
				if (state.availableDates.length) {
					getFleetEvents(
						1,
						state.sizePerPage,
						true,
						data.event_ts
					)
					setState((prevState) => { return { ...prevState, event_date: data.event_ts } })
				} else {
					toastr.info('', 'Device does not have events')
				}
			})
		}

		if (interval) {
			clearInterval(interval)
			setIntervalState(null);
		}
		setIntervalState(setInterval(() => {
			if (!state.requestedEventIDs.length) {
				clearInterval(interval);
				setIntervalState(null);
			} else {
				// debugger
				setState((prevState) => { return { ...prevState, location: null } })
				// console.log('INTERVAL')
				getFleetEvents(state.page, state.sizePerPage, false, null, null, null, state.requestedEventIDs.join(','))
			}
		}, 5000))
	}

	const openVideoRequest = () => {
		setState((prevState) => { return { ...prevState, showVideoRequest: true } })
	}

	const openRequestContextVideo = (row) => {
		setState((prevState) => { return { ...prevState, selectedEvent: row, showRequestContextVideo: true } })
		sendGA({
			category: userRole,
			action: 'Request Additional Video',
			label: 'Open Request Additional Video'
		})
	}

	const returnToEventSearch = (e) => {
		e.preventDefault();
		setState((prevState) => { return { ...prevState, showEventAlertSearch: true, alertSearchType: 'storage_link' } })
	}

	const downloadNVR = (event_id) => {
		getEventVideo({
			user_role: user.roles[0],
			event_id: event_id,
			type: 'nvr',
			company_name: company.name,
			partner_name: company.partner
		})
			.then((res, err) => {
				console.log('!downloadNVR getEventVideo res:', res);
				sendGA({
					category: userRole,
					action: 'Download Event',
					label: 'Download Event NVR'
				})
				window.location.href = res.data.response.url;
			})
			.catch((error) => {
				console.log('!downloadNVR getEventVideo error: ', error.response, error)
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const downloadFlood = (event) => {
		toggleLoader(true);

		requestFloodDownload({ event_id: event.id })
			.then((res, err) => {
				toggleLoader(false);

				const response = res.data.response
				if (response.error && response.error === 'device is offline, snapshot is pending') {
					toastr.warning('', 'Flood Video Requested but Device is offline now')
				} else {
					sendGA({
						category: userRole,
						action: 'Download Event',
						label: 'Download Event Flood'
					})
					toastr.success('', 'Flood Video Requested')
				}

				onSaveVideoRequest({
					event_ts: event.timestamp,
					event_device_id: event.device_id,
					event_id: event.id,
				}, true)
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!requestFloodDownload error:', error.response, error)
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const openEventVideoAll = (row) => {
		if (!(window.innerWidth < 768 || isMobileOnly)) {
			setState((prevState) => { return { ...prevState, selectedEvent: row, showEventVideoAll: true } })
			sendGA({
				category: userRole,
				action: 'Open Event Video',
				label: row.type
			})

			window.scrollTo(0, 0)
		}
		else {
			// Stores required params for the separate browser window to the local storage.
			const winOpen = {
				eventId: row.id,
				type: row.type,
				custom_event_type: row.custom_event_type,
				userRole: user.roles[0],
				companyName: company.name,
				partnerName: company.partner,
				driverRecognition: company.driver_recognition,
				user: user,
			}
			localStorage.setItem('winOpen', JSON.stringify(winOpen));

			const width = 1200
			const height = 800
			const w = window
			const d = document
			const e = d.documentElement
			const g = d.getElementsByTagName('body')[0]
			const winWidth = w.innerWidth || e.clientWidth || g.clientWidth
			const winHeight = w.innerHeight || e.clientHeight || g.clientHeight
			const winX = (document.all) ? window.screenLeft : window.screenX
			const winY = (document.all) ? window.screenTop : window.screenY
			const left = ((winWidth - width) / 2) + winX
			const top = ((winHeight - height) / 2) + winY
			const windowSpecs = 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=1,width=' + width + ',height=' + height + ',left=' + left + ',top=' + top
			const target = row.id
			// target = entry.device_id;
			const win = window.open('/event/video/' + target, target + '1', windowSpecs);

			return win
		}
	}

	const openEventSnapshot = (row) => {
		setState((prevState) => { return { ...prevState, selectedEvent: row, showEventSnapshot: true } })
		sendGA({
			category: userRole,
			action: 'Open Event Snapshot',
			label: row.type
		})
	}

	const onCloseEventNote = (note) => {
		setState((prevState) => { return { ...prevState, showEventNote: false } })
		let label = 'Close Event Note';
		if ((note && note.length) || note === '') {
			label = 'Submit Event Note';
			let selectedEvent = state.selectedEvent;
			if (selectedEvent) {
				selectedEvent.custom_event_type = note
				setState((prevState) => { return { ...prevState, selectedEvent } })
			}
		}
		sendGA({
			category: userRole,
			action: 'Event Note',
			label: label
		})
	}

	const onSaveEventLabel = (eventLabel) => {
		let label = `${state.selectedEvent.event_label ? "Edit" : "Add"} Event Label`;
		let selectedEvent = { ...state.selectedEvent, event_label: eventLabel };
		setState((prevState) => { return { ...prevState, selectedEvent } })

		sendGA({
			category: userRole,
			action: 'Event Label',
			label: label
		})
	}

	const onFlagEvent = (row) => {
		const { id, flagged } = row

		toggleLoader(true);
		if (!flagged) {
			apiCall('PUT', '/events/{id}/flagged', { id })
				.then((res, err) => {
					toggleLoader(false);

					if (eventsView === 'Grid') {
						document.getElementById(id).classList.replace('false', 'on')
					}
					const updatedEvents = [...state.events];
					updatedEvents[state.events.findIndex(event => event.id === id)].flagged = !row.flagged;
					setState((prevState) => { return { ...prevState, events: updatedEvents } })
					sendGA({
						category: userRole,
						action: 'Flag Event',
						label: row.type
					})
				})
				.catch((error) => {
					toggleLoader(false);
					console.log('!onFlagEvent put error:', error.response, error)

					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				})
		} else {
			apiCall('DELETE', '/events/{id}/flagged', { id })
				.then((res, err) => {
					toggleLoader(false);

					if (eventsView === 'Grid') {
						document.getElementById(id).classList.replace('on', 'false')
					}
					const updatedEvents = [...state.events];
					updatedEvents[state.events.findIndex(event => event.id === id)].flagged = !row.flagged;
					setState((prevState) => { return { ...prevState, events: updatedEvents } })
					sendGA({
						category: userRole,
						action: 'Unflag Event',
						label: row.type
					})
				})
				.catch((error) => {
					toggleLoader(false);
					console.log('!onFlagEvent delete error:', error.response, error)
					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				})
		}
	}

	const openEventNote = (row) => {
		setState((prevState) => { return { ...prevState, selectedEvent: row, showEventNote: true } })
		sendGA({
			category: userRole,
			action: 'Event Note',
			label: row.type
		})
	}

	const openModalDelete = (eventId) => {
		setState((prevState) => { return { ...prevState, showModalDelete: true, deleteEventId: eventId } })
	}

	const closeModalDelete = () => {
		setState((prevState) => { return { ...prevState, showModalDelete: false } })
	}

	const openShareEvent = (row) => {
		setState((prevState) => { return { ...prevState, selectedEvent: row, showShareEvent: true } })
		sendGA({
			category: userRole,
			action: 'Share Event',
			label: row.type
		})
	}

	const onCloseShareEvent = (success) => {
		setState((prevState) => { return { ...prevState, showShareEvent: false } })
		let label = (success && success.length) ? 'Submit Share Event' : 'Close Share Event';
		sendGA({
			category: userRole,
			action: 'Share Event',
			label: label
		})
	}

	const deleteEventVideo = () => {
		setState((prevState) => { return { ...prevState, showModalDelete: false } })
		toggleLoader(true);
		const params = {
			event_id: state.deleteEventId,
			wcs: company.type.toLowerCase() === 'wcs',
		}
		apiCall('DELETE', '/events/{event_id}', params)
			.then((res, err) => {
				toggleLoader(false);
				toastr.success('', `Video of the "${state.deleteEventId}" event has been removed`);
				getFleetEvents()

				//* Removes the events update (interval) if removed the monitored event
				const requestedEventIDs = state.requestedEventIDs;
				console.log('requestedEventIDs', requestedEventIDs)
				if (interval && requestedEventIDs.length) {
					console.log('intervals: ', requestedEventIDs.length)
					const index = requestedEventIDs.indexOf(state.deleteEventId)
					console.log('index', index)
					if (index > -1) {
						requestedEventIDs.splice(index, 1)
						setState((prevState) => { return { ...prevState, requestedEventIDs: requestedEventIDs } })
					}

					console.log('requestedEventIDs1', requestedEventIDs, state.requestedEventIDs)
					if (!state.requestedEventIDs.length) {
						clearInterval(interval)
						setIntervalState(null);
					}
				}
				// ************************
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('deleteEventVideo error: ', error)
				let errDescription = ''
				if (error.response.data.response && error.response.data.response.error) {
					errDescription = errorDetection(error.response.data.response.error) || error.response.data.response.error
				}
				toastr.error(`Unable to delete event. ${errDescription || 'Please try again later'}`);
			})
	}

	const openEventAlertSearch = () => {
		setState((prevState) => { return { ...prevState, showEventAlertSearch: true, alertSearchType: 'storage_local' } })
		sendGA({
			category: userRole,
			action: 'Event Search',
			label: 'Open Event Search',
		})
	}

	const cardActionsFormatter = (row) => {
		let snapshotButton, downloadButton, deleteButton, shareButton, flaggedButton, noteButton, requestVideoButton, archivedButton

		if (!(['custom_user', 'custom_partner'].includes(userRole) && _.find(userPermissions, { 'perm_category_id': 2, 'a_edit': false }))) {
			flaggedButton = <CustomButton prefix={`imageButton flaggedButton ${row.flagged && "on"}`} id={row.id} title={`${row.flagged ? "Unflag" : "Flag"} Event`} onClick={() => onFlagEvent(row, 'Grid')} />
			noteButton = <CustomButton size="icon" variant="tertiary" prefix={classnames("note-button", { 'with-note': row.custom_event_type })} active={row.custom_event_type} onClick={() => openEventNote(row)}>
				Note
			</CustomButton>
		}

		if (row.uploading_status === 'VIDEO_UPLOADED') {
			downloadButton = <CustomButton prefix="imageButton downloadButton" title="Download Video" onClick={() => downloadNVR(row.id)} />
			shareButton = <CustomButton prefix="imageButton shareButton" title="Share Event" onClick={() => openShareEvent(row)} />
		}
		else if (row.snapshot_status === 'SNAPSHOT_UPLOADED') {
			snapshotButton = <CustomButton prefix="imageButton snapshotButton" title="View Snapshot" onClick={() => openEventSnapshot(row)} />
			requestVideoButton = <CustomButton prefix="imageButton requestVideoButton" title="Request Video" disabled={row?.uploading_status === 'EVENT_REPORTED'} onClick={() => openRequestContextVideo(row)} />
		}

		if (['system_admin', 'customer_service', 'partner', 'fleet_manager'].indexOf(userRole) > -1) {
			deleteButton = <CustomButton id="deleteButton" prefix="imageButton deleteButton" title="Delete Custom Request" onClick={() => openModalDelete(row.id)}>
				{generalIcons.crossDelete}
			</CustomButton>
		} else if (row.uploading_status === 'VIDEO_UPLOADED' && row.type.toLowerCase() === 'flood') {
			downloadButton = <CustomButton prefix="imageButton downloadButton" title="Download Video" onClick={() => downloadFlood(row)} />
		}

		if (['custom_user', 'custom_partner'].includes(userRole)) {
			if (!_.find(userPermissions, { 'perm_category_id': 9, 'a_view': true })) {
				downloadButton = null
			}
		} else if (userRole === 'sales_demo') {
			downloadButton = (downloadButton) ? <CustomButton prefix="imageButton downloadButton disabled" title="Download Video" disabled="disabled" /> : ''
			shareButton = (shareButton) ? <CustomButton prefix="imageButton shareButton disabled" title="Share Event" disabled="disabled" /> : ''
		}

		if (row.archive === 'archived') {
			archivedButton = <CustomButton prefix={`imageButton archivedButton`} title="Restore Event" onClick={() => setEventArchiveRestoring({ showModal: true, message_type: 'event_restore', event_id: row.id })} />
		}
		else if (row.archive === 'restoring') {
			archivedButton = <CustomButton prefix={`imageButton archivedButton rosco-tertiary active`} title="Event Restoring" onClick={() => setEventArchiveRestoring({ showModal: true, message_type: 'event_restoring', event_id: row.id, request_ts: row.request_ts })} />
		}

		return (
			<div className="event-actions">
				{archivedButton}
				{!archivedButton && snapshotButton}
				{shareButton}
				{!archivedButton && downloadButton}
				{requestVideoButton}
				{flaggedButton}
				{noteButton}
				{deleteButton}
			</div>
		)
	}

	const changeView = (view) => {
		setState((prevState) => { return { ...prevState, eventsView: view } })
		localStorage.setItem('eventsView', view);
		sendGA({
			category: userRole,
			action: 'Change View Format',
			label: view
		})
	}

	const updateDriver = (event_id, driver) => {
		const updatedEvents = [...state.events];
		for (let event of updatedEvents) {
			if (event.id === event_id) {
				event.driver_name = driver.first_name + ' ' + driver.last_name;
				event.driver_id = driver.id
				setState((prevState) => { return { ...prevState, selectedEvent: event } })
			}
		}
		setState((prevState) => { return { ...prevState, events: updatedEvents } })
	}

	const editVehicle = (device_id) => {
		browserHistory.push({ pathname: '/managevehicles/edit', state: { id: device_id, company: company, type: '/event' } });
	}

	const handleSortChange = (sortName, sortOrder) => {
		setState((prevState) => { return { ...prevState, sortName: sortName, sortOrder: sortOrder } })
		getFleetEvents(state.page, state.sizePerPage, true);
	}

	// const selectDate = (tabKey, start_date, end_date) => {
	// 	let new_start_date, new_end_date;
	// 	if (tabKey) {
	// 		const dateRange = getDateRangeFromTabKey(tabKey)
	// 		new_start_date = dateRange[0];
	// 		new_end_date = dateRange[1];
	// 	}
	// 	else {
	// 		new_start_date = start_date ? moment(start_date).format('YYYYMMDD') : state.start_date;
	// 		new_end_date = end_date ? moment(end_date).format('YYYYMMDD') : state.end_date;
	// 	}
	// 	setState({ ...state, tabKey: tabKey, start_date: new_start_date, end_date: new_end_date, dateDropdownOpen: false });
	// 	clearTimer();
	// 	getFleetEvents(state.page, state.sizePerPage, true, state.event_date, null, null, null, new_start_date, new_end_date);
	// }
	const selectDate = (argTabKey, argStartDate, argEndDate) => {
		setState((prevState) => { return { ...prevState, tabKey: argTabKey, start_date: argStartDate, end_date: argEndDate } })
		clearTimer()
		getFleetEvents(state.page, state.sizePerPage, true, state.event_date, null, null, null, argStartDate, argEndDate);
	}

	const onDateChanged = (current) => {
		if (!moment(current, 'MM/DD/YYYY', true).isValid()) {
			toastr.error('', 'Please enter valid date value.')
			return
		}

		toastr.removeByType('error')
		setState((prevState) => { return { ...prevState, event_date: current } })
		clearTimer()
		getFleetEvents(1, state.sizePerPage, true, current)
	}

	const handlePageChange = (page, sizePerPage) => {
		getFleetEvents(page, sizePerPage);
	}

	const handleSizePerPageChange = (sizePerPage) => {
		setState((prevState) => { return { ...prevState, events: [] } })
		getFleetEvents(state.page, sizePerPage)
	}

	const setPropStates = (argStates, callback = null) => {
		setState((prevState) => { return { ...prevState, ...argStates } })
		if (typeof callback === 'function') {
			callback()
		}
	}

	const { start_date, end_date, tabKey, page, sizePerPage, totalSize, availableDates, location, searchCategory, autoValue, events, eventsView, event_date, /*allEvents,*/ devices, drivers, groups, filters, showVideoRequest, showEventSnapshot, showRequestContextVideo, selectedEvent, showEventNote, showShareEvent, showEventVideoAll, showModalDelete, showEventAlertSearch, alertSearchType, sortName, sortOrder, videostatuses } = state;
	const isWSCCompany = company.partner === 'Rosco Vision WCS' || company.type === 'WCS'
	const videoEvents = events.filter(e => e.uploading_status === 'VIDEO_UPLOADED' || e.type.includes('Custom') || e.event_type.toLowerCase().includes('alert') || e.type.toLowerCase().includes('adas') || e.snapshot_status === "SNAPSHOT_UPLOADED");
	const customVideoUser = !(['custom_user', 'custom_partner'].includes(userRole) && !_.find(userPermissions, { perm_category_id: 2, a_add: true }))
	const videostatusFilterAccess = ['system_admin', 'customer_service'].includes(userRole)
	const selectedRow = {
		mode: 'radio',
		bgColor: '#f3f6c1', // you should give a bgcolor, otherwise, you can't regonize which row has been selected
		hideSelectColumn: true, // enable hide selection column.
		clickToSelect: true,
	}
	// const validDate = (current) => {
	// 	return (_.indexOf(availableDates, current.format('YYYY-MM-DD')) !== -1)
	// }
	// const displayItemName = (item) => {
	// 	return item.vehicle_name || item.device_id
	// }
	// const getItemValue = (item) => {
	// 	return item.vehicle_name || item.device_id;
	// }
	// const matchStateToTerm = (item, value) => {
	// 	if (item.vehicle_name) {
	// 		return item.vehicle_name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
	// 	}

	// 	return item.device_id.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1;
	// }

	const eventAlertButton = () => {
		// debugger
		if (location && !['report_link', 'driver_link', 'user_notification'].includes(location.type) && localStorage.getItem('updEvent') === 'false') {
			return <CustomButton
				variant="primary-outline"
				onClick={returnToEventSearch}
				style={{ minWidth: '218px' }}
			>
				Return to Event Search
			</CustomButton>
		}
		else if (!isWSCCompany) {
			return <CustomButton
				variant="primary-outline"
				onClick={openEventAlertSearch}
			>
				Event Search
			</CustomButton>
		}

	}

	if (location && location.event_id) {
		// debugger
		selectedRow['selected'] = [location.event_id * 1];
	} else if (location && location.device_id) {
		// debugger
		console.log('isSame date event_device_id', location);
		let foundevent = _.find(
			events,
			function (item) {
				return item.device_id == location.device_id && moment(item.timestamp).diff(moment(location.date_ts + '+00'), 'seconds') <= 5 && moment(item.timestamp).diff(moment(location.date_ts + '+00'), 'seconds') >= -5;
			});
		if (foundevent) {
			console.log('Found Event', foundevent);

			let loc = location;
			loc.event_id = foundevent.id
			setState((prevState) => { return { ...prevState, location: loc } })
			selectedRow['selected'] = [foundevent.id];
		}
	}

	// console.log('PROPS EventClipsContainer state: ', props)	
	// console.log('STATE EventClipsContainer state: ', state)	
	// console.log('PROPS EventClipsContainer location: ', props.location)	
	// console.log('prevStateRef EventClipsContainer prevStateRef.current: ', prevStateRef.current)	

	// console.log('props.location: ', props.location)
	// console.log('state.location: ', state.location)

	return (
		<div className="cloud-storage">
			<div className='page-subheader event-subheader-grid'>
				{/* Date range selection */}
				<EventClipsDateRange
					tabKey={tabKey}
					eventDate={event_date}
					startDate={start_date}
					endDate={end_date}
					onSelectDate={selectDate}
					onDateChanged={onDateChanged}
					getPreviousEvents={getPreviousEvents}
					getNextEvents={getNextEvents}
				/>

				{/* Events view selection */}
				<div className='view-format-select'>
					<CustomButton variant="primary-outline" size="icon" active={eventsView === 'Table'} onClick={() => changeView('Table')}>
						{eventIcons.tableViewIcon}
					</CustomButton>

					<CustomButton variant="primary-outline" size="icon" active={eventsView === 'Grid'} onClick={() => changeView('Grid')}>
						{eventIcons.gridViewIcon}
					</CustomButton>
				</div>

				{/* Search type and input */}
				<EventClipsSearchType
					devices={devices}
					drivers={drivers}
					searchCategory={searchCategory}
					autoValue={autoValue}
					onSearchCategoryChange={onSearchCategoryChange}
					searchVehicle={searchVehicle}
					setPropStates={setPropStates}
					webfleetDeviceId={webfleetDeviceId}
				/>

				{/* Events filter */}
				<FilterMenu
					filters={filters}
					groups={groups}
					userRole={userRole}
					user={user}
					videostatuses={videostatuses}
					fillFilters={fillFilters}
					selectAllFilters={selectAllFilters}
					videostatusFilterAccess={videostatusFilterAccess}
					fillGroups={fillGroups}
					fillVideostatuseFilters={fillVideostatuseFilters}
					selectAllGroups={selectAllGroups}
					onMoreGroupsClick={onMoreGroupsClick}
					showClose={true}
					showGroups
					twoColumns
				/>

				{/* Event additional buttons */}
				<div className='event-buttons'>
					{customVideoUser &&
						<CustomButton
							variant="primary-outline"
							onClick={openVideoRequest}
							ga_params={{ category: userRole, action: 'Custom Video Request', label: 'Open Custom Video Request' }}
						>
							Custom Video Request
						</CustomButton>
					}
					{eventAlertButton()}
				</div>
			</div>

			{/* Events table */}
			<main className="main-content-block">
				{eventsView === 'Grid'
					? <EventClipsGrid
						events={videoEvents}
						cardActionsFormatter={cardActionsFormatter}
						openEventVideoAll={openEventVideoAll}
						openEventSnapshot={openEventSnapshot}
						totalSize={totalSize}
						sizePerPage={sizePerPage}
						handlePageChange={handlePageChange}
						page={page}
						location={location}
						editVehicle={editVehicle}
						editVehicleUser={userRole === 'system_admin' || userRole === 'customer_service'}
					/>
					: <EventClipsTable
						events={events}
						// allEvents={allEvents}
						isWSCCompany={isWSCCompany}
						selectedRow={selectedRow}
						totalSize={totalSize}
						eventDate={event_date}
						page={page}
						sizePerPage={sizePerPage}
						sortName={sortName}
						sortOrder={sortOrder}
						openEventNote={openEventNote}
						onFlagEvent={onFlagEvent}
						openEventVideoAll={openEventVideoAll}
						downloadNVR={downloadNVR}
						openShareEvent={openShareEvent}
						openEventSnapshot={openEventSnapshot}
						openModalDelete={openModalDelete}
						downloadFlood={downloadFlood}
						handlePageChange={handlePageChange}
						handleSizePerPageChange={handleSizePerPageChange}
						handleSortChange={handleSortChange}
						editVehicle={editVehicle}
						restoreWCSEvent={restoreWCSEvent}
						getAllFleetEvents={getAllFleetEvents}
						getFleetEvents={getFleetEvents}
					/>
				}
			</main>

			{showVideoRequest
				&& (
					<CustomVideoRequest
						// autoValue={autoValue}
						devices={_.sortBy(devices, (num) => { return (num.vehicle_name || num.device_id.toString()).toLowerCase(); })}
						propsSelectedDate={event_date}
						onHide={onCloseVideoRequest}
						onSave={onSaveVideoRequest}
					/>
				)}

			{showEventSnapshot
				&& (
					<EventViewSnapshotModal
						event={selectedEvent}
						getFleetEvents={getFleetEvents}
						onHide={() => setState((prevState) => { return { ...prevState, showEventSnapshot: false } })}
					/>
				)}

			<EventNoteModal
				show={showEventNote}
				event={selectedEvent}
				company_name={company.name || user.company_name}
				partner_name={company.partner || user.partner_company_name}
				onHide={onCloseEventNote}
				onSave={(note) => {
					// getFleetEvents();
					onCloseEventNote(note);
				}}
			/>

			{showEventVideoAll
				&& (
					<AdvancedPlayer
						event={selectedEvent}
						openEventNote={openEventNote}
						openShareEvent={openShareEvent}
						onFlagEvent={onFlagEvent}
						drivers={drivers}
						onHide={() => setState((prevState) => { return { ...prevState, showEventVideoAll: false } })}
						isFlagged={selectedEvent.flagged}
						note={selectedEvent.custom_event_type}
						updateDriver={updateDriver}
						onSaveEventLabel={onSaveEventLabel}
						getFleetEvents={getFleetEvents}
					/>
				)}

			{showShareEvent
				&& (
					<EventShareModal
						event={selectedEvent}
						onHide={onCloseShareEvent}
					/>
				)}

			{showModalDelete
				&& (
					<ModalDelete
						title={'Delete Video'}
						content={`Are you sure you want to delete this event video?`}
						closeModal={closeModalDelete}
						deleteModal={deleteEventVideo}
					/>
				)}

			{showEventAlertSearch
				&& (
					<TrackTraceAlertSearch
						onHide={() => setState((prevState) => { return { ...prevState, showEventAlertSearch: false } })}
						devices={_.sortBy(devices, (num) => { return (num.vehicle_name || num.device_id.toString()).toLowerCase(); })}
						user_role={userRole}
						user_permissions={userPermissions}
						company_id={company.company_id || user.company_id}
						user={user}
						company_name={company.name || user.company_name}
						partner_name={company.partner || user.partner_company_name}
						type={alertSearchType}
						webfleetDeviceId={webfleetDeviceId}
						handleSelect={props.handleSelect}
					/>
				)}
			{showRequestContextVideo
				&& (<ModalRequestContextVideo
					event={selectedEvent}
					onHide={() => setState((prevState) => { return { ...prevState, showRequestContextVideo: false } })}
				/>
				)}
		</div>
	)
}

EventClipsContainer.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired,
	setDashboardRedirect: PropTypes.func.isRequired,
	webfleetDeviceId: PropTypes.string,
}

EventClipsContainer.defaultProps = {
	webfleetDeviceId: '',
}

const mapStateToProps = ({ user, company, webfleetData, eventsData }) => ({
	user: user.user,
	company: company.company,
	webfleetDeviceId: webfleetData.deviceId,
	eventsRedirect: eventsData.eventsRedirect,
})

const mapDispatchToProps = dispatch => ({
	setDashboardRedirect: (state) => {
		dispatch({ type: 'SET_DASHBOARD_REDIRECT', payload: state });
	},
	setEventsRedirect: (state) => {
		dispatch({ type: 'SET_EVENTS_REDIRECT', payload: state });
	},
	setEventArchiveRestoring: (state) => {
		dispatch({ type: 'SET_EVENT_ARCHIVE_RESTORING', payload: state });
	},
	toggleLoader: (show) => {
		dispatch({ type: 'TOGGLE_LOADER', payload: show });
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(EventClipsContainer);
