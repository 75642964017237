import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import { toastr } from 'react-redux-toastr'
import Toggle from 'react-toggle'

import CustomButton from '../../component/CustomButton'
import CustomReactBootstrapSlider from '../../component/CustomReactBootstrapSlider'
import { updateEventWeights } from '../../action/DriverActions'
import { constants } from '../../component/helpers/constants'

export const initEventWeightsArr = [
    {
        name: 'adas_fcw',
        enabled: true,
        weight: 1
    },
    {
        name: 'adas_ldw',
        enabled: true,
        weight: 1
    },
    {
        name: 'adas_pcw',
        enabled: true,
        weight: 1
    },
    {
        name: 'gsensor',
        enabled: true,
        weight: 1
    },
    {
        name: 'max_speed',
        enabled: true,
        weight: 1
    },
    {
        name: 'distraction',
        enabled: true,
        weight: 1
    },
    {
        name: 'drowsiness',
        enabled: true,
        weight: 1
    },
    {
        name: 'yawning',
        enabled: true,
        weight: 1
    },
    {
        name: 'phone_use',
        enabled: true,
        weight: 1
    },
    {
        name: 'harshaccel',
        enabled: true,
        weight: 1
    },
    {
        name: 'harshbraking',
        enabled: true,
        weight: 1
    },
    {
        name: 'low_speeding',
        enabled: true,
        weight: 1
    },
    {
        name: 'moderate_speeding',
        enabled: true,
        weight: 1
    },
    {
        name: 'high_speeding',
        enabled: true,
        weight: 1
    },
    {
        name: 'severe_speeding',
        enabled: true,
        weight: 1
    },
    {
        name: 'idling',
        enabled: true,
        weight: 1
    },
    {
        name: 'smoking',
        enabled: true,
        weight: 1
    },
    {
        name: 'seatbelt',
        enabled: true,
        weight: 1
    },
    {
        name: 'camera_block',
        enabled: true,
        weight: 1
    }
];

const ModalEventWeights = (props) => {
    const { onHide } = props;

    const [eventWeightsArr, setEventWeightsArr] = useState(props.eventWeightsArr)
    const [updatedWeights, setUpdatedWeights] = useState({})

    // console.log('PROPS ModalEventWeights: ', props)
    // console.log('STATE ModalEventWeights eventWeightsArr: ', eventWeightsArr)
    // console.log('STATE ModalEventWeights updatedWeights: ', updatedWeights)

    const changeEventWeights = (e, name) => {
        const value = e.target.value * 1;
        const weights = [...eventWeightsArr]
        const updatedWeight = weights[weights.findIndex(eventWeight => eventWeight.name === name)]
        updatedWeight.weight = value;
        setEventWeightsArr(weights);
        setUpdatedWeights((prevState) => ({ ...prevState, [name]: { weight: value, enabled: updatedWeight.enabled }}))
    }

    const toggleEnabled = (e) => {
        const value = e.target.checked ? true : false;
        const name = e.target.name;
        const weights = [...eventWeightsArr]
        const updatedWeight = weights[weights.findIndex(eventWeight => eventWeight.name === name)]
        updatedWeight.enabled = value;
        setEventWeightsArr(weights)
        setUpdatedWeights((prevState) => ({ ...prevState, [name]: { enabled: value, weight: updatedWeight.weight }}))
    }

    const applyEventWeights = () => {
        const data = {
            company_id: props.company.company_id,
            weights: JSON.stringify(updatedWeights),
        }
        updateEventWeights(data)
            .then(res => {
                props.toggleLoaderModal(false);
                props.updateScores();
                toastr.success('Successfully updated event weights')
                props.onHide();
            })
            .catch(err => {
                props.toggleLoaderModal(false);
                toastr.error('Unable to update event weights. Please try again later.')
            })
    };

    return (
        <Modal
            show
            onHide={onHide}
            size='lg'
        >
            <Modal.Header closeButton>
                <Modal.Title>Adjust Event Weights</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="event-weights-modal modal-text">
                    <h4 className="category-title text-center">Event Weights</h4>
                    <p>
                        Listed below are all the event types that can determine a driver's score. Adjust the event weight using
                        the given slider, from <strong>1</strong> (least impact) to <strong>5</strong> (most impact),
                        depending on how much you would like that event type occuring to negatively impact the driver's score.
                        If you don't want the event to affect a driver's score at all, you can disable it using the dedicated toggle.
                    </p>
                    <ul className="no-style-list two-column-grid">
                        {eventWeightsArr.map(eventType =>
                            <li className={classNames("card", { 'disabled': !eventType.enabled })} key={eventType.name}>
                                <div className='flex-item'>
                                    <label className="event-weights-label">
                                        {constants.eventNames[eventType.name]}
                                    </label>
                                    <Toggle
                                        name={eventType.name}
                                        checked={eventType.enabled}
                                        onChange={toggleEnabled}
                                    />
                                </div>
                                <CustomReactBootstrapSlider
                                    id={`${eventType.name}-slider`}
                                    className="event-slider"
                                    value={eventType.weight}
                                    max={5}
                                    min={1}
                                    step={1}
                                    ticks={[1, 2, 3, 4, 5]}
                                    tooltip="hide"
                                    change={(e) => changeEventWeights(e, eventType.name)}
                                />
                                <ul className='no-style-list'>
                                    <li>1</li>
                                    <li>2</li>
                                    <li>3</li>
                                    <li>4</li>
                                    <li>5</li>
                                </ul>
                            </li>
                        )}
                    </ul>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton variant='primary' onClick={applyEventWeights}>Apply</CustomButton>
            </Modal.Footer>
            {props.loaderModal}
        </Modal>
    )
}

ModalEventWeights.propTypes = {
    onHide: PropTypes.func.isRequired
};

export default connect(
    state => ({
        user: state.user.user,
        company: state.company.company,
        loaderModal: state.loader.loaderModal
    }),
    dispatch => ({
        toggleLoaderModal: (show) => {
            dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
        }
    })
)(ModalEventWeights);
