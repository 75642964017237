import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'

import CustomButton from '../CustomButton'
import ModalFeedbackOptions from './ModalFeedbackOptions'
import ModalDelete from '../smart/modals/ModalDelete'
import { eventIcons } from '../helpers/iconHelpers'
import { isCustomUserPermissions } from '../helpers/helperUsers'
import { apiCallGet, apiCall } from '../../action/RouterActions'

const EventFeedback = (props) => {
	// console.log('PROPS EventFeedback: ', props)
	const { event, getFleetEvents, user, toggleLoader } = props

	const [feedback, setFeedback] = useState({})
	const [showFeedbackOptions, setShowFeedbackOptions] = useState(false)
	const [showThankYouModal, setShowThankYouModal] = useState(false)
	const [showRemoveFeedback, setShowRemoveFeedback] = useState(false)
	const { has_feedback, updated_user_id } = feedback
	const userRole = user.roles[0]
	const userPermissions = user.permissions

	useEffect(() => {
		getEventFeedback()
		// return () => {
		// 	mySliderRef.current.destroy()
		// }
	}, [])

	const getEventFeedback = () => {
		// toggleLoader(true)
		apiCallGet('/events/{event_id}/feedback', { event_id: event.id })
			.then((res, err) => {
				const { feedback } = res.data.response
				setFeedback(feedback || {})
				// toggleLoader(false)
			})
			.catch((error) => {
				console.log('!getEventFeedback error:', error.response, error)
				// toggleLoader(false)
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			})
	}

	const onFeedbackClick = () => {
		if (has_feedback) {
			setShowRemoveFeedback(true)
		}
		else {
			setShowFeedbackOptions(true)
		}
	}

	const addFeedback = () => {
		setShowThankYouModal(true)
		getEventFeedback()
		if (getFleetEvents && typeof getFleetEvents === 'function') {
			getFleetEvents()
		}
	}

	const removeFeedback = (e) => {
		toggleLoader(true)
		const params = {
			event_id: event.id,
			selections: '',
			comment: '',
			has_feedback: false,
		}

		apiCall('POST', '/events/{event_id}/feedback', params)
			.then((res, err) => {
				toggleLoader(false)
				getEventFeedback()
				if (getFleetEvents && typeof getFleetEvents === 'function') {
					getFleetEvents()
				}
				setShowRemoveFeedback(false)
			})
			.catch((error) => {
				console.log('!POSR Feedback error:', error.response, error)
				toggleLoader(false)
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			})
	}

	const isFeedbackDisabled = () => {
		const canFeedbackAdd = ['system_admin', 'customer_service', 'sales_demo', 'partner'].includes(userRole)
			|| (['custom_user', 'custom_partner'].includes(userRole) && isCustomUserPermissions(userRole, userPermissions, 'event_feedback_add'))
		const canFeedbackEdit = ['system_admin', 'customer_service', 'sales_demo'].includes(userRole)
			|| updated_user_id === user.id
			|| (['custom_user', 'custom_partner'].includes(userRole) && isCustomUserPermissions(userRole, userPermissions, 'event_feedback_edit'))

		if (
			has_feedback && canFeedbackEdit
			|| !has_feedback && canFeedbackAdd
		) {
			return false
		}

		return true
	}

	return (
		<>
			<CustomButton
				variant='empty'
				size='icon'
				prefix='event-feedback-button'
				active={has_feedback || false}
				title={`${has_feedback ? "Remove" : "Add"} Feedback`}
				disabled={isFeedbackDisabled()}
				onClick={onFeedbackClick}
			>
				{eventIcons.eventFeedbackIcon}
			</CustomButton>

			{showFeedbackOptions &&
				<ModalFeedbackOptions
					event={event}
					feedback={feedback}
					toggleLoader={toggleLoader}
					onSaveFeedback={addFeedback}
					onHide={() => setShowFeedbackOptions(false)}
				/>
			}

			{showThankYouModal &&
				<ModalDelete
					title="THANK YOU!"
					content={`Your feedback has been received. The RoscoLive team thanks you for taking the time to submit your feedback as it is invaluable to us to better understand how to improve your experience and satisfaction with RoscoLive. \n
					This event has been hidden from view anywhere events are displayed. You can view this event and any others marked as non-valuable by selecting the “Non-Valuable” filter option in any of the event lists on RoscoLive. In addition, it will NOT be considered when calculating driver scores.\n
					To undo this action, simply click the <svg xmlns="http://www.w3.org/2000/svg" fill="red" viewBox="0 0 16 16" width="16" height="16">
					<path d="M14.4965 7.5485H13.9244C14.5925 7.55481 15.1302 8.09746 15.1302 8.76559C15.1302 9.43372 14.5925 9.97637 13.9244 9.98267H10.0767C10.5611 11.1801 10.8093 12.4604 10.8058 13.7517C10.8142 14.2839 10.6088 14.7964 10.2358 15.175C9.86215 15.5535 9.35245 15.7667 8.82032 15.7653H8.11924C8.01829 11.9851 5.14096 9.47238 1.50094 9.82003C1.18265 9.82073 0.876961 9.69453 0.651212 9.46949C0.426163 9.24374 0.299971 8.93807 0.300671 8.61976V3.06703C0.299269 2.74804 0.424765 2.44235 0.650512 2.2166C0.876261 1.99085 1.18193 1.86536 1.50094 1.86676H2.07865C4.12023 1.98454 5.47189 0.122436 7.5079 0.240228H13.397C14.0644 0.247239 14.6014 0.789884 14.6014 1.45731C14.6014 2.12474 14.0644 2.6674 13.397 2.6744H13.9242C14.5923 2.68211 15.1301 3.22615 15.1301 3.8943C15.1301 4.56245 14.5923 5.10649 13.9242 5.11421H14.4963C15.163 5.12192 15.6994 5.66456 15.6994 6.33129C15.6994 6.99802 15.163 7.54068 14.4963 7.54838L14.4965 7.5485Z" />
				</svg> icon again.
					Once undone, the feedback submitted will be removed and event returned back to normal.`}
					styleView="primary"
					backdropClassName="upper"
					dialogClassName="event-feedback-thankyou"
					deleteBtnName="Ok"
					deleteModal={() => setShowThankYouModal(false)}
				/>
			}

			{showRemoveFeedback &&
				<ModalDelete
					title="Remove Event Feedback?"
					content={`Are you sure you want to remove the feedback submitted for this event and restore visibility of this event back to normal?`}
					styleView="delete"
					backdropClassName="upper"
					dialogClassName="event-feedback-thankyou"
					showClose
					closeBtnName="No"
					deleteBtnName="Yes"
					closeModal={() => setShowRemoveFeedback(false)}
					deleteModal={removeFeedback}
				/>
			}
		</>
	)
}

const mapStateToProps = ({ user }) => ({
	user: user.user,
})

const mapDispatchToProps = dispatch => ({
	toggleLoader: (show) => {
		dispatch({ type: 'TOGGLE_LOADER', payload: show });
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(EventFeedback)
