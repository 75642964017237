import axios from 'axios';

export default function Auth() {
	/**
	 * Authenticate a user. Save a token string in Local Storage
	 *
	 * @param {string} token
	 */
	this.authenticateUser = (token, s_t, r_u) => {
		localStorage.setItem('islogged', token);
		localStorage.setItem('s_t', s_t);
		localStorage.setItem('r_u', r_u * 1);
	}

	/**
	 * Check if a user is authenticated - check if a token is saved in Local Storage
	 *
	 * @returns {boolean}
	 */
	this.isUserAuthenticated = () => {
		// return localStorage.getItem('islogged') !== null;
		return localStorage.getItem('islogged');
	}

	/**
	 * Deauthenticate a user. Remove a token from Local Storage.
	 *
	 */
	this.deauthenticateUser = () => {
		localStorage.removeItem('islogged');
		localStorage.removeItem('s_t');
		localStorage.removeItem("timeSession");
		// localStorage.removeItem("r_t");
	}

	/**
	 * Get a token value.
	 *
	 * @returns {string}
	 */
	this.getToken = () => {
		return localStorage.getItem('token');
	}

	this.getSessionToken = () => {
		return localStorage.getItem('s_t');
	}

	this.setAxiosAuthToken = (argToken = null) => {
		const token = argToken || localStorage.getItem('wfauth_token')
		// set JWT token to local
		localStorage.setItem('wfauth_token', token)
		if (token) {
			axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
			// // console.log('location: ', location)
			// // Set config defaults when creating the instance
			// const instance = axios.create({
			//   baseURL: 'https://geotabstaging.roscocloud.com' //location.origin
			// });
			// console.log('instance: ', instance)
			// // Alter defaults after instance has been created
			// instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

			// // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		}
		else
			delete axios.defaults.headers.common["Authorization"];
	}
};
