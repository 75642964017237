// FWSD-4740

import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router'
import Slider from 'react-slick'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { toastr } from 'react-redux-toastr'
import $ from 'jquery'

import { RiAlertFill } from 'react-icons/ri'
import { FaChevronDown, FaChevronUp, FaChevronLeft, FaChevronRight } from 'react-icons/fa'

import momenttz from '../../../component/helpers/momenttz'
import EventViewSnapshotModal from '../../../component/smart/modals/EventViewSnapshotModal'
import { apiCallGet } from '../../../action/RouterActions'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './TripSnapshots.sass'

// const DEMO_SNAPSHOTS = [
// 	{urls:[{channel: 1, "url": "https://stages3.roscocloud.com/rosco-user-825/206812011/1909868/1.jpeg?AWSAccessKeyId=AKIAR34ZBXI7E5ZZ7R7C&Signature=RoYAYQbnPIzGy0oqoWE3oyo9H5c%3D&Expires=1982683241"}, {channel: 3, url: "https://stages3.roscocloud.com/rosco-user-825/3191631123/1909870/3.jpeg?AWSAccessKeyId=AKIAR34ZBXI7E5ZZ7R7C&Signature=SkxyE0z%2FmR89r8I6OW3hyvL1xa8%3D&Expires=1982761485"}],
// 	"type": "live",
// 	"ts": "2022-10-24 13:55:28"},
// 	{urls:[{channel: 1, "url": "https://stages3.roscocloud.com/rosco-user-825/206812011/1909860/1.jpeg?AWSAccessKeyId=AKIAR34ZBXI7E5ZZ7R7C&Signature=cBhQfUXmQDM%2F7rbVd84STIfotik%3D&Expires=1982683241"}, {channel: 3, url: "https://stages3.roscocloud.com/rosco-user-825/3191631123/1909870/3.jpeg?AWSAccessKeyId=AKIAR34ZBXI7E5ZZ7R7C&Signature=SkxyE0z%2FmR89r8I6OW3hyvL1xa8%3D&Expires=1982761485"}],
// 	"type": "live",
// 	"ts": "2022-10-24 14:00:28"},
// 	{urls:[{channel: 1, "url": "https://stages3.roscocloud.com/rosco-user-825/206812011/1909868/1.jpeg?AWSAccessKeyId=AKIAR34ZBXI7E5ZZ7R7C&Signature=RoYAYQbnPIzGy0oqoWE3oyo9H5c%3D&Expires=1982683241"}, {channel: 3, url: "https://stages3.roscocloud.com/rosco-user-825/3191631123/1909870/3.jpeg?AWSAccessKeyId=AKIAR34ZBXI7E5ZZ7R7C&Signature=SkxyE0z%2FmR89r8I6OW3hyvL1xa8%3D&Expires=1982761485"}],
// 	"type": "event",
// 	"event_id": "1909852",
// 	"ts": "2022-10-24 14:05:28"},
// 	{urls:[{channel: 1, "url": "https://stages3.roscocloud.com/rosco-user-825/3191631123/1909870/1.jpeg?AWSAccessKeyId=AKIAR34ZBXI7E5ZZ7R7C&Signature=qNXoXEq3CY6SGOGC4PASHxDLJVs%3D&Expires=1982761485"}, {channel: 3, url: "https://stages3.roscocloud.com/rosco-user-825/3191631123/1909870/3.jpeg?AWSAccessKeyId=AKIAR34ZBXI7E5ZZ7R7C&Signature=SkxyE0z%2FmR89r8I6OW3hyvL1xa8%3D&Expires=1982761485"}],
// 	"type": "live",
// 	"ts": "2022-10-24 14:10:28"},
// 	{urls:[{channel: 1, "url": "https://stages3.roscocloud.com/rosco-user-825/3191631123/1909870/3.jpeg?AWSAccessKeyId=AKIAR34ZBXI7E5ZZ7R7C&Signature=SkxyE0z%2FmR89r8I6OW3hyvL1xa8%3D&Expires=1982761485"}, {channel: 3, url: "https://stages3.roscocloud.com/rosco-user-825/3191631123/1909870/3.jpeg?AWSAccessKeyId=AKIAR34ZBXI7E5ZZ7R7C&Signature=SkxyE0z%2FmR89r8I6OW3hyvL1xa8%3D&Expires=1982761485"}],
// 	"type": "live",
// 	"ts": "2022-10-24 14:15:28"},
// 	{urls:[{channel: 1, "url": "https://stages3.roscocloud.com/rosco-user-825/3191631123/1909870/3.jpeg?AWSAccessKeyId=AKIAR34ZBXI7E5ZZ7R7C&Signature=SkxyE0z%2FmR89r8I6OW3hyvL1xa8%3D&Expires=1982761485"}, {channel: 3, url: "https://stages3.roscocloud.com/rosco-user-825/3191631123/1909870/3.jpeg?AWSAccessKeyId=AKIAR34ZBXI7E5ZZ7R7C&Signature=SkxyE0z%2FmR89r8I6OW3hyvL1xa8%3D&Expires=1982761485"}],
// 	"type": "live",
// 	"ts": "2022-10-24 14:20:28"},
// 	{urls:[{channel: 1, "url": "https://stages3.roscocloud.com/rosco-user-825/3191631123/1909870/3.jpeg?AWSAccessKeyId=AKIAR34ZBXI7E5ZZ7R7C&Signature=SkxyE0z%2FmR89r8I6OW3hyvL1xa8%3D&Expires=1982761485"}, {channel: 3, url: "https://stages3.roscocloud.com/rosco-user-825/3191631123/1909870/3.jpeg?AWSAccessKeyId=AKIAR34ZBXI7E5ZZ7R7C&Signature=SkxyE0z%2FmR89r8I6OW3hyvL1xa8%3D&Expires=1982761485"}],
// 	"type": "live",
// 	"ts": "2022-10-24 14:30:28"},
// 	{urls:[{channel: 1, "url": "https://stages3.roscocloud.com/rosco-user-825/3191631123/1909870/3.jpeg?AWSAccessKeyId=AKIAR34ZBXI7E5ZZ7R7C&Signature=SkxyE0z%2FmR89r8I6OW3hyvL1xa8%3D&Expires=1982761485"}, {channel: 3, url: "https://stages3.roscocloud.com/rosco-user-825/3191631123/1909870/3.jpeg?AWSAccessKeyId=AKIAR34ZBXI7E5ZZ7R7C&Signature=SkxyE0z%2FmR89r8I6OW3hyvL1xa8%3D&Expires=1982761485"}],
// 	"type": "live",
// 	"ts": "2022-10-24 14:35:28"},
// 	{urls:[{channel: 1, "url": "https://stages3.roscocloud.com/rosco-user-825/3191631123/1909870/3.jpeg?AWSAccessKeyId=AKIAR34ZBXI7E5ZZ7R7C&Signature=SkxyE0z%2FmR89r8I6OW3hyvL1xa8%3D&Expires=1982761485"}, {channel: 3, url: "https://stages3.roscocloud.com/rosco-user-825/3191631123/1909870/3.jpeg?AWSAccessKeyId=AKIAR34ZBXI7E5ZZ7R7C&Signature=SkxyE0z%2FmR89r8I6OW3hyvL1xa8%3D&Expires=1982761485"}],
// 	"type": "live",
// 	"ts": "2022-10-24 14:40:28"},
// ]

const SamplePrevArrow = (props) => {
	const { className, onClick } = props
	return (
		<div
			className={className}
			onClick={onClick}
		>
			{/* <span className="glyphicon glyphicon-chevron-left" /> */}
			<FaChevronLeft />
		</div>
	)
}

const SampleNextArrow = (props) => {
	const { className, onClick } = props
	return (
		<div
			className={className}
			onClick={onClick}
		>
			{/* <span className="glyphicon glyphicon-chevron-right" /> */}
			<FaChevronRight />
		</div>
	)
}

const TripSnapshots = (props) => {
	const { map, selectedTrip, tripTimelineTimestamp, tripPlaybackTimestamp, activeTreeItem, toggleLoader } = props;

	let slickSliderRef = useRef(null);

	const [tripSnapShotsList, setTripSnapShotsList] = useState([]);
	const [showImagesWrapper, setShowImagesWrapper] = useState(true);
	const [hoverImageIndex, setHoverImageIndex] = useState(null);
	const [selectedImageIndex, setSelectedImageIndex] = useState(null);
	const [slidesToShow, setSlidesToShow] = useState(4);
	const [showEventSnapshot, setShowEventSnapshot] = useState(false);

	useEffect(() => {
		// debugger
		// getSnapshots()
		// Calculates how many slides display in slider
		const slickSliderElem = document.getElementsByClassName('slick-slider')
		if (slickSliderElem.length) {
			myResizeObserver.observe(slickSliderElem[0])
		}

		return () => {
			// console.log('componentWillUnmount TripSnapshots')
			const slickSliderElem = document.getElementsByClassName('slick-slider')
			if (slickSliderElem.length) {
				myResizeObserver.unobserve(slickSliderElem[0])
			}
		}
	}, [])

	useEffect(() => {
		// debugger
		getSnapshots(selectedTrip.route_id)
		setSelectedImageIndex(null)
	}, [selectedTrip])

	useEffect(() => {
		$("#map").css('height', `calc(100vh - ${showImagesWrapper ? '304px' : '92px'})`);

		return () => {
			$("#map").css('height', 'calc(100vh - 67px)');
		}
	}, [showImagesWrapper])

	useEffect(() => {
		if (tripSnapShotsList.length) {
			// debugger
			// console.log('componentWillReceiveProps TripSnapshots nextProps.tripTimelineTimestamp: ', nextProps.tripTimelineTimestamp)
			// _.orderBy(tripSnapShotsList, ['ts'], ['asc'])

			const newTimelineTimestamp = tripTimelineTimestamp;

			const pos = _.orderBy(tripSnapShotsList, item => moment(item.ts).valueOf(), ['asc']).findIndex(item => (
				moment(item.ts) >= moment(newTimelineTimestamp)
			));

			// console.log('POS!!! ', newTimelineTimestamp, pos, tripSnapShotsList[pos])

			let selectedImageIndex = null
			if (pos === 0 && newTimelineTimestamp === tripSnapShotsList[pos].ts) {
				selectedImageIndex = pos
			}
			if (pos > 0) {
				if (newTimelineTimestamp === tripSnapShotsList[pos].ts) {
					selectedImageIndex = pos
				}
				else {
					selectedImageIndex = pos - 1
				}
			}
			else if (pos === -1) {
				selectedImageIndex = tripSnapShotsList.length - 1
			}
			// $('slick-slider').slickGoTo(selectedImageIndex);
			// console.log('slickSliderRef1: ', slickSliderRef, slickSliderRef.current)
			if (slickSliderRef && slickSliderRef.current !== null) {
				slickSliderRef.slickGoTo(selectedImageIndex);
			}
			setSelectedImageIndex(selectedImageIndex);
		}
	}, [tripTimelineTimestamp])

	useEffect(() => {
		if (tripSnapShotsList.length) {
			// debugger
			// console.log('componentWillReceiveProps TripSnapshots nextProps.tripTimelineTimestamp: ', nextProps.tripTimelineTimestamp)
			// _.orderBy(tripSnapShotsList, ['ts'], ['asc'])

			const newTimelineTimestamp = tripPlaybackTimestamp;

			const pos = _.orderBy(tripSnapShotsList, item => moment(item.ts).valueOf(), ['asc']).findIndex(item => (
				moment(item.ts) >= moment(newTimelineTimestamp)
			));

			// console.log('POS!!! ', newTimelineTimestamp, pos, tripSnapShotsList[pos])

			let selectedImageIndex = null
			if (pos === 0 && newTimelineTimestamp === tripSnapShotsList[pos].ts) {
				selectedImageIndex = pos
			}
			if (pos > 0) {
				if (newTimelineTimestamp === tripSnapShotsList[pos].ts) {
					selectedImageIndex = pos
				}
				else {
					selectedImageIndex = pos - 1
				}
			}
			else if (pos === -1) {
				selectedImageIndex = tripSnapShotsList.length - 1
			}
			// $('slick-slider').slickGoTo(selectedImageIndex);
			// console.log('slickSliderRef2: ', slickSliderRef, slickSliderRef.current)
			if (slickSliderRef && slickSliderRef.current !== null) {
				slickSliderRef.slickGoTo(selectedImageIndex);
			}
			setSelectedImageIndex(selectedImageIndex);
		}
	}, [tripPlaybackTimestamp])

	const getSnapshots = (routeId = null) => {
		const tripRouteId = routeId || selectedTrip.route_id

		toggleLoader(true);
		apiCallGet('/devices/routes/{route_id}/snapshots', { route_id: tripRouteId })
			.then((res) => {
				toggleLoader(false);
				const data = res.data.response.snapshots;
				data.map(snapshot => snapshot.ts = snapshot.ts + '+00');
				setTripSnapShotsList(data);
			})
			.catch((error) => {
				toggleLoader(false);
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
				console.log('TripSnapshots getSnapshots error: ', error)
			})
	}

	const myResizeObserver = new ResizeObserver((entries) => {
		for (const entry of entries) {
			const cr = entry.contentRect;
			// console.log('Element:', entry.target);
			// console.log(`Element size: ${cr.width}px x ${cr.height}px`);
			// console.log(`Element padding: ${cr.top}px ; ${cr.left}px`);
			const count = Math.trunc(cr.width / 250)
			// console.log('count: ', count)

			setSlidesToShow(count)
		}
	});

	const onTripSnapshotsToggle = (e) => {
		e.preventDefault()
		setShowImagesWrapper(!showImagesWrapper)
	}

	const onImageClick = (imageIndex) => {
		const { setTripTimelineTimestamp } = props

		let index = imageIndex
		if (selectedImageIndex === imageIndex) {
			index = null
		}
		// console.log('onImageClick index: ', index)

		setSelectedImageIndex(index);
		setShowEventSnapshot(true);
		// moves vehicle icon on the google map to the snapshot timestamp
		if (index !== null) {
			setTripTimelineTimestamp(tripSnapShotsList[index].ts)
		}
	}

	const settings = {
		dots: false,
		infinite: false,
		speed: 400,
		slidesToShow,
		slidesToScroll: 1,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		// afterChange: (q,w,e) => console.log('afterChange: ', q,w,e),
		// onInit: (q,w,e) => console.log('onInit: ', q,w,e),
		// onReInit: (q,w,e) => console.log('onReInit: ', q,w,e),
		// onSwipe: (q,w,e) => console.log('onSwipe: ', q,w,e),
	}

	let selectedTripSnapshots = {}
	if (activeTreeItem?.device_id && tripSnapShotsList.length && selectedImageIndex !== null && selectedImageIndex > -1) {
		// debugger
		// if (! tripSnapShotsList[selectedImageIndex] === 'undefined') {
		// 	debugger
		// }
		// console.log('TripSnapshots activeTreeItem, selectedImageIndex, tripSnapShotsList: ', activeTreeItem, selectedImageIndex, tripSnapShotsList)
		selectedTripSnapshots = {
			device_id: activeTreeItem.device_id,
			timestamp: tripSnapShotsList[selectedImageIndex]?.ts,
			type: tripSnapShotsList[selectedImageIndex]?.type,
			id: tripSnapShotsList[selectedImageIndex]?.event_id,
		}
		tripSnapShotsList[selectedImageIndex]?.urls.forEach((item) => {
			selectedTripSnapshots['snapshot_' + item.channel] = item.url
		})

		// console.log('selectedTripSnapshots: ', selectedTripSnapshots)
	}

	return (
		<div className="trip-snapshots">
			<div className="trip-snapshots-toggle">
				<Link to="/" onClick={onTripSnapshotsToggle}>
					{/* <span className={`glyphicon glyphicon-chevron-${showImagesWrapper ? 'down' : 'up'}`} /> */}
					{showImagesWrapper ? <FaChevronDown size="20px" /> : <FaChevronUp size="20px" />}
				</Link>
			</div>
			{showImagesWrapper && (
				<div className="images-wrapper">
					{tripSnapShotsList.length
						? (
							<Slider
								// ref={slider => (this.slider = slider)}
								ref={elem => (slickSliderRef = elem)}
								{...settings}
							>
								{
									tripSnapShotsList.map((item, index) => {
										return (
											<div
												key={index}
												className={`image-block${hoverImageIndex === index ? ' hover' : ''}${selectedImageIndex === index ? ' active' : ''}`}
											>
												<img
													src={item.urls[0].url}
													alt=""
													onMouseEnter={() => setHoverImageIndex(index)}
													onMouseLeave={() => setHoverImageIndex(null)}
													onClick={() => onImageClick(index)}
												/>
												{selectedImageIndex !== index &&
													<div className="time-wrapper">
														{momenttz(`${item.ts}`).format('h:mm:ss a')}
														{item.type === 'event' &&
															<RiAlertFill className="glyphicon-alert" />
														}
													</div>
												}

											</div>
										)
									})
								}
							</Slider>
						)
						: (
							<div className="no-snapshots">No Snapshots</div>
						)
					}
				</div>
			)}

			{showEventSnapshot && selectedImageIndex !== null && (
				<EventViewSnapshotModal
					event={selectedTripSnapshots.type && selectedTripSnapshots.type === 'event' && selectedTripSnapshots}
					tripSnapshots={selectedTripSnapshots}
					onHide={() => setShowEventSnapshot(false)}
				/>
			)}
		</div>
	)
}

const mapStateToProps = ({ dashboardData }) => ({
	selectedTrip: dashboardData.selectedTrip,
	tripTimelineTimestamp: dashboardData.tripTimelineTimestamp,
	tripPlaybackTimestamp: dashboardData.tripPlaybackTimestamp
});

const mapDispatchToProps = dispatch => ({
	setTripTimelineTimestamp: (state) => {
		dispatch({ type: 'SET_TRIP_TIMELINE_TIMESTAMP', payload: state })
	},
	toggleLoader: (show) => {
		dispatch({ type: 'TOGGLE_LOADER', payload: show });
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(TripSnapshots);
