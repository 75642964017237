import ReactGA from 'react-ga'

// FWSD-5071
export const isCustomUserPermissions = (userRole, userPermissions, permissionType) => {
	if (['custom_user', 'custom_partner'].includes(userRole)) {
		if (!userPermissions || !permissionType) {
			return false
		}
		
		switch (permissionType) {
			case 'settings_profile_view':
				return userPermissions.find(({ perm_category_id, a_view, a_add, a_edit, a_delete }) => perm_category_id === 15 && (a_view || a_add || a_edit || a_delete))
			case 'settings_profile_add':
				return userPermissions.find(({ perm_category_id, a_add, a_edit, a_delete }) => perm_category_id === 15 && (a_add || a_edit || a_delete))
			case 'settings_profile_edit':
				return userPermissions.find(({ perm_category_id, a_edit, a_delete }) => perm_category_id === 15 && (a_edit || a_delete))
			case 'settings_profile_delete':
				return userPermissions.find(({ perm_category_id, a_delete }) => perm_category_id === 15 && a_delete)
			case 'user_add':
				return userPermissions.find(({ perm_category_id, a_add, a_edit, a_delete }) => perm_category_id === 16 && (a_add || a_edit || a_delete))
			case 'user_edit':
				return userPermissions.find(({ perm_category_id, a_edit, a_delete }) => perm_category_id === 16 && (a_edit || a_delete))
			case 'user_delete':
				return userPermissions.find(({ perm_category_id, a_delete }) => perm_category_id === 16 && (a_delete))
			case 'vehicle_edit':
				return userPermissions.find(({ perm_category_id, a_edit, a_delete }) => perm_category_id === 5 && (a_edit || a_delete))
			case 'settings_profile_disabledBtn':
				return !(_.find(userPermissions.filter(e => e.perm_category_name !== 'Other'), { a_view: true }).perm_category_id === 15)
			case 'event_feedback_add':
				return userPermissions.find(({ perm_category_id, a_add }) => perm_category_id === 18 && (a_add))
			case 'event_feedback_edit':
				return userPermissions.find(({ perm_category_id, a_edit }) => perm_category_id === 18 && (a_edit))
		}

		return false
	}

	return true
}

// FWSD-6999
export const sendGA = ({ category, action, label }) => {
	if (process.env.NODE_ENV === 'production') {
		ReactGA.event({
			category,
			action,
			label,
		})
	}
}