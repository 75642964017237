import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import ManageAssetsUsersContainer from '../../smart/menuComponentsContainer/ManageAssetsUsersContainer';
import SearchBar from '../../SearchBar'
import ManageUsersCreateUser from './ManageUsersCreateUser'

import { isCustomUserPermissions } from '../../helpers/helperUsers'
import { getAllUsers, getUsers } from '../../../action/ClientActions'

import './menuComponents.sass'
import GreyHeader from '../../GreyHeader'
import Toggle from 'react-toggle';

const ManageUsers = (props) => {

	const { user, company, toggleLoader } = props;
	const userRole = user.roles[0]
	const userPermissions = user.permissions

	const [searchText, setSearchText] = useState('');
	const [initialData, setInitialData] = useState([]);
	const [users, setUsers] = useState([]);
	const [allUsers, setAllUsers] = useState(false);

	useEffect(() => {
		if (company && company.company_id) {
			setSearchText('');
			changedUsersList();
		}
	}, [company.company_id, allUsers])

	const dataSearch = (e) => {
		const value = e.target.value.toLowerCase()
		const filter = initialData.filter(user => {
			return (
				user.first_name && user.first_name.toLowerCase().includes(value) ||
				user.last_name && user.last_name.toLowerCase().includes(value) ||
				user.first_name && user.last_name && (user.first_name + " " + user.last_name).toLowerCase().includes(value) ||
				user.email && user.email.toLowerCase().includes(value)
			)
		})

		setUsers(filter)
		setSearchText(value)
	}

	const changedUsersList = () => {
		toggleLoader(true);

		if (allUsers && ['system_admin', 'customer_service'].includes(userRole)) {
			getAllUsers()
				.then(res => {
					toggleLoader(false);
					const initUsers = res.data.response.users.filter(({is_fake}) => !is_fake)
					setUsers(initUsers)
					setInitialData(_.clone(initUsers))
				})
				.catch(err => {
					toggleLoader(false);
					toastr.error('There was an issue getting the available users. Please try again later.')
				})
		}
		else {
			getUsers(userRole, { name: company.name, partner: company.partner })
				.then((res, err) => {
					toggleLoader(false);
					const initUsers = res.data.response.users.filter(({is_fake}) => !is_fake)
					setUsers(initUsers)
					setInitialData(_.clone(initUsers))
				})
				.catch((error) => {
					toggleLoader(false);
					toastr.error('There was an issue getting the available users. Please try again later.')
				});
		}
	}

	return (
		<div>
			<GreyHeader title='Users' />

			<div className='page-subheader'>
				<div className='subheader-section search'>
					<SearchBar term={searchText} onSearch={dataSearch} />
				</div>
				{['system_admin', 'customer_service'].includes(userRole) &&
					<div className='subheader-section align-center'>
						<label>
							<Toggle
								name="select-all"
								checked={allUsers}
								onChange={(e) => setAllUsers(e.target.checked)}
							/>
							<span>All Users</span>
						</label>
					</div>
				}
			</div>

			<main className='manage-users main-content-block'>
				{(
					!['group_manager', 'sales_demo', 'custom_partner'].includes(userRole)
					|| isCustomUserPermissions(userRole, userPermissions, 'user_add')
					|| isCustomUserPermissions(userRole, userPermissions, 'user_edit')
					|| isCustomUserPermissions(userRole, userPermissions, 'user_delete'))
					&&
					<ManageUsersCreateUser
						changedUsersList={changedUsersList}
						user_role={userRole}
						company={company}
					/>
				}
				<ManageAssetsUsersContainer
					users={users}
					changedUsersList={changedUsersList}
					allUsers={allUsers}
				/>
			</main>
		</div>
	)
}

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(ManageUsers);
