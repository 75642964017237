import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import FleetAdminDriverContainer from '../../component/smart/menuComponentsContainer/FleetAdminDriverContainer'
import DriversContainer from './DriversContainer'
import './Drivers.sass'

const ManageDriversPage = ({ company, location }) => {
	return (
		company.driver_recognition === 'faceid' 
			? <FleetAdminDriverContainer />
			: <DriversContainer location={location}/>
	)
};

ManageDriversPage.propTypes = {
	company: PropTypes.objectOf(PropTypes.any).isRequired
};

export default connect(
	state => ({
		company: state.company.company
	})
)(ManageDriversPage);
