import _ from 'lodash'

export const defaultConfiguration = {
	Channels: [
		{
			index: 0,
			ParkingQuality: 1,
			Substream: true,
			Enabled: true,
			ParkingFPS: 1,
			Record: 1,
			MainQuality: 2,
			MainFPS: 15,
			Audio: 0,
		},
		{
			index: 1,
			ParkingQuality: 1,
			Substream: true,
			Enabled: true,
			ParkingFPS: 1,
			Record: 1,
			MainQuality: 2,
			MainFPS: 15,
			Audio: 1,
		},
		{
			index: 2,
			ParkingQuality: 1,
			Substream: true,
			Enabled: false,
			ParkingFPS: 1,
			Record: 1,
			MainQuality: 2,
			MainFPS: 15,
			Audio: 1,
		},
		{
			index: 3,
			ParkingQuality: 1,
			Substream: true,
			Enabled: false,
			ParkingFPS: 1,
			Record: 1,
			MainQuality: 2,
			MainFPS: 15,
			Audio: 1,
		},
	],
	Debug: {
		LcdDisplayMode: 0,
		UseLegacyLive: false,
	},
	Overlay: {
		ShowSpeed: true,
	},
	General: {
		TimeZone: 'US/Eastern',
		IgnitionShutdownDelay: 60,
	},
	Event: {
		Accelerometer: {
			Enable: {
				Y: true,
				X: true,
				Z: false,
			},
			TriggerOutput: -1,
			FatalThreshold: {
				Y: 1.2,
				X: 1.2,
				Z: 2,
			},
			Enabled: true,
			ParkingThreshold: {
				Y: 0.2,
				X: 0.2,
				Z: 1.2,
			},
			SendToCloud: false,
			FatalSendToCloud: true,
			Threshold: {
				Y: 10.0,
				X: 10.0,
				Z: 10.0,
			},
			FatalTriggerOutput: -1,
		},
		PanicButton: {
			Enabled: true,
		},
		Adas: {
			Pedestrian: {
				SendToCloud: false,
				Enabled: false,
			},
			SpeedThreshold: 0,
			LaneDeparture: {
				SendToCloud: false,
				Enabled: false,
			},
			Headway: {
				SendToCloud: false,
				Enabled: false,
			},
			ForwardCollision: {
				SendToCloud: false,
				Enabled: false,
			},
		},
		HighSpeed: {
			Threshold: 112.65,
			SendToCloud: false,
			Enabled: false,
			TriggerOutput: -1,
		},
		Voltage: {
			HighThreshold: 32,
			ShutdownDelay: 20,
			LowThreshold: 11.4,
			Enabled: true,
			RecoveryThreshold: 11.6,
		},
		BuzzerEnabled: false,
		Sensor: [
			{
				index: 0,
				SendToCloud: false,
				Name: 'Sensor #1',
				TriggerOutput: -1,
				ActiveHigh: true,
				Enabled: false,
				ShortName: 'S1',
			},
			{
				index: 1,
				SendToCloud: false,
				Name: 'Sensor #2',
				TriggerOutput: -1,
				ActiveHigh: true,
				Enabled: false,
				ShortName: 'S2',
			},
			{
				index: 2,
				SendToCloud: false,
				Name: 'Sensor #3',
				TriggerOutput: -1,
				ActiveHigh: true,
				Enabled: false,
				ShortName: 'S3',
			},
			{
				index: 3,
				SendToCloud: false,
				Name: 'Sensor #4',
				TriggerOutput: -1,
				ActiveHigh: true,
				Enabled: false,
				ShortName: 'S4',
			},
			{
				index: 4,
				SendToCloud: true,
				Name: 'External Driver Event',
				TriggerOutput: -1,
				Enabled: true,
				ShortName: 'EE',
			},
			{
				index: 8,
				SendToCloud: true,
				TriggerOutput: -1,
			},
		],
		HighTemperature: {
			Threshold: 99,
			ShutdownDelay: 150,
			Enabled: true,
		},
		Dms: {
			Distraction: {
				Enabled: false,
				SendToCloud: false,
			},
			PhoneUse: {
				Enabled: false,
				SendToCloud: false,
			},
			Enabled: false,
			Yawning: {
				Enabled: false,
				SendToCloud: false,
			},
			Dozing: {
				Enabled: false,
				SendToCloud: false,
			},
		},
	},
	Servers: {
		Ads: {
			ShutdownDelay: 480,
		},
	},
	System: {
		SpeedUnit: 1,
		BacklightLow: 100,
		BacklightHigh: 800,
		DisplaySpeed: false,
	},
	Recording: {
		LockEventFiles: false,
	},
	Peripherals: {
		Mobileye: {
			Enabled: false,
		},
		Dms: {
			Enabled: false,
		},
	},
}

export const defaultConfigurationDv6 = {
	Channels: [
		{
			index: 0,
			ParkingQuality: 1,
			Substream: true,
			Enabled: true,
			ParkingFPS: 1,
			Record: 1,
			MainQuality: 2,
			MainFPS: 24,
			Audio: 0,
		},
		{
			index: 1,
			ParkingQuality: 1,
			Substream: true,
			Enabled: true,
			ParkingFPS: 1,
			Record: 1,
			MainQuality: 2,
			MainFPS: 24,
			Audio: 1,
		},
		{
			index: 2,
			ParkingQuality: 1,
			Substream: false,
			Enabled: false,
			ParkingFPS: 1,
			Record: 1,
			MainQuality: 2,
			MainFPS: 24,
			Audio: 1,
		},
	],
	Debug: {
		LcdDisplayMode: 0,
		UseLegacyLive: false,
	},
	Overlay: {
		ShowSpeed: true,
		ShowTZ: false,
	},
	General: {
		TimeZone: 'US/Eastern',
		IgnitionShutdownDelay: 60,
	},
	Event: {
		Adas: {
			Enabled: false,
			CameraHeight: 150,
			CameraOffset: 0.0,
			VehicleHoodLength: 150,
			VehicleWidth: 170,
			LaneDeparture: {
				SendToCloud: false,
				Enabled: false,
			},
			ForwardCollision: {
				Sensitivity: 0,
				SendToCloud: false,
				Enabled: false,
			},
			Headway: {
				SpeedUnit: 'mph',
				SpeedThreshold: 32,
				Sensitivity: 0,
				SendToCloud: false,
				Enabled: false,
				Ttc: {
					TriggerThreshold: 0.6,
				},
			},
			FollowingDistance: {
				SpeedUnit: 'mph',
				SpeedThreshold: 32,
				Sensitivity: 0,
				SendToCloud: false,
				Enabled: false,
				Ttc: {
					TriggerThreshold: 0.6,
				},
			},
		},
		Accelerometer: {
			Enable: {
				Y: true,
				X: true,
				Z: false,
			},
			TriggerOutput: -1,
			FatalThreshold: {
				Y: 1.2,
				X: 1.2,
				Z: 2,
			},
			Enabled: true,
			ParkingThreshold: {
				Y: 0.2,
				X: 0.2,
				Z: 1.2,
			},
			SendToCloud: false,
			FatalSendToCloud: true,
			Threshold: {
				Y: 10.0,
				X: 10.0,
				Z: 10.0,
			},
			FatalTriggerOutput: -1,
		},
		HarshAccel: {
			Threshold: 9.5,
			SendToCloud: true,
			Enabled: true,
		},
		HarshBraking: {
			Threshold: 11,
			SendToCloud: true,
			Enabled: true,
		},
		HighSpeed: {
			Threshold: 112.65,
			SendToCloud: false,
			Enabled: false,
			TriggerOutput: -1,
		},
		Voltage: {
			HighThreshold: 32,
			ShutdownDelay: 20,
			LowThreshold: 11.5,
			Enabled: true,
			RecoveryThreshold: 12.0,
		},
		Sensor: [
			{
				index: 0,
				SendToCloud: false,
				Name: 'Sensor #1',
				TriggerOutput: -1,
				ActiveHigh: true,
				Enabled: false,
				ShortName: 'S1',
			},
			{
				index: 1,
				SendToCloud: false,
				Name: 'Sensor #2',
				TriggerOutput: -1,
				ActiveHigh: true,
				Enabled: false,
				ShortName: 'S2',
			},
			{
				index: 2,
				SendToCloud: false,
				Name: 'Sensor #3',
				TriggerOutput: -1,
				ActiveHigh: true,
				Enabled: false,
				ShortName: 'S3',
			},
			{
				index: 3,
				SendToCloud: false,
				Name: 'Sensor #4',
				TriggerOutput: -1,
				ActiveHigh: true,
				Enabled: false,
				ShortName: 'S4',
			},
			{
				index: 4,
				SendToCloud: true,
				Name: 'External Driver Event',
				TriggerOutput: -1,
				Enabled: true,
				ShortName: 'EE',
			},
			{
				index: 8,
				SendToCloud: true,
				TriggerOutput: -1,
			},
		],
		HighTemperature: {
			Threshold: 99,
			ShutdownDelay: 150,
			Enabled: true,
		},
		Dms: {
			CameraObstruction: {
				Enabled: false,
			},
			Seatbelt: {
				Enabled: false,
				Sensitivity: 0,
			},
			Distraction: {
				Enabled: true,
				SendToCloud: true,
				Sensitivity: 0,
			},
			PhoneUse: {
				Enabled: true,
				SendToCloud: true,
				Sensitivity: 0,
			},
			Enabled: true,
			Yawning: {
				SendToCloud: true,
				Enabled: true,
				Sensitivity: 0,
			},
			Dozing: {
				SendToCloud: true,
				Enabled: true,
				Sensitivity: 0,
			},			
			Sensitivity: 1.00,
			HeadDown: {
				SendToCloud: true,
				Enabled: true,
				Sensitivity: 0,
			},
			Smoking: {
				Enabled: false,
				Sensitivity: 0,
			},
		},
		BuzzerEnabled: false,
	},
	Servers: {
		Ads: {
			ShutdownDelay: 480,
		},
		SleepMode: {
			Enabled: false,
		},
	},
	System: {
		SpeedUnit: 1,
		Chimes: {
			CameraObstruction: {
				Enabled: 0,
			},
			Dms: false,
			Volume: 100,
			Adas: false,
			Accelerometer: false,
			HarshBraking: false,
			HarshAccel: false,
			HighSpeed: false,
			Distraction: true,
			Yawning: true,
			Drowsiness: true,
			PhoneUse: true,
			ForwardCollision: true,
			Headway: true,
			FollowingDistance: true,
			LaneDeparture: true,
			Smoking: false,
			Seatbelt: false,
		},
	},
	Recording: {
		LockEventFiles: false,
	},
	Peripherals: {
		CanBus: {
			Type: 0,
		},
	},
}

export const defaultEventVideoTypes = {
	dv4_driver: 'video_snapshot',
	dv6_driver: 'video_snapshot',
	dv4_high_speed: 'alert_only',
	dv6_high_speed: 'alert_only',
	dv4_fatal_gsensor: 'video_snapshot',
	dv6_fatal_gsensor: 'video_snapshot',

	dv4_ext_driver: 'snapshot_only',
	dv4_distraction: 'video_snapshot',
	dv4_drowsiness: 'video_snapshot',
	dv4_phone_use: 'video_snapshot',

	dv6_harsh_braking: 'video_snapshot',
	dv6_harsh_accel: 'video_snapshot',
	dv6_distraction: 'video_snapshot',
	dv6_yawning: 'video_snapshot',
	dv6_drowsiness: 'video_snapshot',
	dv6_phone_use: 'video_snapshot',
	dv6_lane_departure: 'snapshot_only',
	dv6_forward_collision: 'snapshot_only',
	dv6_headway_monitoring: 'snapshot_only',
	dv6_following_distance: 'snapshot_only',
	dv6_seatbelt: 'video_snapshot',
	dv6_smoking: 'video_snapshot',
	dv6_camera_block: 'alert_only',
}

const traverse = (res, o, parent = '') => {
	let i

	for (const k in o) {
		i = o[k]
		if (_.isArray(i)) {
			i.map((obj, index) => {
				if (parent) {
					if (obj.index) traverse(res, obj, `${parent}.${k}.${obj.index}`)
					else traverse(res, obj, `${parent}.${k}.${index}`)
				} else if (obj.index) traverse(res, obj, `${k}.${obj.index}`)
				else traverse(res, obj, `${k}.${index}`)
			})
		} else if (typeof i === 'object') {
			if (parent) traverse(res, i, `${parent}.${k}`)
			else traverse(res, i, k)
		} else {
			if (k == 'index') continue
			if (parent) {
				res[`${parent}.${k}`] = i
			} else {
				res[k] = i
			}
		}
	}
}

export const fillConfig = (config, profile) => {
	const res1 = {};
	const res2 = {}

	config.map((elem, index) => {
		const tmp = elem.split('=')
		const val = _.trim(tmp[1]).replace(/"/g, '')

		if (_.trim(tmp[0]) === '') {
			return false
		} if (val === '') {
			res1[_.trim(tmp[0])] = ''
		} else if (val === 'true') {
			res1[_.trim(tmp[0])] = true
		} else if (val === 'false') {
			res1[_.trim(tmp[0])] = false
		} else if (isNaN(val)) {
			res1[_.trim(tmp[0])] = val
		} else {
			res1[_.trim(tmp[0])] = val * 1
		}
	})

	profile = JSON.parse(profile)

	traverse(res2, profile)

	return { ...res1, ...res2 }
}
