// it is used to store data for the Events pages

const defaultState = {
	eventsRedirect: null, // stores needed params when redirecting to the Events page (from Event Search popup)
	eventArchiveRestoring: {
		showModal: false,
		message_type: null,
		event_id: null,
	}
}

const eventsData = (state = defaultState, action) => {
	switch (action.type) {	
		case 'SET_EVENTS_REDIRECT': {
			return { ...state, eventsRedirect: action.payload };
		}
		case 'SET_EVENT_ARCHIVE_RESTORING': {
			return { ...state, eventArchiveRestoring: action.payload }
		}
		default:
			return state;
	}
}

export default eventsData
