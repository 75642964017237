/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import ReactBootstrapSlider from 'react-bootstrap-slider';
import { Button, Dropdown } from 'react-bootstrap';
import classnames from 'classnames';
import axios from "axios";
import moment from 'moment';
import { connect } from 'react-redux';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import { browserHistory } from 'react-router'
import { toastr } from 'react-redux-toastr';
import { isMobileOnly } from 'react-device-detect';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import $ from 'jquery'
// import screenfull from 'screenfull'
import { RxEnterFullScreen, RxExitFullScreen } from "react-icons/rx"

import SpeedGraph from './AdvancedPlayer/SpeedGraph'
import GsensorGraph from './AdvancedPlayer/GsensorGraph'
import SimpleMapExampleGoogleMap from './AdvancedPlayer/SimpleMapExampleGoogleMap'
import SimpleMapExampleHEREMap from './AdvancedPlayer/SimpleMapExampleHEREMap'
import OverlayTriggerNode from './AdvancedPlayer/OverlayTriggerNode'
import ModalEventLabel from './smart/modals/ModalEventLabel'
import EventFeedback from './EventFeedback'
import CustomButton from './CustomButton'
import { CustomButtonToggle, CustomMenu } from './DropdownComponents'
import ModalRequestContextVideo from './smart/modals/ModalRequestContextVideo'
import ModalCoachEvent from '../pages/drivers/ModalCoachEvent'

import { addAuditLogFunc } from './helpers/helperAuditLog'
import { eventIcons, moreActionsIcon, videoPlayerIcons } from './helpers/iconHelpers'
import { isInternalUser } from './helpers/constants'
import { getDirection } from './helpers/labels'
import { eventTypeDetection } from './helpers/helperEvents'
import { sendGA, isCustomUserPermissions } from './helpers/helperUsers'

import { addDriverToEvent, getEventVideo } from '../action/AlertActions'
import { apiCallGet } from '../action/RouterActions'
import { getDeviceById } from '../action/DeviceActions'

import logo from '../../../assets/rosco-logo-horizontal.jpg'
import './smart/webplayer/webplayer.sass'
import 'bootstrap-slider/dist/css/bootstrap-slider.css'

let vid1, vid2, vid3, vid4;
const vidDeviationControl = () => {
	if (
		(!vid2.ended && Math.abs(vid1.currentTime - vid2.currentTime) > 0.2) ||
		(vid3 && !vid3.ended && Math.abs(vid1.currentTime - vid3.currentTime) > 0.2) ||
		(vid4 && !vid4.ended && Math.abs(vid1.currentTime - vid4.currentTime) > 0.2)
	) {
		cancelAnimationFrame(vidDeviationControl);

		$('#player-video video').each(function () {
			this.pause();
		});

		// set all 3 before new AnimationFrame is drawn.
		if (vid4) {
			vid4.currentTime = vid1.currentTime;
			vid3.currentTime = vid4.currentTime;
			vid2.currentTime = vid3.currentTime;
			vid1.currentTime = vid2.currentTime;
		}
		else if (vid3) {
			vid3.currentTime = vid1.currentTime;
			vid2.currentTime = vid3.currentTime;
			vid1.currentTime = vid2.currentTime;
		}
		else {
			vid2.currentTime = vid1.currentTime;
			vid1.currentTime = vid2.currentTime;
		}

		$('#player-video video').each(function () {
			this.play();
		});
	}

	if (vid1.paused || vid1.ended) {
		cancelAnimationFrame(vidDeviationControl);
		return;
	}
	else {
		requestAnimationFrame(vidDeviationControl);
	}
}

const AdvancedPlayer = (props) => {
	const { user, company, event, isFlagged, onFlagEvent, openEventNote, openShareEvent, onSaveEventLabel, loaderModal, toggleLoaderModal, getFleetEvents } = props;
	const userRole = user.roles[0];
	const userPermissions = user.permissions

	const [intervalPlayback, setIntervalPlayback] = useState(null);
	const [windowHeight, setWindowHeight] = useState(560);
	const [windowWidth, setWindowWidth] = useState(1060);

	const [parentObj, setParentObj] = useState(null);
	const [videoUrls, setVideoUrls] = useState([]);
	const [nvrUrl, setNvrUrl] = useState('');
	const [header, setHeader] = useState({});
	const [metadata, setMetadata] = useState([]);
	const [maxCollection, setMaxCollection] = useState();
	const [coordinates, setCoordinates] = useState({ lat: -34.397, lng: 150.644 });
	const [direction, setDirection] = useState(0);
	const [dir, setDir] = useState('');
	// const [starttimestamp, setStarttimestamp] = useState('');
	// const [timestamp, setTimestamp] = useState(0);
	const [state, setState] = useState({
		starttimestamp: '',
		timestamp: 0,
	});
	const [polyline, setPolyline] = useState([]);
	const [acc, setAcc] = useState([]);
	const [speed, setSpeed] = useState(0);
	const [driver, setDriver] = useState('');
	const [meye, setMeye] = useState({
		"error": false,
		"failsafe": false,
		"fcw": false,
		"hw": 0,
		"hwWarning": 0,
		"ldw": true,
		"ldwLeft": false,
		"ldwRight": false,
		"pedDz": false,
		"pedForward": false,
		"speedWarning": 0,
		"tamper": false
	});
	const [activeChannel, setActiveChannel] = useState(1);
	const [channelAudio, setChannelAudio] = useState(2);
	const [play, setPlay] = useState(false);
	const [playbackRate, setPlaybackRate] = useState(1.0);
	const [fps, setFps] = useState(24);
	const [value, setValue] = useState(0);
	const [max, setMax] = useState(null);

	const [volume_value, setVolume_value] = useState(0.5);
	const [videos, setVideos] = useState([
		{ brightness: 1, contrast: 1, audio: false },
		{ brightness: 1, contrast: 1, audio: false },
		{ brightness: 1, contrast: 1, audio: false },
		{ brightness: 1, contrast: 1, audio: false },
	]);

	const [showChannelsSelection, setShowChannelsSelection] = useState(false);
	const [showRequestContextVideo, setShowRequestContextVideo] = useState(false);
	const [showCoachEventModal, setShowCoachEventModal] = useState(false);
	const [showAddDriver, setShowAddDriver] = useState(false);
	const [showEventLabel, setShowEventLabel] = useState(false);
	const [availableDrivers, setAvailableDrivers] = useState([]);
	const [device, setDevice] = useState({});
	const [accidentTime, setAccidentTime] = useState('');
	const [view, setView] = useState(localStorage.getItem('playerView') || 'default');
	const [playerFullScreen, setPlayerFullScreen] = useState(false)

	const prevViewRef = useRef(localStorage.getItem('playerView') || 'default');

	useEffect(() => {
		//* Only for iOS devices display channel selection to playback video because they can not to mutiple playback
		if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) || window.innerWidth < 768) {
			setShowChannelsSelection(true)
		}
		//******
		if ((window.innerWidth / window.innerHeight) > 1.899) {
			setWindowHeight(window.innerHeight * 0.875)
			setWindowWidth(window.innerHeight * 0.875 * 1.899)
		}
		else {
			setWindowHeight(window.innerWidth * 0.875 * 0.526)
			setWindowWidth(window.innerWidth * 0.875)
		}

		toggleLoaderModal(true);

		document.addEventListener('mousedown', handleClickOutside);
		// FWSD-6003, Full Screen View
		document.addEventListener('fullscreenchange', onFullScreenChange)
		document.addEventListener('webkitfullscreenchange', onFullScreenChange)
		document.addEventListener('mozfullscreenchange', onFullScreenChange)
		document.addEventListener('MSFullscreenChange', onFullScreenChange)

		getEventDriver()

		getEventVideo({
			user_role: userRole,
			event_id: event.id,
			type: 'all',
			company_name: company.name,
			partner_name: company.partner
		})
			.then((res, err) => {

				if (res.data.response.error) {
					toggleLoaderModal(false);
					toastr.error('Unable to get Event Video. Please try again later');
				} else {
					const { video_urls, nvr_url } = res.data.response;

					setVideoUrls(video_urls)
					setNvrUrl(nvr_url)
					const firstEvent = event.type && event.type.split(',')[0]
					if (['External Driver', 'Driver', 'Drowsiness', 'Distraction', 'Phone Use', 'Yawning', 'Phone Use/Hand to Head', 'Drowsiness/Head Down', 'Seatbelt', 'Smoking'].includes(firstEvent) && video_urls.length > 1) {
						setActiveChannel(2)
					}

					let maxCollection = []
					let playersLoaded = 0;

					$('#player-video video').on('loadeddata', (e) => {
						if (hasAudio(e.target)) {
							const channelNumber = $(e.target).attr("data-channel")
							let videosUpdate = videos;
							videosUpdate[channelNumber - 1].audio = true
							setVideos(videosUpdate)
						}

						maxCollection.push({ videoObj: e.target, duration: e.target.duration });
						playersLoaded++;
						if (playersLoaded == video_urls.length) {
							setMaxCollection(maxCollection);
							setPlayerEvents(maxCollection);
						}
					});
					axios(res.data.response.metadata_url)
						.then((res, err) => {
							console.log('metadata.json res', res);

							let polyline = []
							let acc = []
							let metadata = res.data.metadata

							metadata = _.orderBy(metadata, ['t']);

							const entry = _.find(res.data.metadata, function (item, i) {
								return item.gps && item.gps.lat && item.gps.lon;
							})
							metadata.map((item, i) => {

								if (item.gps && item.gps.lat && item.gps.lon) {
									polyline.push({ "lat": item.gps.lat, "lng": item.gps.lon });
								}

								if (item.acc) {
									acc.push(item.acc);
								}
							});
							acc = _.orderBy(acc, ['t']);

							setHeader(res.data.header) //res.data.header
							setMetadata(metadata) //res.data.metadata
							setPolyline(polyline)
							setAcc(acc)
							setState((prevState) => ({
								starttimestamp: (res.data.header.date && res.data.header.startTime) ? moment.utc(res.data.header.date + ' ' + res.data.header.startTime).valueOf() : '',
								timestamp: (res.data.header.date && res.data.header.startTime) ? moment.utc(res.data.header.date + ' ' + res.data.header.startTime).valueOf() : 0,
							}))
							if (entry) {
								setCoordinates({ lat: entry.gps.lat, lng: entry.gps.lon });
								setDir(getDirection(entry.direction));
								setDirection(entry.direction);
							}

						})
						.catch((error) => {
							console.log('error', error);
						})

				}
			})
			.catch((error) => {
				toggleLoaderModal(false);
				console.log(error);

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
			// FWSD-6003, Full Screen View
			document.removeEventListener('fullscreenchange', onFullScreenChange)
			document.removeEventListener('webkitfullscreenchange', onFullScreenChange)
			document.removeEventListener('mozfullscreenchange', onFullScreenChange)
			document.removeEventListener('MSFullscreenChange', onFullScreenChange)

			if (intervalPlayback) {
				clearInterval(intervalPlayback);
				setIntervalPlayback(null);
			}
		}
		// }, [!!timestamp, view]);
	}, []);

	useEffect(() => {
		if (prevViewRef.current !== view) {
			// debugger
			setPlayerEvents(maxCollection, true)
			prevViewRef.current = view
		}
	}, [view])

	useEffect(() => {
		// if (value) {
		const ts = value
		const metadataUpdate = metadata;

		let ind = 0;
		var entry = _.find(metadataUpdate, function (item, i) {
			ind = i;
			return (item.t - metadataUpdate[0].t) >= (ts * 1000);
		})

		if (entry) {
			let newSpeed = entry.speed || 0;
			if (!newSpeed) {
				const speed_entry = _.find(metadataUpdate, function (item, i) {
					return ((item.t - metadataUpdate[0].t) >= (ts * 1000 + 1000)) || (item.speed > 0);
				}, ind)

				if (speed_entry) {
					newSpeed = speed_entry.speed || 0;
				} else {
					newSpeed = 0;
				}
			}
			setSpeed(Math.round(newSpeed * ((user.speed_unit === 'kph') ? 1 : .62)));

			let gps = entry.gps || 0;
			if (!gps) {
				const gps_entry = _.find(metadataUpdate, function (item, i) {
					return ((item.t - metadataUpdate[0].t) >= (ts * 1000 + 500)) || (item.gps);
				}, ind)

				if (gps_entry && gps_entry.gps) {
					setCoordinates({ lat: gps_entry.gps.lat, lng: gps_entry.gps.lon });
				}
			}
			else {
				setCoordinates({ lat: gps.lat, lng: gps.lon });
			}

			if (entry.direction) {
				setDir(getDirection(entry.direction));
				setDirection(entry.direction);
			}

			if (header.mobileye) {
				let meye = entry.meye // || meye;
				if (!meye) {
					const meye_entry = _.findLast(metadataUpdate, function (item, i) {
						return /*((item.t - metadataUpdate[0].t) >= (ts*1000+1000)) ||*/ _.has(item, 'meye')
					}, ind)

					if (meye_entry && meye_entry.meye) {
						setMeye(meye_entry.meye.warn)
					}
					else {
						setMeye({
							"error": false,
							"failsafe": false,
							"fcw": false,
							"hw": 0,
							"hwWarning": 0,
							"ldw": true,
							"ldwLeft": false,
							"ldwRight": false,
							"pedDz": false,
							"pedForward": false,
							"speedWarning": 0,
							"tamper": false
						})
					}
				}
			}

			if (header.driver && !driver) props.drivers.map(driver => { if (driver.card_id === header.driver) setDriver(`${driver.first_name} ${driver.last_name}`) });
		}
	}, [state.timestamp])

	const handleClickOutside = (event) => {
		const draggable = document.getElementById('draggable') && !document.getElementById('draggable').contains(event.target);
		const modalDialog = !(document.getElementsByClassName('modal-dialog')[0] && document.getElementsByClassName('modal-dialog')[0].contains(event.target));
		const popover = !(document.getElementById('advanced-player-mp4videos') && document.getElementById('advanced-player-mp4videos').contains(event.target));
		const toastrElement = !(document.getElementsByClassName('toastr')[0] && document.getElementsByClassName('toastr')[0].contains(event.target));
		const modalDialog1 = !(document.getElementsByClassName('modal-dialog')[1] && document.getElementsByClassName('modal-dialog')[1].contains(event.target)); // The Event Feedback Thank You  modal
		if (draggable && modalDialog && popover && toastrElement && modalDialog1) {
			hideWindow();
		}
	}

	const getEventDriver = () => {
		const { event: { id } } = props
		apiCallGet('/event/{eventId}/driver', { eventId: id })
			.then((res, err) => {
				console.log('!getEventDriver res:', res, err)

				const { driver } = res.data.response
				setDriver(driver.name || '')
			})
			.catch((error) => {
				console.log('!getEventDriver error:', error.response, error)

				setDriver('')
			})
	}

	const hasAudio = (video) => {
		return video.mozHasAudio
			|| Boolean(video.webkitAudioDecodedByteCount)
			|| Boolean(video.audioTracks && video.audioTracks.length);
	}

	const setPlayerEvents = (maxCollection, forcePause) => {
		// debugger
		// console.log('!!!!setPlayerEvents', maxCollection);

		vid1 = document.getElementById("channel1");
		vid2 = document.getElementById("channel2");
		vid3 = document.getElementById("channel3");
		vid4 = document.getElementById("channel4");

		const player_video = document.getElementById('player-video');

		const obj = _.minBy(maxCollection, o => o.duration);
		setParentObj(obj)
		player_video.getElementsByTagName('video')[0].addEventListener('ended', function (e) {
			// debugger
			setPlay(false);
		}, false);

		player_video.getElementsByTagName('video')[0].addEventListener('timeupdate', function (e) {
			// debugger
			const ts = this.currentTime
			setValue(ts)
			// const metadataUpdate = metadata;
			setState((prevState) => ({ ...prevState, timestamp: prevState.starttimestamp + ts * 1000 }));
		}, false);

		toggleLoaderModal(false);

		if (!forcePause) {
			setMax(document.getElementById('player-video').getElementsByTagName('video')[0].duration)
			if (!isMobileOnly && !showChannelsSelection) {
				playVideo(false);
			}
		}

	}

	const toggleAudio = (e, player_number) => {
		e.stopPropagation();

		if (player_number) {
			videos[player_number - 1].audio && setChannelAudio(player_number);
		}
		else {
			setChannelAudio(0)
		}
	}

	const changeActiveChannel = (channelSelected) => {
		stopVideo()
		setActiveChannel(channelSelected)
	}

	const changeView = (e, view) => {
		// debugger
		stopVideo(e, 0)
		setView(view);
		// setPlayerEvents(maxCollection, true)
		localStorage.setItem('playerView', view)
	}

	const playVideo = (ff) => {
		// debugger
		clearInterval(intervalPlayback);

		if (!play || ff) {
			const playbackRateUpdate = Math.abs(playbackRate);
			setPlay(true);

			let videoSelection = '#player-video video';
			if (showChannelsSelection) {
				videoSelection = '#player-video video#channel' + channelAudio;
			}

			$(videoSelection).each(function () {
				this.playbackRate = playbackRateUpdate;
				this.play();
			});

			if (videoUrls.length > 1 && !showChannelsSelection) {
				vidDeviationControl();
			}
		} else {
			setPlay(false);

			$('#player-video video').each(function () {
				this.pause();
			});
		}
	}

	const pauseVideo = () => {
		clearInterval(intervalPlayback);
		$('#player-video video').each(function () {
			this.pause();
		});
	}

	const stopVideo = (e) => {
		clearInterval(intervalPlayback);
		$('#player-video video').each(function () {
			this.pause();
		});

		changeVideoSlider(e, 0);

		setPlay(false);
	}

	const changeVideoSlider = (e, newval) => {
		const val = (typeof (newval) != "undefined") ? newval : e.target.value;
		$('#player-video video').each(function () {
			this.currentTime = val;
		});

		setValue(val);
	}

	const downloadNVR = () => {
		if (nvrUrl) {
			addAuditLogFunc('download_nvr', { downloaded: nvrUrl })
			window.location.href = nvrUrl;
		}
	}

	const hideWindow = () => {
		$('#player-video video').each(function () {
			this.pause();
		});

		//* Remove all listeners (ended, timeupdate) in a video element?
		if (vid1 && vid1.parentNode) {
			vid1.parentNode.removeChild(vid1);
		}
		if (vid2 && vid2.parentNode) {
			vid2.parentNode.removeChild(vid2);
		}
		if (vid3 && vid3.parentNode) {
			vid3.parentNode.removeChild(vid3);
		}
		if (vid4 && vid4.parentNode) {
			vid4.parentNode.removeChild(vid4);
		}

		setTimeout(() => props.onHide(), 100);
	}

	const onResize = (event, { element, size }) => {
		setWindowWidth(Math.trunc(size.width));
		setWindowHeight(Math.trunc(size.height));
	}

	const getVideoSoundIcon = (channelNumber) => {
		const row = videos[channelNumber - 1]

		if (row.audio && channelAudio === channelNumber) {
			return (
				<CustomButton variant="empty" size="icon" id="sound" onClick={(e) => toggleAudio(e, 0)} >
					{videoPlayerIcons.soundOnIcon}
				</CustomButton >
			)
		} else if (row.audio) {
			return (
				<CustomButton variant="empty" size="icon" id="sound" onClick={(e) => toggleAudio(e, channelNumber)}>
					{videoPlayerIcons.soundOffIcon}
				</CustomButton >
			)
		}
	}

	const openRequestContextVideo = () => {
		setShowRequestContextVideo(true)
		sendGA({
			category: user.roles[0],
			action: 'Request Additional Video',
			label: 'Open Request Additional Video'
		})
	}

	const closeRequestContextVideo = () => {
		setShowRequestContextVideo(false)
	}

	const generateAccidentReport = async () => {
		toggleLoaderModal(true);
		pauseVideo();
		setPlay(false);
		await getDeviceById({ deviceId: event.device_id, userRole }).then(res => setDevice(res.data.response.device));

		const rows = {
			'Device Information': {
				'Vehicle Name': event.vehicle_name || 'Unknown',
				'Driver': driver || 'Unknown Driver',
				'VIN': device.vin_number || 'Unknown',
				'Vehicle': event.vehicle_name || 'Unknown',
				'Time of Collision': state.timestamp ? moment.utc(state.timestamp).format('h:mm:ss a') : 'Unknown'
			}
		};

		const stylePDF = {
			rows: {
				paddingTop: 50,
				paddingLeft: 5,
				fontSize: 11,
				lineHeight: 7,
				width: 30,
				chapter: {
					fontSize: 18
				}
			},
			images: {
				width: 70,
				height: 57
			}
		}

		const doc = new jsPDF()
		doc.setFontSize(10)
		doc.addImage(`${logo}?r=${Math.floor(Math.random() * 100000)}`, 'JPEG', 5, 5, 55, 15);
		doc.setFont(undefined, 'bold')
		doc.text('Rosco Vision', 55 + 15, 5 + 5);
		doc.setFont(undefined, 'normal')
		doc.text('90-21144th Place, Jamaica, New York 11435', 55 + 15, 5 + 5 + 5);
		doc.text('(718) 526-2601', 55 + 15, 5 + 5 + 5 * 2);

		let rowsCount = -2
		Object.keys(rows).forEach((itemTitle) => {
			rowsCount += 2
			doc.setFontSize(stylePDF.rows.chapter.fontSize)
			doc.setFont(undefined, 'normal')
			doc.text(itemTitle, stylePDF.rows.paddingLeft, stylePDF.rows.paddingTop + stylePDF.rows.lineHeight * rowsCount);

			doc.setFontSize(stylePDF.rows.fontSize)
			doc.setTextColor('black')
			Object.keys(rows[itemTitle]).forEach((itemCell) => {
				rowsCount += 1
				doc.setFont(undefined, 'bold')
				doc.text(itemCell + ":", stylePDF.rows.paddingLeft, stylePDF.rows.paddingTop + stylePDF.rows.lineHeight * rowsCount);
				doc.setFont(undefined, 'normal')
				doc.text(rows[itemTitle][itemCell], doc.getTextWidth(itemCell) + 10, stylePDF.rows.paddingTop + stylePDF.rows.lineHeight * rowsCount);
			})
		});

		setAccidentTime(moment(state.timestamp).format('LLLL'));

		await html2canvas(document.querySelector("#captureSpeed")).then(canvas => {
			doc.addImage(
				canvas.toDataURL("image/jpeg", 1.0),
				'JPEG',
				stylePDF.rows.paddingLeft + 115 + 10,
				105,
				stylePDF.images.width,
				stylePDF.images.height
			);
		});

		await html2canvas(document.querySelector("#captureGsensor")).then(canvas => {
			doc.addImage(
				canvas.toDataURL("image/jpeg", 1.0),
				'JPEG',
				stylePDF.rows.paddingLeft + 115 + 10,
				110 + 57,
				stylePDF.images.width,
				stylePDF.images.height
			);
		});

		// if (document.querySelector('#player-map').offsetWidth > 325) document.querySelector('#player-map').style.width = "325px";
		document.querySelector('#player-map').style.border = "none";
		$('.gm-svpc').hide();
		$('.gmnoprint').hide();
		$('a').hide();

		await html2canvas(document.querySelector("#player-map"), {
			useCORS: true,
			useOverflow: false,
			optimized: false,
			allowTaint: false,
			ignoreElements: node => node.nodeName === 'BUTTON'
		}).then(canvas => {
			doc.addImage(
				canvas.toDataURL("image/jpeg", 1.0),
				'JPEG',
				stylePDF.rows.paddingLeft + 10,
				105,
				100,
				120
			);
		});

		// document.querySelector('#player-map').style.width = '100%';
		document.querySelector('#player-map').style.borderTop = "2px solid #4c4c4";
		$('.gm-svpc').show();
		$('.gmnoprint').show();
		$('a').show();

		doc.setFontSize(15);
		doc.setFont(undefined, 'bold');
		doc.text('Disclaimer', 5, 239);

		doc.setFontSize(10);
		doc.setFont(undefined, 'normal')
		doc.text('This report is for illustrative purposes only. This report was compiled using data reported by the Dual-Vision camera.', 5, 246);
		doc.text('Acceleration, speed, g-force, and GPS location are subject to inherent uncertainties and measurement errors which may', 5, 251);
		doc.text('lead to certain inaccuracies in this report. This reporting feature is still under development and performance is uncertain.', 5, 256);
		doc.text('Rosco Vision Systems does not authenticate this report for litigation purposes. This report serves as a starting point', 5, 261);
		doc.text('for your investigation of an incident but no conclusions should be drawn from it without a trained expert to interpret it.', 5, 266);

		doc.setFontSize(15);
		doc.setFont(undefined, 'bold');
		doc.text('MARGIN OF ERRORS', 5, 275);

		doc.setFontSize(10);
		doc.setFont(undefined, 'normal')
		doc.text('GPS ± 10 ft', 5, 282);
		doc.text('Speed ± 5 mph / kph', 5, 287);
		doc.text('G-Force ± 0.05 Gs', 5, 292);

		toggleLoaderModal(false);
		doc.save('accident_report.pdf');
	}

	const openCoachEventModal = () => {
		setShowCoachEventModal(true)
	}

	const canEditNote = () => {
		if (userRole === 'sales_demo'
			|| (userRole === 'user' && event && event.created_by !== user.id)
			|| (['custom_user', 'custom_partner'].includes(userRole) && _.find(user.permissions, { 'perm_category_id': 2, 'a_edit': false }))
		) {
			return false
		}

		return true
	}

	const openSelectDriver = () => {
		toggleLoaderModal(true);
		apiCallGet('/company/{companyId}/driver', { companyId: company.company_id })
			.then(res => {
				setAvailableDrivers(res.data.response.drivers);
				toggleLoaderModal(false);
				setShowAddDriver(true);
			})
			.catch(err => {
				toggleLoaderModal(false);
				toastr.error('There was an issue getting available drivers. Please try again later')
				console.log(err);
			})
	}

	const addDriver = (driver) => {
		toggleLoaderModal(true);
		addDriverToEvent({ event_id: event.id, driver_id: driver.id })
			.then(res => {
				//UPDATE PROPS WITH NEW DRIVER NAME
				toggleLoaderModal(false);
				setShowAddDriver(false);
				setDriver(`${driver.first_name} ${driver.last_name}`);
				props.updateDriver(event.id, driver)
			})
			.catch(err => {
				toggleLoaderModal(false);
				toastr.error('There was an issue getting available drivers. Please try again later')
				console.log(err);
			})
	}

	const editVehicle = () => {
		hideWindow();
		browserHistory.push({ pathname: '/managevehicles/edit', state: { id: event.device_id, company: company, type: '/event' } });
	}


	const onPlayerFullScreenClick = () => {
		// setPlayerFullScreen(!playerFullScreen)
		const openPlayerFullScreen = () => {
			const elem = document.getElementById('player-container') || document.getElementById('player-video')
			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			} else if (elem.webkitRequestFullscreen) { /* Safari */
				elem.webkitRequestFullscreen();
			} else if (elem.msRequestFullscreen) { /* IE11 */
				elem.msRequestFullscreen();
			}
		}

		const closePlayerFullScreen = () => {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.webkitExitFullscreen) { /* Safari */
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) { /* IE11 */
				document.msExitFullscreen();
			}
		}

		if (document.fullscreenElement || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement || playerFullScreen) {
			closePlayerFullScreen()
		}
		else {
			openPlayerFullScreen()
		}
	}

	// FWSD-6003, Full Screen View
	const onFullScreenChange = () => {
		if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
			setPlayerFullScreen(false)
		}
		else {
			setPlayerFullScreen(true)
		}
	}

	const canFlagVideo = !(['custom_user', 'custom_partner'].includes(userRole) && _.find(userPermissions, { 'perm_category_id': 2, 'a_edit': false }))
	const canRequestVideo = !(['custom_user', 'custom_partner'].includes(userRole) && _.find(userPermissions, { 'perm_category_id': 2, 'a_add': false }))
	const isCoachableEvent = ['system_admin', 'customer_service', 'fleet_manager', 'partner'].includes(userRole) && ((event.driver_name && event.driver_id) || driver);
	const canEventFeedbackView = ['system_admin', 'customer_service', 'sales_demo', 'partner'].includes(userRole) 
		|| (['custom_user', 'custom_partner'].includes(userRole) && isCustomUserPermissions(userRole, userPermissions, 'event_feedback_add'))
		|| (['custom_user', 'custom_partner'].includes(userRole) && isCustomUserPermissions(userRole, userPermissions, 'event_feedback_edit'))

	const videosLength = videoUrls && videoUrls.length

	const displayTime = (value) => {
		const durationSeconds = Math.floor(value)
		if (durationSeconds > 0) {
			// console.log('displayTime value: ', durationSeconds, value)
			return moment.duration(durationSeconds, 'seconds').format()
			// return moment(Math.floor(value) * 1000).format('h:mm:ss')
			// return moment(Math.floor(value)).format('h:mm:ss')
			// return moment.duration(durationSeconds, 'seconds').format('h:*m:*s', { stopTrim: "h" })
			// return moment.duration(value, 'seconds').format('h:mm:ss', { trim: 'both' })
			// return moment.duration(clipDuration, 'seconds').format('h [hrs] m [min] s [sec]', { trim: 'both' })
		}
		else {
			return "0:00"
		}
	}

	const video1 = (<div
		className={classnames('channel channel1', { 'active': channelAudio === 1 })}
		onClick={(e) => toggleAudio(e, 1)}
		onDoubleClick={() => view === 'default' && changeActiveChannel(1)}
	>
		<h1 style={{ lineHeight: 0 }}>
			<video id="channel1" data-channel="1" muted={channelAudio != 1} preload="auto" playsInline src={videoUrls[0]} controls={false} />
			{activeChannel !== 1 && getVideoSoundIcon(1)}
		</h1>
	</div>);
	const video2 = (videosLength > 1) && (
		<div
			className={classnames('channel channel2', { 'active': channelAudio === 2 })}
			onClick={(e) => toggleAudio(e, 2)}
			onDoubleClick={() => view === 'default' && changeActiveChannel(2)}
		>
			<h1>
				<video id="channel2" data-channel="2" muted={channelAudio != 2} preload="auto" playsInline src={videoUrls[1]} controls={false} />
				{(view === 'grid' || activeChannel !== 2) && getVideoSoundIcon(2)}
			</h1>
		</div>);
	const video3 = (videosLength > 2) ? (
		<div
			className={classnames('channel channel3', { 'active': channelAudio === 3 })}
			onClick={(e) => toggleAudio(e, 3)}
			onDoubleClick={() => view === 'default' && changeActiveChannel(3)}
		>
			<h1>
				<video id="channel3" data-channel="3" muted={channelAudio != 3} preload="auto" playsInline src={videoUrls[2]} controls={false} />
				{(view === 'grid' || activeChannel !== 3) && getVideoSoundIcon(3)}
			</h1>
		</div>) :
		(view === 'default' && <div className='empty-channel' />);
	const video4 = (videosLength > 3) ? (
		<div
			className={classnames('channel channel4', { 'active': channelAudio === 4 })}
			onClick={(e) => toggleAudio(e, 4)}
			onDoubleClick={() => view === 'default' && changeActiveChannel(4)}
		>
			<h1>
				<video id="channel4" data-channel="4" muted={channelAudio != 4} preload="auto" playsInline src={videoUrls[3]} controls={false} />
				{(view === 'grid' || activeChannel !== 4) && getVideoSoundIcon(4)}
			</h1>
		</div>) :
		((view === 'default' || videosLength === 3) && <div className='empty-channel' />);

	const getVideoChannel = (position) => {
		if (view === 'grid') {
			if (position === 1) return video1;
			if (position === 2) return video2;
			if (position === 3) return video3;
			if (position === 4) return video4;
		}
		switch (activeChannel) {
			case 1:
				if (position === 1) return video1;
				if (position === 2) return video2;
				if (position === 3) return video3;
				if (position === 4) return video4;
				break
			case 2:
				if (position === 1) return video2;
				if (position === 2) return video1;
				if (position === 3) return video3;
				if (position === 4) return video4;
				break
			case 3:
				if (position === 1) return video3;
				if (position === 2) return video1;
				if (position === 3) return video2;
				if (position === 4) return video4;
				break
			case 4:
				if (position === 1) return video4;
				if (position === 2) return video1;
				if (position === 3) return video2;
				if (position === 4) return video3;
				break
		}
	}

	//* Sizing of the popup with player
	let heightPadding = 1
	if (navigator && navigator.userAgent.search("Firefox") > -1) {
		heightPadding = 10
	}
	let ActualHeight = document.getElementById('player-container') ? document.getElementById('player-container').offsetHeight + heightPadding : windowHeight
	let MapHeight = document.getElementById('event-info') ? document.getElementById('event-info').offsetHeight + 20 : '50%';

	let isXs = window.innerWidth < 992;

	const getMeyePedestrian = () => {
		let arr = []
		if (meye.pedDZ) {
			arr.push('Danger Zone')
		}
		if (meye.pedForward) {
			arr.push('Forward')
		}

		if (arr.length) {
			return arr.join(', ')
		}
		else {
			return 'None'
		}
	}

	const getMeyeSystemStatus = () => {
		let arr = []
		if (meye.error) {
			arr.push('Error code #' + meye.error)
		}
		if (meye.tamper) {
			arr.push('Tamper')
		}
		if (meye.failsafe) {
			arr.push('Failsafe')
		}

		if (arr.length) {
			return arr.join(', ')
		}
		else {
			return 'Good'
		}
	}

	// const isPlayerFullScreen = document.fullscreenElement
	// const isPlayerFullScreen = document.fullscreenElement || playerFullScreen

	// console.log('isPlayerFullScreen: ', isPlayerFullScreen)
	// console.log('AdvancedPplayer header.date: ', header.date)
	// console.log('AdvancedPplayer header.date moment: ', moment.utc(header.date).format("ddd MMM D[, ']YY"))
	// console.log('AdvancedPplayer timestamp: ', state.timestamp)
	// console.log('AdvancedPplayer timestamp: ', moment.utc(state.timestamp).format('h:mm:ss A'))
	// console.log('AdvancedPplayer metadata: ', metadata)
	// console.log('AdvancedPlayer metadata: ', metadata)
	// console.log('AdvancedPlayer coordinates: ', coordinates)
	// console.log('AdvancedPlayer direction: ', direction)

	// $('video').on('fullscreenchange webkitfullscreenchange mozfullscreenchange', function() {
	// 	this.muted = document.fullscreenElement !== this;
	//   });

	return (
		<Draggable
			handle={(isMobileOnly || isXs) ? "" : '.draggable-element'}
			bounds="body"
		>
			<div id='draggable' className="advanced-player-container alert-search-container track-trace-alert" style={{ width: windowWidth + 'px', left: `calc(50% - ${Math.trunc(windowWidth / 2)}px)`, position: 'absolute', top: '-37px', maxWidth: '100%' }}>
				<div className="modal-lg1">
					<Resizable
						height={ActualHeight}
						width={windowWidth}
						onResize={onResize}
						axis={'both'}
						minConstraints={[500, 500]}
					>
						<div className="modal-content" role="document">
							<div className="modal-body" style={{ height: (isXs && view === 'grid') ? '90vh' : (ActualHeight + 'px') }}>
								<div id="advanced-web-player" className={(view !== 'default' && videosLength === 2) && 'grid'} style={{ height: (isXs && view === 'grid') ? '90vh' : (ActualHeight + 'px') }}>
									{heightPadding < 10 &&
										<button type="button" className="close small-close-button" onClick={hideWindow}>
											<span aria-hidden="true">×</span><span className="sr-only">Close</span>
										</button>
									}
									<div className="video-column">
										{(view === 'default' || videosLength < 2)
											? (
												<div id="player-video" style={{ width: '100%', height: '100%' }}>
													{(videosLength > 0) &&
														<div id='player-container'>
															<div className='channel1-wrapper'>
																{getVideoChannel(1)}
																{/* Video horizontal scrolling */}
																<div className="video-slider">
																	{<CustomButton variant="empty" onClick={(e) => playVideo(false)} id="play" title={play ? "Pause" : "Play"}>
																		{play ? videoPlayerIcons.pauseIcon : videoPlayerIcons.playIcon}
																	</CustomButton>}
																	<div className='slider-container'>
																		<ReactBootstrapSlider
																			id="video-slider"
																			name="horizontalSliderName"
																			value={value}
																			max={max}
																			min={0}
																			step={0.1}
																			tooltip="hide"
																			change={changeVideoSlider}
																		/>
																	</div>
																	{videos[activeChannel - 1].audio && ((activeChannel === channelAudio)
																		? <svg xmlns="http://www.w3.org/2000/svg" onClick={(e) => toggleAudio(e, 0)} width="3em" height="3em" fill="#fff" className="bi bi-volume-up-fill sound" viewBox="0 0 16 16">
																			<path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z" />
																			<path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z" />
																			<path d="M8.707 11.182A4.486 4.486 0 0 0 10.025 8a4.486 4.486 0 0 0-1.318-3.182L8 5.525A3.489 3.489 0 0 1 9.025 8 3.49 3.49 0 0 1 8 10.475l.707.707zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z" />
																		</svg>
																		: <svg xmlns="http://www.w3.org/2000/svg" onClick={(e) => toggleAudio(e, activeChannel)} width="45" height="45" fill="#fff" className="bi bi-volume-off-fill sound" viewBox="0 0 16 16">
																			<path d="M10.717 3.55A.5.5 0 0 1 11 4v8a.5.5 0 0 1-.812.39L7.825 10.5H5.5A.5.5 0 0 1 5 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z" />
																		</svg>
																	)}

																	<CustomButton variant="link" onClick={onPlayerFullScreenClick} >
																		{/* {isPlayerFullScreen */}
																		{playerFullScreen
																			? <RxExitFullScreen className='change-view' size='3.2em' />
																			: <RxEnterFullScreen className='change-view' size='3.2em' />
																		}
																	</CustomButton>

																	{/* {!isPlayerFullScreen && videosLength > 1 && */}
																	{!playerFullScreen && videosLength > 1 &&
																		<svg xmlns="http://www.w3.org/2000/svg" onClick={(e) => changeView(e, 'grid')} width="3.5em" height="3.5em" fill="currentColor" className="bi bi-grid change-view" viewBox="0 0 16 16">
																			<path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
																		</svg>
																	}
																</div>
															</div>
															{!showChannelsSelection ?
																<div className={`wrapper ${videosLength > 2 && 'center-align'}`}>
																	{getVideoChannel(2)}
																	{getVideoChannel(3)}
																	{getVideoChannel(4)}
																	<div className="tooltipDoubleClick">
																		Double click channel to enlarge
																	</div>
																</div> :
																<div className='channel-select'>
																	<ul className="list-inline chanels-list">
																		{_.times(videosLength, i =>
																			<li key={i}
																				className={classnames('list-inline-item', { 'active': activeChannel === (i + 1) })}
																			>
																				<a href="javascript:void(0)" onClick={() => changeActiveChannel(i + 1)}>
																					{i + 1}
																				</a>
																			</li>
																		)}
																	</ul>
																</div>
															}
														</div>
													}
												</div>
											) : (
												<div className='grid-wrapper'>
													<div id='player-video' className={`video-grid ${videosLength > 2 && 'four-channels'}`} style={{ height: isXs ? '90vh' : (ActualHeight + 'px') }}>
														{getVideoChannel(1)}
														{getVideoChannel(2)}
														{getVideoChannel(3)}
														{getVideoChannel(4)}
														<div className="video-slider">
															<CustomButton variant="empty" size="icon" onClick={(e) => playVideo(false)} id="play" title={play ? "Pause" : "Play"}>
																{play ? videoPlayerIcons.pauseIcon : videoPlayerIcons.playIcon}
															</CustomButton>
															<div className='slider-container'>
																<ReactBootstrapSlider
																	id="video-slider"
																	name="horizontalSliderName"
																	value={value}
																	max={max}
																	min={0}
																	step={0.1}
																	tooltip="hide"
																	change={changeVideoSlider}
																/>
															</div>
															{videosLength > 1 &&
																<svg xmlns="http://www.w3.org/2000/svg" onClick={(e) => changeView(e, 'default')} width="3.5em" height="3.5em" fill="currentColor" className="bi bi-layout-sidebar change-view rotate" viewBox="0 0 16 16">
																	<path d="M0 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3zm5-1v12h9a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H5zM4 2H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h2V2z" />
																</svg>
															}
														</div>
													</div>
												</div>
											)
										}
										{/* Draggable Element */}
										<div className="draggable-wrapper">
											<svg xmlns="http://www.w3.org/2000/svg" width="5em" height="3em" fill="currentColor" className="bi bi-grip-horizontal draggable-element" viewBox="0 0 16 16">
												<path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
											</svg>
										</div>
									</div>


									{/* Right sidebar */}
									<div
										className="metadata-column"
										style={{ height: windowWidth < 800 ? 'auto' : (ActualHeight) + 'px' }}
									>
										<div id='event-info' className='right-column-info'>
											<div className='player-header'>
												<div className='metadata-header'>
													{['system_admin', 'customer_service'].indexOf(userRole) > -1 ?
														<span className="vehicle-name clickable" onClick={() => editVehicle()}>{event.vehicle_name || event.device_id}</span> :
														<span className="vehicle-name">{event.vehicle_name || event.device_id}</span>
													}
													<span className='event-id'>{event.id}</span>
													<button type="button" variant="close" style={{ border: "none", background: "white", marginLeft: "5px" }} onClick={hideWindow}>
														<span aria-hidden="true">×</span><span className="sr-only">Close</span>
													</button>
												</div>
											</div>
											<div id="player-metadata" className="player-metadata info-box">
												<div className="player-data">
													{canFlagVideo &&
														<CustomButton
															variant='empty'
															size="icon"
															prefix='flagged-button-menu'
															id={event.id}
															active={event.flagged || isFlagged}
															title={`${event.flagged ? "Unflag" : "Flag"} Event`}
															onClick={() => onFlagEvent(event)}
														>
															{eventIcons.flaggedIcon}
														</CustomButton>
													}
													{canEventFeedbackView && 
														<EventFeedback
															event={event}
															getFleetEvents={getFleetEvents}
														/>
													}
													<h4><span className='category-title'>Event Info</span></h4>
													{(userRole !== 'sales_demo') && (
														<Dropdown
															id="download-menu-dropdown"
															drop
															title='More Actions'
														>
															<Dropdown.Toggle
																as={CustomButtonToggle}
																size="icon"
																variant="empty"
																wrapperClassName="no-line-height"
															>
																{moreActionsIcon}
															</Dropdown.Toggle>
															<Dropdown.Menu
																as={CustomMenu}
																className="download-menu"
																title='More Actions'
															>
																<div>
																	<h4 className='category-title'>More Actions</h4>
																	{(videosLength > 0) &&
																		(
																			!['custom_user', 'custom_partner'].includes(userRole) ||
																			(['custom_user', 'custom_partner'].includes(userRole) && _.find(userPermissions, { 'perm_category_id': 9, 'a_view': true }))
																		) &&
																		<div>
																			< OverlayTriggerNode
																				nvrUrl={nvrUrl}
																				videoUrls={videoUrls}
																			/>
																		</div>
																	}

																	{nvrUrl &&
																		(
																			!['custom_user', 'custom_partner'].includes(userRole) ||
																			(['custom_user', 'custom_partner'].includes(userRole) && _.find(userPermissions, { 'perm_category_id': 9, 'a_view': true }))
																		) &&
																		<div>
																			<CustomButton variant="empty" onClick={downloadNVR} title="Download NVR Video" id='video-player-button'>
																				<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" className="bi bi-cloud-arrow-down" viewBox="0 0 16 16">
																					<path fillRule="evenodd" d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z" />
																					<path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
																				</svg>
																				<span className='download-button-text'>Download NVR Video</span>
																			</CustomButton>
																		</div>
																	}
																	<CustomButton variant="empty" className="video-player-button" title="Share Event" id='video-player-button' onClick={() => openShareEvent(event)}>
																		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-share" viewBox="0 0 16 16">
																			<path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
																		</svg>
																		<span className='download-button-text share-button'>Share</span>
																	</CustomButton>
																	{canRequestVideo &&
																		<CustomButton
																			variant="empty"
																			title="Request Additional Video"
																			id='video-player-button'
																			disabled={event?.uploading_status === 'EVENT_REPORTED'}
																			onClick={openRequestContextVideo}
																		>
																			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-play" viewBox="0 0 16 16">
																				<path d="M6 6.883v4.234a.5.5 0 0 0 .757.429l3.528-2.117a.5.5 0 0 0 0-.858L6.757 6.454a.5.5 0 0 0-.757.43z" />
																				<path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
																			</svg>
																			<span className='download-button-text share-button'>Request Additional Video</span>
																		</CustomButton>
																	}
																	<CustomButton variant="empty" onClick={generateAccidentReport} title="Generate Accident Report" id='video-player-button'>
																		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-diamond" viewBox="0 0 16 16">
																			<path d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z" />
																			<path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
																		</svg>
																		<span className='download-button-text share-button'>Generate Accident Report</span>
																	</CustomButton>
																	{isCoachableEvent &&
																		<CustomButton variant="empty" onClick={openCoachEventModal} title="Coach Event" id='video-player-button'>
																			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-circle" viewBox="0 0 16 16">
																				<path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
																			</svg>
																			<span className='download-button-text share-button'>Coach Event</span>
																		</CustomButton>
																	}
																</div>
															</Dropdown.Menu>
														</Dropdown>
													)}
												</div>
												<ul>
													<li className="eventNameRow">
														<div>
															<span className='metadata-logo'>
																<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" className="bi bi-exclamation-triangle" viewBox="0 0 16 16">
																	<path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
																	<path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
																</svg>
															</span>
															<span className='header-value'>
																{eventTypeDetection(event)}
															</span>
														</div>
														{['system_admin', 'customer_service', 'sales_demo', 'partner', 'custom_partner', 'user', 'fleet_manager'].includes(userRole) &&
															(<div className="eventLabelBlock">
																<span className="eventLabel">{event.event_label ? event.event_label : ""}</span>
																<CustomButton variant="link" onClick={() => setShowEventLabel(true)}>{event.event_label ? "EDIT" : "ADD LABEL"}</CustomButton>
															</div>)}
													</li>
													{header.date &&
														<li>
															<span className="metadata-logo">
																<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" className="bi bi-calendar-event" viewBox="0 0 16 16">
																	<path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
																	<path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
																</svg>
															</span>
															<span className='header-value'>
																{moment.utc(header.date).format("ddd MMM D[, ']YY") + ', ' + (state.timestamp ? moment.utc(state.timestamp).format('h:mm:ss A') : '')}
															</span>
														</li>
													}
													{(event.driver_name || event.geotab_driver || driver || header.driver) &&
														<li>
															<span className="metadata-logo">
																<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" className="bi bi-person-badge" viewBox="0 0 16 16">
																	<path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
																	<path d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z" />
																</svg>
															</span>
															<span className='header-value'>
																{event.driver_name || event.geotab_driver || driver || header.driver}
															</span>
														</li>
													}
													{['fleet_manager', 'partner', 'customer_service', 'system_admin'].includes(userRole) &&
														(!(event.driver_name || event.geotab_driver || driver || header.driver) && company.driver_recognition === 'cardreader') &&
														<li style={{ display: "flex", marginTop: "3px" }}>
															<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" className="bi bi-person-badge" viewBox="0 0 16 16">
																<path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
																<path d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z" />
															</svg>
															{!showAddDriver ?
																<button className='header-value header-button' onClick={openSelectDriver} title="Add Driver" >
																	+ Add Driver
																</button> :
																<Dropdown
																	defaultOpen
																	id="driver-dropdown"
																	className='actions-dropdown'
																>
																	<Dropdown.Toggle className='header-value header-button header-value-driver'>Select Driver...</Dropdown.Toggle>
																	<Dropdown.Menu as={CustomMenu}>
																		<ul className='actions-menu'>
																			{availableDrivers.map(driver =>
																				<button
																					key={driver.id}
																					className='menu-action'
																					title={`${driver.first_name} ${driver.last_name}`}
																					onClick={() => addDriver(driver)}
																				>
																					{`${driver.first_name} ${driver.last_name}`}
																				</button>
																			)}
																		</ul>
																	</Dropdown.Menu>
																</Dropdown>
															}
														</li>
													}
													<li style={{ marginTop: "3px" }}>
														<span className="metadata-logo">
															<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" className="bi bi-speedometer" viewBox="0 0 16 16">
																<path d="M8 2a.5.5 0 0 1 .5.5V4a.5.5 0 0 1-1 0V2.5A.5.5 0 0 1 8 2zM3.732 3.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 8a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 7.31A.91.91 0 1 0 8.85 8.569l3.434-4.297a.389.389 0 0 0-.029-.518z" />
																<path fillRule="evenodd" d="M6.664 15.889A8 8 0 1 1 9.336.11a8 8 0 0 1-2.672 15.78zm-4.665-4.283A11.945 11.945 0 0 1 8 10c2.186 0 4.236.585 6.001 1.606a7 7 0 1 0-12.002 0z" />
															</svg>
														</span>
														<span className='header-value'>
															{speed} {user.speed_unit === 'kph' ? 'KPH' : 'MPH'} <span className="red-text" style={{ fontSize: '13px' }}>{meye.speedWarning ? "(" + ((meye.speedWarning - 1) * 5) + "-" + (meye.speedWarning * 5) + " over limit)" : ""}</span>
														</span>
													</li>
													<li>
														<span className="metadata-logo">
															<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" className="bi bi-stopwatch" viewBox="0 0 16 16">
																<path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z" />
																<path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z" />
															</svg>
														</span>
														<span className='header-value'>
															{displayTime(value)} / {displayTime(max)}
														</span>
													</li>
													<li className={canEditNote() ? 'note-row' : ''} onClick={() => canEditNote() && openEventNote(event)}>
														<span className="metadata-logo">
															<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
																<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
															</svg>
														</span>
														<span className='header-value'>
															{props.note || 'Leave a note...'}
														</span>
													</li>
												</ul>

												{header.mobileye &&
													<ul className='mobileye-list'>
														<h4><span className='category-title'>Mobileye Info</span></h4>
														<li><span className="header-item">HW:</span><span className={classnames({ 'green-text': meye.hwWarning == 1 }, { 'red-text': meye.hwWarning == 2 })}>{meye.hw / 10} seconds</span></li>
														<li><span className="header-item">FCW:</span>{meye.fcw ? "Forward" : "None"}</li>
														<li><span className="header-item">LDW:</span>{meye.ldw ? "Active" : "Inactive"}{meye.ldwLeft ? ", Left" : ""}{meye.ldwRight ? ", Right" : ""}</li>
														<li><span className="header-item">Pedestrian:</span>{getMeyePedestrian()}</li>
														<li><span className="header-item">System Status:</span>{getMeyeSystemStatus()}</li>
													</ul>
												}
											</div>
										</div>
										<div id="player-map" style={{ height: `calc(100% - ${MapHeight}px)`, width: '100%' }}>
											{!isInternalUser(userRole) ?
												<SimpleMapExampleGoogleMap
													containerElement={
														<div style={{ height: `100%` }} className='container-element' />
													}
													mapElement={
														<div style={{ height: `100%` }} className='map-element' />
													}
													coordinates={coordinates}
													polyline={polyline}
													direction={direction}
													accidentTime={accidentTime}
												/> :
												<SimpleMapExampleHEREMap
													containerElement={
														<div style={{ height: `100%` }} className='container-element' />
													}
													mapElement={
														<div style={{ height: `100%` }} className='map-element' />
													}
													coordinates={coordinates}
													polyline={polyline}
													direction={direction}
													accidentTime={accidentTime}
												/>}
										</div>
										<div style={{ opacity: 0 }}>
											<SpeedGraph
												speedData={metadata.filter(elm => !!elm.speed)}
												speed={speed}
												timestamp={state.timestamp}
												speed_unit={user.speed_unit === 'kph' ? 'KPH' : 'MPH'}
											/>
											<GsensorGraph
												acceleration={acc}
												timestamp={state.timestamp}
											/>
										</div>
									</div>
								</div>
							</div>

							{showRequestContextVideo &&
								<ModalRequestContextVideo
									event={event}
									onHide={closeRequestContextVideo}
								/>
							}

							{showCoachEventModal &&
								<ModalCoachEvent
									type='event'
									coachingItem={event}
									onHide={() => setShowCoachEventModal(false)}
								/>
							}

							{showEventLabel &&
								<ModalEventLabel
									event={event}
									onSaveEventLabel={onSaveEventLabel}
									onHide={() => setShowEventLabel(false)}
								/>
							}

							{loaderModal}
						</div>
					</Resizable>
				</div>
			</div>
		</Draggable>
	)
}

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company,
		loaderModal: state.loader.loaderModal
	}),
	dispatch => ({
		toggleLoaderModal: (show) => {
			dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
		}
	})
)(AdvancedPlayer);
