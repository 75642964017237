import React, { useState, useEffect } from 'react'
import { browserHistory } from 'react-router'
import { Button } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import moment from 'moment'
import Datetime from '@iftek/react-datetime'
import _ from 'lodash'

import { renderCustomHeader, defaultTableOptions, paginationOptions, tableAdjustableColOptions } from '../../helpers/table'
import momenttz from '../../helpers/momenttz'
import DriverIdentifierModal from '../modals/DriverIdentifierModal'
import DriverRenamerModal from '../modals/DriverRenamerModal'

import { apiCallGet } from '../../../action/RouterActions'
import GreyHeader from '../../GreyHeader'
import CustomButton from '../../CustomButton';

const FleetAdminDriverContainer = (props) => {

	const { undrivers, drivers, user, company, location, selectedObj, toggleLoader } = props;
	const user_role = user.roles[0];

	const [locationState, setLocationState] = useState(null);
	const [start_date, setStart_date] = useState(moment().subtract(6, 'days').format('YYYYMMDD'));
	const [end_date, setEnd_date] = useState(moment().format('YYYYMMDD'));

	useEffect(() => {
		// console.log('!componentDidMount FleetAdminDriverContainer', {...props})
		const { selectedObj, updateCompany } = props;
		const { company_id, timestamp, company_name, partner_name, company_type, driver_recognition, token } = selectedObj;

		//* The Manage Drivers page will only be visible if company driver ID is enabled or role = system_admin
		// if (company.driver_recognition === 'disabled' && ['system_admin'].indexOf(user_role) === -1) {
		if (company.driver_recognition === 'manual' && ['system_admin'].indexOf(user_role) === -1) {
			browserHistory.push('/not-found')
		}
		// ******************

		if (location && location.state && location.state.type === 'driverid') {
			if (company_id !== company.company_id) {
				updateCompany({
					name: company_name,
					partner: partner_name,
					company_id,
					type: company_type,
					driver_recognition,
					token,
				})
			}

			const date = moment(timestamp).format('YYYYMMDD')
			setStart_date(date);
			setEnd_date(date);
			setLocationState(location);
			getDrivers(company_id);
			browserHistory.replace({ pathname: '/fleet-admin/driver', state: {} })
		} else if (!_.isEmpty(company)) {
			getDrivers()
		}
	}, []);

	useEffect(() => {
		// console.log('!componentWillReceiveProps FleetAdminDriverContainer, location=', location)
		// console.log('!componentWillReceiveProps FleetAdminDriverContainer, props.location=', props.location)
		// console.log('!componentWillReceiveProps FleetAdminDriverContainer',)
		//* The Manage Drivers page will only be visible if company driver ID is enabled or role = system_admin
		// if (company.driver_recognition == 'disabled' && ['system_admin'].indexOf(user_role) == -1) {
		if (company.driver_recognition === 'manual' && ['system_admin'].indexOf(user_role) == -1) {
			browserHistory.push('/not-found')
		}
		// ******************
		// if (location && location.state && nextProps.location.state.type == 'driverid') {
		//     console.log('location exists')
		// }
		// else if (!props.location || _.isEmpty(props.location.state))
		if (!location || _.isEmpty(location.state)) {
			console.log('Company is updated')
			getDrivers(company.company_id)
		}
	}, [company.company_id]);

	const getDrivers = (companyId) => {
		toggleLoader(true)

		const company_id = companyId || company.company_id;

		const requestArray = []
		requestArray.push(apiCallGet('/company/{company_id}/driver', { company_id }))
		requestArray.push(apiCallGet('/company/{company_id}/driver/trip', { company_id, start_date, end_date }))

		Promise.all(requestArray)
			.then(
				(res) => {
					toggleLoader(false)

					props.setIdentifiedDrivers(res[0].data.response.drivers)

					const undrivers = res[1].data.response.driver_trips.map((item, index) => {
						item.id = `${item.device_id}_${moment(item.min_ts).format('YYMMDDHHmmss')}`
						return item
					})
					props.setUnidentifiedDrivers(undrivers)

					// if (res.length >0) {
					//     if ( res[0] && res[0].status == 200 || res[0].status == 201 ) {
					//         toastr.success("", "Changes were successfully saved");
					//     }
					// }
					// else {
					//     toastr.success("", "There were no changes");
					// }
				},
				(error) => {
					toggleLoader(false)

					let errcont = _.get(error, 'response.data.response.error');
					if (errcont) {
						if (errcont === 'group exist') {
							errcont = 'A group with this name already exists';
						}

						toastr.error('', errcont);
					}
				},
			)
	}

	const getUnidentifiedDrivers = () => {
		toggleLoader(true)
		const { company_id } = company;
		apiCallGet('/company/{company_id}/driver/trip', { company_id, start_date, end_date })
			.then((res, err) => {
				console.log('!getUnidentifiedDrivers res:', res, err)

				const undrivers = res.data.response.driver_trips.map((item, index) => {
					item.id = `${item.device_id}_${moment(item.min_ts).format('YYMMDDHHmmss')}`
					return item
				})
				props.setUnidentifiedDrivers(undrivers)

				toggleLoader(false)
			})
			.catch((error) => {
				toggleLoader(false)
				console.log('!getUnidentifiedDrivers error:', error.response)
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const onDateChanged1 = (current) => {
		// console.log('onDateChanged1', current)
		setStart_date(current.format('YYYYMMDD'))
	}

	const onDateChanged2 = (current) => {
		// console.log('onDateChanged2', current)
		setEnd_date(current.format('YYYYMMDD'))
	}

	const toogleZoom = (event) => {
		// console.log('toogleZoom', event)
		const elem = event.target

		if (elem.classList.contains('zoom')) {
			elem.classList.remove('zoom')
		} else {
			elem.classList.add('zoom')
		}
	}

	const showDriverIdentifier = (drivertrip) => {
		props.toggleDriverIdentifier(true)
		props.setSelectedDriverTrip(drivertrip)
	}

	const showDriverRenamer = (driver) => {
		props.toggleDriverRenamer(true)
		props.setSelectedDriver(driver)
	}

	const vehicleNameFormatter = (cell, row) => row.vehicle_name || row.device_id;

	const timeFormatter = (cell, row) => momenttz(moment(cell)).format('MM/DD/YYYY hh:mm:ss A');

	const actionsFormatter = (cell, row) => (
		<a href="javascript:void(0)" onClick={() => showDriverIdentifier(row)}>
			<i className="fa fa-id-badge" />
			Identify
		</a>
	);

	const actionsDrFormatter = (cell, row) => (
		<a href="javascript:void(0)" onClick={() => showDriverRenamer(row)}>
			<i className="fa fa-id-badge" />
			Rename
		</a>
	);

	const photosFormatter = (cell, row) => {
		const option = []

		if (row.photos && row.photos.length) {
			row.photos.map((item, index) => {
				option.push(<img className="driver-photo" key={item.id} src={item.url} alt="" onClick={toogleZoom} />)
				// return <img src={item.url} alt="" />
			})
		}

		return <span className="text-wrap">{option}</span>
	};

	// onRowSelect = (row, isSelected, e) => {
	//     console.log('&&&&&onRowSelect', row, isSelected, e)
	//     // if (isSelected && row.min_ts == row.min_ts ) {
	//     //     return true
	//     // }
	// }
	// console.log('+++FleetAdminDriverContainer RENDER PROPS:', props)
	// company = _.find(props.company_collection, ['name', company_name]),
	// company_id = company ? company.id : null,
	const selectedRow = {
		mode: 'radio',
		bgColor: '#f3f6c1', // you should give a bgcolor, otherwise, you can't regonize which row has been selected
		hideSelectColumn: true, // enable hide selection column with radiobuttons
		clickToSelect: true,
	};
	const selectedRow1 = {
		mode: 'radio',
		bgColor: '#f3f6c1', // you should give a bgcolor, otherwise, you can't regonize which row has been selected
		hideSelectColumn: true, // enable hide selection column with radiobuttons
		clickToSelect: true,
		// onSelect: onRowSelect,
		// selected: ['17839173_190908141500']
	};
	const options1 = { ...paginationOptions };
	const validDate1 = current => current.isSameOrBefore(moment(end_date));
	const validDate2 = current => current.isSameOrAfter(moment(start_date));

	//* Looking for unindentified driver trip for user notifications
	if (locationState && locationState.state && locationState.state.type === 'driverid' && undrivers.length) {
		let page = 1
		const obj = undrivers.find(({ device_id }, index) => {
			if (device_id === selectedObj.device_id) {
				page = Math.trunc(index / 10) + 1
				return selectedObj
			}
		})
		if (obj) {
			selectedRow1.selected = [`${obj.device_id}_${moment(obj.min_ts).format('YYMMDDHHmmss')}`]
			options1.page = page
		}
	}

	return (
		<div className="fleet-admin-modem">
			<GreyHeader title='Drivers' subtitle={company.name} />

			<main className='main-content-block'>
				<div className="manage-users-table">
					{!drivers.length
						&& <div>Company Has No Drivers</div>}
					{drivers.length > 0
						&& (
							<BootstrapTable
								data={drivers}
								{...tableAdjustableColOptions}
								selectRow={selectedRow}
								bodyStyle={{ overflow: 'unset' }}
							>
								<TableHeaderColumn
									isKey
									dataField="id"
									width="10%"
									dataSort
								>
									{renderCustomHeader(0, 'Driver ID')}
								</TableHeaderColumn>
								<TableHeaderColumn
									width="20%"
									dataField="name"
									dataSort
								>
									{renderCustomHeader(1, 'Driver Name')}
								</TableHeaderColumn>

								{['partner_view', 'sales_demo'].indexOf(user_role) > -1
									&& (
										<TableHeaderColumn
											width="50%"
											dataField="version"
											dataSort={false}
											dataFormat={photosFormatter}
										>
											<div className="custom-table-header-row">
												<div className="custom-table-header-title">Photos</div>
											</div>
										</TableHeaderColumn>
									)}

								{['partner_view', 'sales_demo'].indexOf(user_role) === -1
									&& (
										<TableHeaderColumn
											width="35%"
											dataField="version"
											dataSort={false}
											dataFormat={photosFormatter}
										>
											{renderCustomHeader(2, 'Photos')}
										</TableHeaderColumn>
									)}

								{['partner_view', 'sales_demo'].indexOf(user_role) === -1
									&& (
										<TableHeaderColumn
											width="15%"
											dataField="current_firmware"
											dataSort={false}
											dataFormat={actionsDrFormatter}
										>
											<div className="custom-table-header-row">
												<div className="custom-table-header-title">Actions</div>
											</div>
										</TableHeaderColumn>
									)}
							</BootstrapTable>
						)}
				</div>

				<hr style={{ marginTop: '20px' }} />

				<h5 style={{ marginTop: '20px' }}>Unidentified Drivers</h5>
				<div className="row reload-dates-mobile" style={{ maxWidth: '800px', display: 'flex' }}>
					<div className="col-md-4 date-container">
						<div className="date"><span>Start Date:</span></div>
						<div className="datetime">
							<Datetime
								inputProps={{ readOnly: true }}
								timeFormat={false}
								defaultValue={moment(start_date).format('MM/DD/YYYY')}
								value={moment(start_date).format('MM/DD/YYYY')}
								closeOnSelect
								isValidDate={validDate1}
								onChange={onDateChanged1}
							/>
						</div>
					</div>
					<div className="col-md-4 date-container">
						<div className="date"><span>End Date:</span></div>
						<div className="datetime">
							<Datetime
								inputProps={{ readOnly: true }}
								timeFormat={false}
								defaultValue={moment(end_date).format('MM/DD/YYYY')}
								value={moment(end_date).format('MM/DD/YYYY')}
								closeOnSelect
								isValidDate={validDate2}
								onChange={onDateChanged2}
							/>
						</div>
					</div>
					<div className="col-md-4" style={{ alignSelf: 'flex-end' }}>
						<CustomButton onClick={getUnidentifiedDrivers} variant="primary-outline">Reload</CustomButton>
					</div>
				</div>


				<div className="manage-users-table">
					{!undrivers.length
						&& <div>No Unidentified Drivers</div>}

					{undrivers.length > 0
						&& (
							<BootstrapTable
								data={undrivers}
								{...defaultTableOptions}
								options={options1}
								selectRow={selectedRow1}
								bodyStyle={{ overflow: 'unset' }}
							>
								<TableHeaderColumn dataField="id" isKey hidden>composite key</TableHeaderColumn>

								<TableHeaderColumn
									width="25%"
									dataField="vehicle_name"
									dataSort
									dataFormat={vehicleNameFormatter}
								>
									{renderCustomHeader(0, 'Vehicle Name')}
								</TableHeaderColumn>
								<TableHeaderColumn
									width="20%"
									dataField="min_ts"
									dataSort
									dataFormat={timeFormatter}
								>
									{renderCustomHeader(3, 'Photos')}
								</TableHeaderColumn>
								<TableHeaderColumn
									width="20%"
									dataField="max_ts"
									dataSort
									dataFormat={timeFormatter}
								>
									{renderCustomHeader(2, 'End Range')}
								</TableHeaderColumn>

								{['partner_view', 'sales_demo'].indexOf(user_role) > -1
									&& (
										<TableHeaderColumn
											width="35%"
											dataField="version"
											dataSort={false}
											dataFormat={photosFormatter}
										>
											<div className="custom-table-header-row">
												<div className="custom-table-header-title">Photos</div>
											</div>
										</TableHeaderColumn>
									)}

								{['partner_view', 'sales_demo'].indexOf(user_role) === -1
									&& (
										<TableHeaderColumn
											width="25%"
											dataField="version"
											dataSort={false}
											dataFormat={photosFormatter}
										>
											{renderCustomHeader(3, 'Photos')}
										</TableHeaderColumn>
									)}

								{['partner_view', 'sales_demo'].indexOf(user_role) === -1
									&& (
										<TableHeaderColumn
											width="10%"
											dataField="current_firmware"
											dataSort={false}
											dataFormat={actionsFormatter}
										>
											<div className="custom-table-header-row">
												<div className="custom-table-header-title">Actions</div>
											</div>
										</TableHeaderColumn>
									)}
							</BootstrapTable>
						)}
				</div>
			</main>

			{showDriverIdentifier
				&& (
					<DriverIdentifierModal
						onSave={getDrivers}
					/>
				)}

			{showDriverRenamer
				&& <DriverRenamerModal />}
		</div>
	)
}

const mapStateToProps = (state) => {
	const {
		user, company, driver, notification
	} = state;
	return {
		user: user.user,
		company: company.company,
		undrivers: driver.undrivers,
		drivers: driver.drivers,
		showDriverIdentifier: driver.showDriverIdentifier,
		showDriverRenamer: driver.showDriverRenamer,
		selectedObj: notification.selectedObj
	}
};

const mapDispatchToProps = dispatch => ({
	setUnidentifiedDrivers: (drivers) => {
		dispatch({ type: 'SET_UNIDENTIFIED_DRIVERS', payload: drivers })
	},
	setIdentifiedDrivers: (drivers) => {
		dispatch({ type: 'SET_IDENTIFIED_DRIVERS', payload: drivers })
	},
	toggleDriverIdentifier: (state) => {
		dispatch({ type: 'SHOW_DRIVER_IDENTIFIER', payload: state })
	},
	setSelectedDriverTrip: (state) => {
		dispatch({ type: 'SET_DRIVER_TRIP', payload: state })
	},
	toggleDriverRenamer: (state) => {
		dispatch({ type: 'SHOW_DRIVER_RENAMER', payload: state })
	},
	setSelectedDriver: (state) => {
		dispatch({ type: 'SET_DRIVER', payload: state })
	},
	updateCompany: (company) => {
		dispatch({ type: 'GET_COMPANY_SUCCESS', payload: company })
	},
	toggleLoader: (show) => {
		dispatch({ type: 'TOGGLE_LOADER', payload: show });
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(FleetAdminDriverContainer);
