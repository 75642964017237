import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa'
import { BsDot } from 'react-icons/bs'

import CustomButton from '../../../component/CustomButton'
import { filterIcon } from '../../../component/helpers/iconHelpers'
import { isInternalUser } from '../../../component/helpers/constants'
import { isCustomUserPermissions } from '../../../component/helpers/helperUsers'

import '../../../component/FilterMenu.sass'
// TODO: needs to develop universal filter menu for events
const VehicleSidebarFilter = (props) => {
    // console.log('PROPS VehicleSidebarFilter: ', props)
    const { user, filters, setFiltersVehiclePanel, userRole } = props
    const userPermissions = user.permissions
    const canEventFeedbackView = ['system_admin', 'customer_service', 'sales_demo', 'partner'].includes(userRole) 
		|| (['custom_user', 'custom_partner'].includes(userRole) && isCustomUserPermissions(userRole, userPermissions, 'event_feedback_add'))
		|| (['custom_user', 'custom_partner'].includes(userRole) && isCustomUserPermissions(userRole, userPermissions, 'event_feedback_edit'))
    
    let countSelectedFilters = canEventFeedbackView ? 0 : 1
    let showFilterActive = false
    for (let [filter, value] of Object.entries(filters)) {
        value && countSelectedFilters++
        if (!['pending', 'feedback'].includes(filter) && !value) {
            showFilterActive = true
        }
    }

    const onFilterItemClick = (name) => {
        const newFilters = { ...filters }
        newFilters[name] = !newFilters[name]

        setFiltersVehiclePanel(newFilters)
    }

    const selectAllFilters = () => {
        const newFilters = { ...filters }

        if (countSelectedFilters === Object.keys(filters).length) {
            for (let key in newFilters) {
                newFilters[key] = false
            }
        }
        else {
            for (let key in newFilters) {
                if (key === 'feedback' && !canEventFeedbackView) {
                    newFilters[key] = false
                }
                else {
                    newFilters[key] = true
                }
            }
        }
        setFiltersVehiclePanel(newFilters)
    }


    // const { filters, setFiltersVehiclePanel } = props
    // const countSelectedEventTypes = Object.keys(filters.event_types).reduce((sum, value) => (filters.event_types[value] ? sum + 1 : sum), 0)
    // const countSelectedStatuses = Object.keys(filters.statuses).reduce((sum, value) => (filters.statuses[value] ? sum + 1 : sum), 0)
    // const showFilterActive = countSelectedEventTypes !== Object.keys(filters.event_types).length || countSelectedStatuses !== Object.keys(filters.statuses).length

    // const onSelectAllFiltersClick = (filterType) => {
    //     const newFilters = { ...filters }
    //     const countSelected = filterType === 'event_types' ? countSelectedEventTypes : countSelectedStatuses
    //     if (countSelected === Object.keys(newFilters[filterType]).length) {
    //         for (let key in newFilters[filterType]) {
    //             newFilters[filterType][key] = false
    //         }
    //         setFiltersVehiclePanel(newFilters)
    //     } else {
    //         for (let key in newFilters[filterType]) {
    //             newFilters[filterType][key] = true
    //         }
    //         setFiltersVehiclePanel(newFilters)
    //     }
    // }
    // const onFilterItemClick = (filterType, filterItem) => {
    // 	const newFilters = { ...filters }
    //     if ( filterType === 'event_types') {
    //         const obj = newFilters.event_types.find(({fulltype}) => filterItem) 
    //         if (obj) {
    //             obj.checked = !obj.checked
    //         }
    //     }
    //     //* filterType === 'statuses')
    //     else { 
    // 	    newFilters[filterType][filterItem] = !newFilters[filterType][filterItem] 
    //     }

    //     setFiltersVehiclePanel(newFilters)
    // }

    return (
        <Dropdown
            id="type-filter-btn"
            align="start"
            drop
        >
            <Dropdown.Toggle
                // nocaret
                bsPrefix="no-caret event-filter-toggle"
            >
                {filterIcon}
                {showFilterActive
                    && <BsDot className='filter-dot' />
                }
            </Dropdown.Toggle>
            <Dropdown.Menu
                className="typeFilterMenu"
            >
                <h5 className="category-title">Events</h5>
                <CustomButton
                    variant="link"
                    prefix="btn-select-all"
                    onClick={selectAllFilters}
                // variant="link"
                // onClick={() => onSelectAllFiltersClick('event_types')}
                >
                    {`${countSelectedFilters === Object.keys(filters).length ? 'Unselect' : 'Select'} All`}
                </CustomButton>
                <ul className="filters-list">
                    <li onClick={() => onFilterItemClick('critical_gsensor')}>
                        High G-Force
                        {filters.critical_gsensor && <FaCheck className='flagSelected' />}
                    </li>
                    <li onClick={() => onFilterItemClick('speed')}>
                        Speeding
                        {filters.speed && <FaCheck className='flagSelected' />}
                    </li>
                    <li onClick={() => onFilterItemClick('driver')}>
                        Driver
                        {filters.driver && <FaCheck className='flagSelected' />}
                    </li>
                    <li onClick={() => onFilterItemClick('custom')}>
                        Custom
                        {filters.custom && <FaCheck className='flagSelected' />}
                    </li>
                    <li onClick={() => onFilterItemClick('harshaccel')}>
                        Harsh Acceleration
                        {filters.harshaccel && <FaCheck className='flagSelected' />}
                    </li>
                    {/* Only for Tenna and internal users (FWSD-7355) */}
                    {/* {(user?.partner_company_name === "TENNA LLC" || user?.company_name === "Tenna LLC" || isInternalUser(userRole)) && */}
                        <li onClick={() => onFilterItemClick('camerablock')}>
                            Potential Ch2 Obstruction
                            {filters.camerablock && <FaCheck className='flagSelected' />}
                        </li>
                    {/* } */}
                    <li onClick={() => onFilterItemClick('harshbraking')}>
                        Harsh Braking
                        {filters.harshbraking && <FaCheck className='flagSelected' />}
                    </li>
                    <li onClick={() => onFilterItemClick('pending')}>
                        Pending
                        {filters.pending && <FaCheck className='flagSelected' />}
                    </li>
                    {canEventFeedbackView &&
                        <li onClick={() => onFilterItemClick('feedback')}>
                            Feedback
                            {filters.feedback && <FaCheck className='flagSelected' />}
                        </li>
                    }

                    <h5 className="category-subtitle">DMS</h5>
                    <li onClick={() => onFilterItemClick('distraction')}>
                        Distraction
                        {filters.distraction && <FaCheck className='flagSelected' />}
                    </li>
                    <li onClick={() => onFilterItemClick('drowsiness')}>
                        Drowsiness/Head Down
                        {filters.drowsiness && <FaCheck className='flagSelected' />}
                    </li>
                    <li onClick={() => onFilterItemClick('yawning')}>
                        Yawning
                        {filters.yawning && <FaCheck className='flagSelected' />}
                    </li>
                    <li onClick={() => onFilterItemClick('phone_use')}>
                        Phone Use/Hand to Head
                        {filters.phone_use && <FaCheck className='flagSelected' />}
                    </li>
                    <li onClick={() => onFilterItemClick('seatbelt')}>
                        Seatbelt
                        {filters.seatbelt && <FaCheck className='flagSelected' />}
                    </li>
                    <li onClick={() => onFilterItemClick('smoking')}>
                        Smoking
                        {filters.smoking && <FaCheck className='flagSelected' />}
                    </li>

                    <h5 className="category-subtitle">ADAS</h5>

                    <li onClick={() => onFilterItemClick('adas_ldw')}>
                        Lane Departure
                        {filters.adas_ldw && <FaCheck className='flagSelected' />}
                    </li>
                    <li onClick={() => onFilterItemClick('adas_pcw')}>
                        Pedestrian Warning
                        {filters.adas_pcw && <FaCheck className='flagSelected' />}
                    </li>
                    <li onClick={() => onFilterItemClick('adas_fcw')}>
                        Forward Collision
                        {filters.adas_fcw && <FaCheck className='flagSelected' />}
                    </li>
                    <li onClick={() => onFilterItemClick('adas_hw')}>
                        Headway Warning
                        {filters.adas_hw && <FaCheck className='flagSelected' />}
                    </li>
                    <li onClick={() => onFilterItemClick('tailgating')}>
                        Tailgating
                        {filters.tailgating && <FaCheck className='flagSelected' />}
                    </li>
                    {isInternalUser(userRole) && (
                        <>
                            <h5 className="category-subtitle">Video Status</h5>

                            <li onClick={() => onFilterItemClick('videouploaded')}>
                                Uploaded
                                {filters.videouploaded && <FaCheck className='flagSelected' />}
                            </li>
                            <li onClick={() => onFilterItemClick('videopending')}>
                                Pending
                                {filters.videopending && <FaCheck className='flagSelected' />}
                            </li>
                            <li onClick={() => onFilterItemClick('videononexist')}>
                                No Video On Device
                                {filters.videononexist && <FaCheck className='flagSelected' />}
                            </li>
                            <li onClick={() => onFilterItemClick('videoconvertedfailed')}>
                                Conversion Error
                                {filters.videoconvertedfailed && <FaCheck className='flagSelected' />}
                            </li>
                            <li onClick={() => onFilterItemClick('videotransferringfailed')}>
                                Download Error
                                {filters.videotransferringfailed && <FaCheck className='flagSelected' />}
                            </li>
                            <li onClick={() => onFilterItemClick('videofilesconverted')}>
                                Files Converted
                                {filters.videofilesconverted && <FaCheck className='flagSelected' />}
                            </li>
                            <li onClick={() => onFilterItemClick('videofailed')}>
                                Event Failed
                                {filters.videofailed && <FaCheck className='flagSelected' />}
                            </li>
                            <li onClick={() => onFilterItemClick('videoundefined')}>
                                Unknown
                                {filters.videoundefined && <FaCheck className='flagSelected' />}
                            </li>
                        </>
                    )}
                </ul>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default VehicleSidebarFilter
